import React from 'react';
import styled from 'styled-components';
import { Container } from '../../ui/container';

interface IMainPageTitle {
    statics: any;
}

const MainPageTitle = (props: IMainPageTitle) => {
    const { statics } = props;

    return (
        <Wrapper>
            <Container>
                {statics.Title ? (
                    <Title dangerouslySetInnerHTML={{ __html: statics.Title.data }}></Title>
                ) : (
                    <Title>We come to you. We will value your coin & jewelry collection. Melbourne metro area. All by appointment.</Title>
                )}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Title = styled.h1`
    margin-top: 50px;
`;
export default MainPageTitle;
