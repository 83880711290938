import React from 'react';
import CheckboxComponent from '../../../ui/CheckboxComponent';
import CounterComponent from '../../../ui/CounterComponent';
import DeleteIMG from '../../../assets/svgs/cart_delete_icon.svg';
import styled from 'styled-components';
import { IProduct } from '../../../api/cart/cartTypes';
import PlaceholderIMG from '../../../assets/svgs/cart_placeholder_img.svg';
import { roundToTwoDecimals } from '../../../helpers/round';

interface ICartProductElem {
    item: IProduct;
    isSelected: boolean;
    onClickPlus: (id: string, quantity: number) => void;
    onClickMinus: (id: string, quantity: number) => void;
    addProductToList: (item: IProduct) => void;
    deleteAction: (id: string) => void;
}

const Index: React.FC<ICartProductElem> = ({ item, onClickPlus, onClickMinus, addProductToList, isSelected, deleteAction }) => {
    /*const [isSelected, setIsSelected] = useState(false);*/
    return (
        <TableElem key={`product_block${item.id} ${Index}`}>
            <ProductInfo>
                <ProductCheckBox>
                    <CheckboxComponent
                        value={isSelected}
                        onClick={() => {
                            addProductToList(item);
                        }}
                        width={'18px'}
                        height={'18px'}
                    />
                </ProductCheckBox>
                <ProductImage>
                    <img
                        style={{ objectFit: 'cover' }}
                        draggable={'false'}
                        width={'80px'}
                        height={'80px'}
                        src={item.medias[0] && item.medias[0].url ? item.medias[0].url : PlaceholderIMG}
                        alt={'placeholder'}
                    />
                </ProductImage>
                <ProductText>{item.name}</ProductText>
            </ProductInfo>
            <CounterBlock>
                <CounterComponent
                    onClickPlus={() => onClickPlus(item.id, item.quantity)}
                    onClickMinus={() => onClickMinus(item.id, item.quantity)}
                    children={item.quantity + ''}
                />
            </CounterBlock>
            <PriceBlock>
                <Prices>
                    <div>${roundToTwoDecimals(item.price * item.quantity)}</div>
                    <div style={{ color: '#8A8A8A', display: 'flex', gap: 3.5 }}>
                        <div>{item.quantity}</div>
                        <div>*</div>
                        <div>${roundToTwoDecimals(item.price)}</div>
                    </div>
                </Prices>
                <DeleteBlock>
                    <img onClick={() => deleteAction(item.id)} src={DeleteIMG} alt="delete" style={{ cursor: 'pointer' }} />
                </DeleteBlock>
            </PriceBlock>
        </TableElem>
    );
};

const Prices = styled.div`
    padding-left: 65px;
    @media (max-width: 1420px) {
        padding-left: 10vw;
    }
    @media (max-width: 870px) {
        padding-left: 60px;
    }
    @media (max-width: 770px) {
        display: flex;
        flex-direction: row;
        padding-left: 35px;
        min-width: 160px;
        gap: 20px;
    }
`;
const ProductCheckBox = styled.div`
    @media (max-width: 770px) {
        position: absolute;
        /*    margin-top: 10px;
    margin-left: 10px;*/
    }
`;

const ProductImage = styled.div`
    width: 80px;
    height: 80px;
`;

const ProductText = styled.div`
    max-width: 300px;
    @media (max-width: 770px) {
        height: 60px;
    }
`;

const DeleteBlock = styled.div`
    //position: relative;
    @media (max-width: 1295px) {
        margin-right: 10px;
    }
    @media (max-width: 770px) {
        margin-top: 90px;
        margin-left: 8vw;
    }
`;

const PriceBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 770px) {
        position: absolute;
        margin-top: 60px;
        margin-left: 70px;
        height: 40px;
    }
`;

const CounterBlock = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 770px) {
        width: 110px;
    }
`;

const ProductInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    @media (max-width: 770px) {
        align-items: flex-start;
        //flex-direction: column;
    }
`;

const TableElem = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 4fr 1fr 2fr;
    height: 100px;
    min-width: 850px;
    padding-bottom: 20px;
    border-bottom: 1px solid #9fabd9;
    @media (max-width: 1420px) {
        min-width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
    }
    @media (max-width: 770px) {
        grid-template-columns: 1fr;
        padding-bottom: 50px;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        gap: 5px;
    }
`;

export default Index;
