import React, { useEffect } from 'react';
import FAQs from '../components/faq';
import Layout from '../components/Layout';
import { Container } from '../ui/container';
import { Helmet } from 'react-helmet';
const Faq = () => {
    useEffect(() => {
        document.title = 'FAQ | Bullion Network Melbourne';
    }, []);

    return (
        <Layout>
            <Helmet>
                <meta property="og:site_name" content="Bullion Network" />
                <meta property="og:title" content=" FAQ | Bullion Network Melbourne" />
                <meta property="og:description" content="Bullion Network Melbourne | Frequently Asked Questions" />
                <meta property="description" content="Bullion Network Melbourne | Frequently Asked Questions" />
            </Helmet>
            <Container>
                <FAQs />
            </Container>
        </Layout>
    );
};

export default Faq;
