import React from 'react';

import Layout from '../components/Layout';
import {Container} from '../ui/container';
import NotFoundComponent from "../components/404";
import styled from "styled-components";

const NotFound = () => {
    return (
        <Layout>
            <Container>
                <Component>
                    <NotFoundComponent/>
                </Component>
            </Container>
        </Layout>

    );
};
const Component = styled.div`
  margin-top: 160px ;
  margin-bottom: 300px;
  @media (max-width: 600px){
    margin-top: 111px ;
    margin-bottom: 552px;
  }
`
export default NotFound;
