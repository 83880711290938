import api from "api"
import {IAddProduct, ICart, IQuantity} from "@api/cart/cartTypes";

export const cartApi = {
    async getCart() {
        return (await api.get<ICart>('/cart')).data
    },
    async addProductToCart(data: IAddProduct) {
        return (await api.post<ICart>('cart', data)).data
    },
    async updateQuantityProductInCart(id: string, data: IQuantity) {
        return (await api.put<ICart>(`/cart/${id}`, data)).data
    },
    async deleteProductFromCart(id: string) {
        return (await api.delete<ICart>(`/cart/${id}`)).data
    },
}