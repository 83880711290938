import {ICategoriesData, ILivePriceMainInfo, IPaginationData, IShopData} from "@api/shop/shopTypes";
import api from "../../api";
import {IShopProduct} from "../../api/cart/cartTypes";

export const shopApi = {
    async getShopInfo(data?:IPaginationData){
        return (await api.get<IShopData>(`shop`,{
            params:{
                page: data?.page,
                page_limit: data?.pageLimit,
                sort: data?.sort,
                category_id: data?.category_id,
                title: data?.title,
            }
        })).data
    },
    async getCurrentProductInfo(id:string){
        return (await api.get<IShopProduct>(`shop/${id}`)).data
    },
    async getRecommendedProducts(){
        return (await api.get<IShopProduct[]>(`shop/recomended`)).data
    },
    async getCategories(){
        return (await api.get<ICategoriesData[]>(`category`)).data
    },
    async getLpl(currency?: string){
        return (await api.get<ILivePriceMainInfo[]>(`lpl`, {
            params: {
                currency
            }
        })).data
    }
}