import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Container } from '../ui/container';
import styled from 'styled-components';
import { useTypedSelector } from '../hooks/useSelector';
import { IResponseOrderData } from '../api/order/orderTypes';
import { useOrderActions } from '../hooks/useActions';
import { roundToTwoDecimals } from '../helpers/round';

function formatDate(date: string) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = '' + d.getFullYear();
    year = year.slice(2);
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
}

const AfterPayment = () => {
    const { orderInfo } = useTypedSelector((state) => state.orderReducer);
    const { clearMyOrderInfo } = useOrderActions();

    const [orderData, setOrderData] = useState<IResponseOrderData | undefined>(orderInfo);
    const [currentMoneyType, setCurrentMoneyType] = useState('$');

    useEffect(() => {
        setOrderData(orderInfo);
    }, [orderInfo]);

    useEffect(() => {
        const currentData = localStorage.getItem('afterPayment');
        if (currentData) {
            setOrderData(JSON.parse(currentData));
        }

        return () => {
            clearMyOrderInfo();
            localStorage.removeItem('afterPayment');
        };
    }, []);

    return (
        <Layout>
            <Container>
                <Content>
                    <Title>Thank you. Your order has been received.</Title>
                    <MainInfo>
                        <div style={{ borderBottom: '1px solid #DBD5CC', paddingBottom: 20, marginBottom: 10 }}>
                            <SubTitle>Information about the order will be duplicated to your email</SubTitle>
                            <InfoList>
                                <li>
                                    Order number:
                                    {` ${orderData?.id ? orderData.id : ''}`}
                                </li>
                                <li>
                                    Date:
                                    {orderData && ` ${formatDate(orderData.createdAt)}`}
                                </li>
                                <li>
                                    Email:
                                    {` ${orderData?.email ? orderData.email : ''}`}
                                </li>
                                <li>
                                    Total:
                                    {orderData?.total_price ? ` ${currentMoneyType}${roundToTwoDecimals(orderData.total_price)}` : ''}
                                </li>
                                <li>Payment method: Check payments</li>
                            </InfoList>
                            <BlockLikeButton>Order details</BlockLikeButton>
                            <div>
                                <GridTwoItems
                                    style={{ marginBottom: 10 }}
                                    widthSecondAdapt={500}
                                    widthStartAdapt={1268}
                                    gridTemplateColumnsAdapt={'3fr 1fr'}
                                >
                                    <FatLabel>Product:</FatLabel>
                                    <FatLabel>Total:</FatLabel>
                                </GridTwoItems>
                                <ProductList>
                                    {orderData?.products.map((item, index) => {
                                        return (
                                            <GridTwoItems
                                                key={`product ${index}`}
                                                widthSecondAdapt={500}
                                                widthStartAdapt={1268}
                                                gridTemplateColumnsAdapt={'3fr 1fr'}
                                            >
                                                <div>
                                                    {`${item.product.name} `}*{` ${item.amount}`}
                                                </div>
                                                <div>{` ${currentMoneyType}${roundToTwoDecimals(
                                                    +roundToTwoDecimals(item.price) * item.amount
                                                )}`}</div>
                                            </GridTwoItems>
                                        );
                                    })}
                                </ProductList>
                            </div>
                        </div>
                        <div>
                            <PriceBlock>
                                <GridTwoItems width={300}>
                                    <FatLabel>Subtotal:</FatLabel>
                                    <div>
                                        {`${
                                            orderData
                                                ? `${currentMoneyType}${(() => {
                                                      //   let total = 0;
                                                      //   orderData.products.forEach((odpr) => {
                                                      //       //   total += odpr.price * odpr.amount;
                                                      //       total += +roundToTwoDecimals(odpr.product.price) * odpr.amount;
                                                      //   });
                                                      //   //   total -= +orderData.tax;
                                                      //   return roundToTwoDecimals(total);
                                                      return roundToTwoDecimals(
                                                          +roundToTwoDecimals(orderData.total_price) - +roundToTwoDecimals(orderData.tax)
                                                      );
                                                  })()}`
                                                : ''
                                        }`}
                                    </div>
                                </GridTwoItems>
                                <GridTwoItems width={300}>
                                    <FatLabel>Tax:</FatLabel>
                                    <div>{`${orderData ? `${currentMoneyType}${roundToTwoDecimals(orderData.tax)}` : ''}`}</div>
                                </GridTwoItems>
                                <GridTwoItems width={300}>
                                    <FatLabel>Payment method:</FatLabel>
                                    <div>Check payments</div>
                                </GridTwoItems>
                                <GridTwoItems width={300}>
                                    <FatLabel>Total:</FatLabel>
                                    <div>{`${
                                        orderData
                                            ? `${currentMoneyType}${(() => {
                                                  //   let total = 0;
                                                  //   orderData.products.forEach((odpr) => {
                                                  //       //   total += odpr.price * odpr.amount;
                                                  //       total += +roundToTwoDecimals(odpr.price) * odpr.amount;
                                                  //       console.log(total);
                                                  //   });
                                                  //   console.log('final', total);
                                                  //   //   total -= +orderData.tax;
                                                  return roundToTwoDecimals(orderData.total_price);
                                              })()}`
                                            : ''
                                    }`}</div>
                                </GridTwoItems>
                            </PriceBlock>
                            <BlockLikeButton>Billing address</BlockLikeButton>
                            <GridTwoItems gridTemplateColumns={'0.5fr 1fr'} widthStartAdapt={1268}>
                                <InfoBlock>
                                    <div>
                                        <FatSpanLabel>Name: </FatSpanLabel>
                                        {orderData ? `${orderData?.first_name} ${orderData?.last_name}` : ''}
                                    </div>
                                    {orderData?.company_name && (
                                        <div>
                                            <FatSpanLabel>Company: </FatSpanLabel>
                                            {orderData.company_name}
                                        </div>
                                    )}
                                    <div>
                                        <FatSpanLabel>Delivery address: </FatSpanLabel>
                                        {orderData?.address}
                                    </div>
                                    {orderData?.note && (
                                        <div>
                                            <FatSpanLabel>Order notes: </FatSpanLabel>
                                            {orderData.note}
                                        </div>
                                    )}
                                </InfoBlock>
                                <InfoBlock>
                                    <div>
                                        <FatSpanLabel>Phone: </FatSpanLabel>
                                        {orderData?.phone}
                                    </div>
                                    <div>
                                        <FatSpanLabel>Email: </FatSpanLabel>
                                        {orderData?.email}
                                    </div>
                                </InfoBlock>
                            </GridTwoItems>
                        </div>
                    </MainInfo>
                </Content>
            </Container>
        </Layout>
    );
};

const BlockLikeButton = styled.div`
    width: 190px;
    height: 46px;
    background: #f8c882;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 19px;
`;

const InfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ProductList = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const PriceBlock = styled.div`
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

interface IGridTwoItems {
    width?: number | string;
    gap?: number | string;
    gridTemplateColumns?: string;
    widthStartAdapt?: number | string;
    widthSecondAdapt?: number | string;
    gridTemplateColumnsAdapt?: string;
}

const GridTwoItems = styled.div<IGridTwoItems>`
    width: ${(props) => (!props?.width ? '100%' : typeof props.width === 'number' ? `${props.width}px` : props.width)};
    gap: ${(props) => (!props?.gap ? 'unset' : typeof props.gap === 'number' ? `${props.gap}px` : props.gap)};
    display: grid;
    grid-template-columns: ${(props) => (!props?.gridTemplateColumns ? '1.2fr 1fr' : props.gridTemplateColumns)};
    @media (max-width: ${(props) => (typeof props.widthStartAdapt === 'number' ? `${props.widthStartAdapt}px` : props.widthStartAdapt)}) {
        grid-template-columns: ${(props) => (!props?.gridTemplateColumnsAdapt ? '1fr' : props.gridTemplateColumnsAdapt)};
    }
    @media (max-width: ${(props) => (typeof props.widthSecondAdapt === 'number' ? `${props.widthSecondAdapt}px` : props.widthSecondAdapt)}) {
        grid-template-columns: 2fr 1fr;
    }
`;

const ButtonContainer = styled.div`
    width: 190px;
    margin-bottom: 15px;
`;

const InfoList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 20px;
    margin-bottom: 50px;
`;

const MainInfo = styled.div`
    margin-top: 20px;
`;

const FatLabel = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
`;

const FatSpanLabel = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
`;

const SubTitle = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
`;

const Title = styled.div`
    font-weight: 900;
    font-size: 40px;
    line-height: 53px;

    color: #0b1126;
`;

const Content = styled.div`
    font-family: 'Inter', serif;
    padding: 50px 16px 20px 16px;
    min-height: 850px;
`;

export default AfterPayment;
