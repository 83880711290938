const MAX_LENGTH_STRING = 127;
const MIN_LENGTH_STRING = 2;
const MAX_LENGTH_NUMBER = 13;
const MAX_LENGTH_ADDRESS = 255;
const MAX_LENGTH_PASSWORD = 63;
const MIN_LENGTH_NUMBER = 6;
const MIN_ABN_ACN_NUMBER = 9;
const MAX_ABN_ACN_NUMBER = 11;

export enum FIELD_TYPE {
    firstName = 'First name',
    lastName = 'Last name',
    email = 'Email',
    phone = 'Phone',
    residentialAddress = 'Residential address',
    businessRegistrationAddress = 'Business registration address',
    deliveryAddress = 'Delivery address',
    actualAddress = 'Actual address',
    newPassword = 'New password',
    oldPassword = 'Old password',
    confirmPassword = 'Confirm password',
    website = 'Website',
    abnAcn = 'ABN or ACN',
    companyName = 'Company name',
}

const isNullValue = (value: RegExpMatchArray | null, type: FIELD_TYPE) => {
    return value === null ? `The field ${type} is filled in incorrectly` : '';
};

export const checkPassword = (password: string, confirmPassword: string) => {
    return password === confirmPassword ? '' : 'The entered passwords do not match';
};

export const validateField = (field: string, type: FIELD_TYPE) => {
    switch (type) {
        case FIELD_TYPE.abnAcn: {
            if (field !== '') {
                let abnAcnValidation = field.match(new RegExp(/^[0-9]{9,11}$/));
                if (field.length < MIN_ABN_ACN_NUMBER || field.length > MAX_ABN_ACN_NUMBER) {
                    return `The field ${type} has too ${field.length < MIN_ABN_ACN_NUMBER ? 'few' : 'many'} characters`;
                }

                return isNullValue(abnAcnValidation, type);
            }
            return `Please fill in the field ${type}`;
        }
        case FIELD_TYPE.companyName:
        case FIELD_TYPE.firstName:
        case FIELD_TYPE.lastName: {
            if (field !== '') {
                let nameValidation = field.match(new RegExp(/^[a-zA-Z“\-'`”]{2,}$/));
                if (field.length < MIN_LENGTH_STRING || field.length > MAX_LENGTH_STRING) {
                    return `The field ${type} has too ${field.length < MIN_LENGTH_STRING ? 'few' : 'many'} characters`;
                }

                return isNullValue(nameValidation, type);
            }
            return `Please fill in the field ${type}`;
        }
        case FIELD_TYPE.email: {
            if (field !== '') {
                let emailValidation = field.match(new RegExp(/^[^@ \t\r\n^]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/));

                return isNullValue(emailValidation, type);
            }
            return `Please fill in the field ${type}`;
        }
        case FIELD_TYPE.phone: {
            if (field !== '') {
                let phoneValidation = field.match(new RegExp(/^\+[0-9]{5,12}$/));

                if (field.length < MIN_LENGTH_NUMBER || field.length > MAX_LENGTH_NUMBER) {
                    return `The field ${type} has too ${field.length < MIN_LENGTH_NUMBER ? 'few' : 'many'} characters`;
                }

                return isNullValue(phoneValidation, type);
            }
            return `Please fill in the field ${type}`;
        }
        case FIELD_TYPE.businessRegistrationAddress:
        case FIELD_TYPE.residentialAddress:
        case FIELD_TYPE.deliveryAddress:
        case FIELD_TYPE.actualAddress: {
            if (field !== '') {
                let addressValidation = field.match(
                    new RegExp(
                        /^[0-9!#$%&'*+—/=?^_`{|}~]* [a-zA-Z!#$%&'*+—/=?^_`{|}~]+ ?[a-zA-Z!#$%&'*+—/=?^_`{|}~]*, ?[a-zA-Z!#$%&'*+—/=?^_`{|}~]+, ?[0-9!#$%&'*+—/=?^_`{|}~]+, ?[a-zA-Z!#$%&'*+—/=?^_`{|}~]+$/
                    )
                );

                if (field.length < MIN_LENGTH_NUMBER || field.length > MAX_LENGTH_ADDRESS) {
                    return `The field ${type} has too ${field.length < MIN_LENGTH_NUMBER ? 'few' : 'many'} characters`;
                }
                return isNullValue(addressValidation, type);
            }
            return `Please fill in the field ${type}`;
        }
        case FIELD_TYPE.newPassword:
        case FIELD_TYPE.oldPassword:
        case FIELD_TYPE.confirmPassword: {
            if (field !== '') {
                let resAddressValidation = field.match(new RegExp(/^[a-zA-Z0-9!#$%&'*+—/=?^_`{|}~]{6,63}$/));

                if (field.length < MIN_LENGTH_NUMBER || field.length > MAX_LENGTH_PASSWORD) {
                    return `The field ${type} has too ${field.length < MIN_LENGTH_NUMBER ? 'few' : 'many'} characters`;
                }
                return isNullValue(resAddressValidation, type);
            }
            return `Please fill in the field ${type}`;
        }
        default: {
            return '';
        }
    }
};
