import {
    cartActions,
    IDeleteProductFromCart,
    IEndCartAction,
    IErrorCartAction,
    IGetCart,
    IStartCartAction,
    IUpdateQuantity,
} from '../../store/types/cartTypes';
import { CartActionTypes } from '../../store/constants/cartConstants';
import { IErrors } from '../../api/user/userTypes';
import { IAddProduct, ICart, IQuantity } from '../../api/cart/cartTypes';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { cartApi } from '../../api/cart/cartApi';
import { addToQueue, showSnackbar } from '../../store/actions/snackbarActions';
import { SnakeTypes } from '../../ui/components/modals/SnakeBar';
import { useSelector } from 'react-redux';
import { useTypedSelector } from '../../hooks/useSelector';
import { setResponseMessage } from './userActions';
import { getShopData } from './shopActions';

export const startCartAction = (): IStartCartAction => ({
    type: CartActionTypes.START_CART_ACTION,
});

const errorCartAction = (payload: IErrors[]): IErrorCartAction => ({
    type: CartActionTypes.ERROR_CART_ACTION,
    payload,
});

export const getCart_ = (payload: ICart | undefined): IGetCart => ({
    type: CartActionTypes.GET_CART,
    payload,
});

export const endCartAction = (): IEndCartAction => ({
    type: CartActionTypes.END_CART_ACTION,
});

export const getCart = () => async (dispatch: Dispatch<cartActions>) => {
    try {
        dispatch(startCartAction());
        const response = await cartApi.getCart();
        dispatch(getCart_(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(getCart_(undefined));
        dispatch(errorCartAction(e.response?.data.message));
    }
};

const updateQuantityProductInCart_ = (payload: ICart): IUpdateQuantity => ({
    type: CartActionTypes.UPDATE_QUANTITY,
    payload,
});

export const updateQuantityProductInCart = (id: string, data: IQuantity) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startCartAction());
        const response = await cartApi.updateQuantityProductInCart(id, data);
        dispatch(updateQuantityProductInCart_(response));
    } catch (_e) {
        const e = _e as AxiosError;
        if (e.response?.data.message.includes('this quantity is not available.')) {
            dispatch(addToQueue({ content: 'Out of Stock', type: SnakeTypes.error }));
        } else {
            dispatch(addToQueue({ content: e.response?.data.message, type: SnakeTypes.error }));
        }
        dispatch(errorCartAction(e.response?.data.message));
    }
};

const deleteProductFromCart_ = (payload: ICart): IDeleteProductFromCart => ({
    type: CartActionTypes.DELETE_PRODUCT_FROM_CART,
    payload,
});

export const deleteProductFromCart = (id: string) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startCartAction());
        const response = await cartApi.deleteProductFromCart(id);
        dispatch(deleteProductFromCart_(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(addToQueue({ content: e.response?.data.message, type: SnakeTypes.error }));
        dispatch(errorCartAction(e.response?.data.message));
    }
};

export const addProductToCart = (data: IAddProduct) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startCartAction());
        // const user = useTypedSelector((state) => state.userReducer.user);
        if (data.user) {
            if (data.user.role === 'admin' || data.user.role === 'super-admin') {
                return dispatch(
                    addToQueue({
                        content: 'Not to cause problems with QuickBooks admins are not allowed to purchase. Use an account with role natural',
                        type: SnakeTypes.warning,
                        ms: 4000,
                    })
                );
            }
            const response = await cartApi.addProductToCart(data);
            dispatch(getCart_(response));


            if (data.getShopParams) {
                dispatch(getShopData(data.getShopParams));
            }

            //dispatch(showSnackbar({content: "product was successfully added to cart", type: SnakeTypes.success}))
            dispatch(addToQueue({ content: 'product was successfully added to cart', type: SnakeTypes.success }));
        } else {
            dispatch(setResponseMessage({ message: 'open_sign_in_modal' }));
            // dispatch(addToQueue({ content: 'Sign in to add products to cart', type: SnakeTypes.warning, ms: 4000 }));
        }
    } catch (_e: any) {
        const e = _e as AxiosError;
        if (e.response?.data.message.includes('this quantity is not available.')) {
            dispatch(addToQueue({ content: 'Out of Stock', type: SnakeTypes.error }));
        } else {
            dispatch(addToQueue({ content: e.response?.data.message, type: SnakeTypes.error }));
        }
        dispatch(errorCartAction(e.response?.data.message));
    }
};
