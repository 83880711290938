import {IErrors} from "../../api/user/userTypes";
import {ICategoriesData, ILivePriceMainInfo} from "../../api/shop/shopTypes";
import {shopTypes} from "../../store/types/shopTypes";
import {ShopActionsTypes} from "../../store/constants/shopConstants";
import {IShopProduct} from "../../api/cart/cartTypes";

interface IShopState {
    error: IErrors[] | undefined
    loading: boolean
    shop: IShopProduct[]
    total_items: number | undefined
    page_limit: number | undefined
    currentProduct: IShopProduct | undefined
    recommendedProducts: IShopProduct[]
    categories: ICategoriesData[]
    lplInfo: ILivePriceMainInfo[]
}

const initialState: IShopState = {
    error: undefined,
    loading: false,
    shop: [],
    total_items: undefined,
    page_limit: undefined,
    currentProduct: undefined,
    recommendedProducts: [],
    categories: [],
    lplInfo: [],
}

const startLoading = (state: IShopState) => {
    return {...state, loading: true, error: undefined}
}

const stopErrorLoading = (state: IShopState, error: IErrors[]) => {
    return {...state, loading: false, error: error}
}


const shopReducer = (state = initialState, action: shopTypes): IShopState => {
    switch (action.type) {
        case ShopActionsTypes.START_SHOP_ACTION: {
            return startLoading(state)
        }
        case ShopActionsTypes.ERROR_SHOP_ACTION: {
            return stopErrorLoading(state, action.payload)
        }

        case ShopActionsTypes.GET_SHOP_DATA:{
            return {
                ...state,
                shop: action.payload.data,
                total_items: action.payload.total_items,
                page_limit: action.payload.page_limit,
            }
        }
        case ShopActionsTypes.GET_SHOP_PRODUCT:{
            return {
                ...state,
                currentProduct: action.payload
            }
        }
        case ShopActionsTypes.GET_CATEGORIES_PRODUCTS:{
            return {
                ...state,
                categories: action.payload
            }
        }

        case ShopActionsTypes.GET_LIVE_PRICE_DATA:
            return {
                ...state,
                lplInfo: action.payload
            }

        case ShopActionsTypes.GET_RECOMMENDED_PRODUCTS:
            return {
                ...state,
                recommendedProducts: action.payload
            }


        default: {
            return state
        }
    }
};

export default shopReducer;
