import {ISnackbarData, ISnakeItem, snackbarAction} from "../../store/types/snackbarTypes";
import {SnackbarActionTypes} from "../../store/constants/snackbarConstants";
import {SnakeTypes} from "../../ui/components/modals/SnakeBar";

interface ISnackbarState {
    type: SnakeTypes | undefined
    content: string | undefined
    queue: ISnackbarData[]
    ms: number
}

const initialState: ISnackbarState = {
    type: undefined,
    content: undefined,
    queue: [],
    ms: 2000
}

const snackbarReducer = (state = initialState, action: snackbarAction): ISnackbarState => {
    switch (action.type) {
        case SnackbarActionTypes.SET_SNACKBAR:
            return {
                ...state, ...action.payload
            }

        case SnackbarActionTypes.ADD_TO_QUEUE:
            return {
                ...state,
                queue: [...state.queue, {
                    type: action.payload.type,
                    content: action.payload.content,
                    ms: action.payload.ms
                }]
            }
        case SnackbarActionTypes.REMOVE_FROM_QUEUE:
            state.queue.shift()
            return {
                ...state,
                queue: state.queue
            }

        default:
            return state;
    }
}


export default snackbarReducer;
