import React from 'react';
import styled from 'styled-components';

type StyledButtonProps = {
    onClick?: () => void;
    height?: string;
    children: React.ReactNode;
    disabled?: boolean;
    backgroundColor?: string;
};

const StyledButton = (props: StyledButtonProps) => {
    return (
        <Button onClick={props.onClick} disabled={props.disabled} style={{ height: props.height, backgroundColor: props.backgroundColor }}>
            {props.children}
        </Button>
    );
};

export default StyledButton;

export const Button = styled.button`
    font-family: Inter, serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    background-color: #f8c882;
    width: 100%;
    border-radius: 6px;
    border-width: 0;
    cursor: pointer;
    transition: background 0.15s, color 0.15s;
    &:hover {
        background: linear-gradient(0deg, rgba(166, 166, 166, 0.2), rgba(166, 166, 166, 0.2)), #f8c882;
    }

    &:active {
        background: linear-gradient(0deg, rgba(101, 101, 101, 0.2), rgba(101, 101, 101, 0.2)), #f8c882;
        color: #ffffff;
    }

    &:disabled {
        background: #d3d3d3;
        color: #ffffff;
        cursor: default;
    }
`;
