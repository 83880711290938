import {
    ICLearMyOrderInfo,
    ICreateOrder,
    IErrorOrderAction,
    IGetMyOrders,
    IStartOrderAction,
    IStopOrderAction,
    orderActions,
} from '../../store/types/orderTypes';
import { OrderActionTypes } from '../../store/constants/orderConstants';
import { IErrors } from '../../api/user/userTypes';
import { ICreateOrderData, IMyOrdersData, IResponseOrderData } from '../../api/order/orderTypes';
import { Dispatch } from 'redux';
import { orderApi } from '../../api/order/orderApi';
import { AxiosError } from 'axios';
import { IPaginationData } from '../../api/shop/shopTypes';
import { showSnackbar } from '../../store/actions/snackbarActions';
import { SnakeTypes } from '../../ui/components/modals/SnakeBar';

const startCartAction = (): IStartOrderAction => ({
    type: OrderActionTypes.START_ORDER_ACTION,
});

const stopCartAction = (): IStopOrderAction => ({
    type: OrderActionTypes.STOP_ORDER_ACTION,
});

const errorCartAction = (payload: IErrors[]): IErrorOrderAction => ({
    type: OrderActionTypes.ERROR_ORDER_ACTION,
    payload,
});

const addOrderInfo = (payload: IResponseOrderData): ICreateOrder => ({
    type: OrderActionTypes.CREATE_ORDER,
    payload,
});

export const createOrder = (data: ICreateOrderData) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startCartAction());
        const response = await orderApi.createOrder(data);
        dispatch(addOrderInfo(response));
        localStorage.setItem('afterPayment', JSON.stringify(response));
        dispatch(stopCartAction());
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: e.response?.data.message, type: SnakeTypes.error }));
        dispatch(errorCartAction(e.response?.data.message));
    }
};

const getMyOrders_ = (payload: IMyOrdersData): IGetMyOrders => ({
    type: OrderActionTypes.GET_MY_ORDERS,
    payload,
});

export const getMyOrders = (data: Omit<IPaginationData, 'sort' | 'category_id'>) => async (dispatch: Dispatch<orderActions>) => {
    try {
        dispatch(startCartAction());
        const response = await orderApi.getOrders(data);
        dispatch(getMyOrders_(response));
        dispatch(stopCartAction());
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorCartAction(e.response?.data.message));
    }
};

export const clearMyOrderInfo = (): ICLearMyOrderInfo => ({
    type: OrderActionTypes.CLEAR_MY_ORDER_INFO,
});
