import {IErrors} from "../../api/user/userTypes";
import {searchActions} from "../../store/types/searchTypes";
import {SearchActionsTypes} from "../../store/constants/searchConstants";
import {IShopProduct} from "../../api/cart/cartTypes";

interface ISearchState {
    error: IErrors[] | undefined
    loading: boolean
    searchShop: IShopProduct[]
}

const initialState: ISearchState = {
    error: undefined,
    loading: false,
    searchShop: []
}

const startLoading = (state: ISearchState) => {
    return {...state, loading: true, error: undefined}
}

const stopErrorLoading = (state: ISearchState, error: IErrors[]) => {
    return {...state, loading: false, error: error}
}


const searchReducer = (state = initialState, action: searchActions): ISearchState => {
    switch (action.type) {
        case SearchActionsTypes.START_SEARCH_ACTION: {
            return startLoading(state)
        }
        case SearchActionsTypes.ERROR_SEARCH_ACTION: {
            return stopErrorLoading(state, action.payload)
        }


        case SearchActionsTypes.GET_SEARCH_DATA:
            return {
                ...state,
                searchShop: action.payload.data,
                loading: false
            }

        case SearchActionsTypes.CLEAR_SEARCH_DATA:
            return {
                ...state,
                searchShop: []
            }

        default: {
            return state
        }
    }
};

export default searchReducer;

