import React from 'react';
import styled from "styled-components";
import ButtonCategories from "./ButtonCategories";
import {IList} from "@ui/Selector";

interface ICategoryBar {
    list: IList[];
    setCurrentCategory: (value: IList) => void
}

const CategoriesBar: React.FC<ICategoryBar> = ({list, setCurrentCategory}) => {
    return (
        <div>
            <Buttons>
                {
                    list ? list.map((item, index) => (
                        <React.Fragment key={`card-index-${index}`}>
                            <ButtonCategories
                                isFirst={index === 0}
                                isLast={index === list.length-1}
                                onClick={() => {
                                    setCurrentCategory(item)
                                }}
                                index={index}
                                title={item.title || ""}
                            />
                        </React.Fragment>
                    )) : <></>
                }
            </Buttons>
        </div>
    );
};


const Buttons = styled.div`
  border-radius: 20px;
  box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
  width: 300px; 
/*  padding-top: 18px;
  padding-bottom: 18px;*/
  //height: 475px;
/*  @media (max-width: 1290px) and (min-width: 900px) {
    width: 250px;
  }*/
`
export default CategoriesBar;
