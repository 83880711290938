import React, { useEffect, useState } from 'react';
import { Heading1, Heading4, Heading5 } from '../../ui/HeadingTags';
import styled from 'styled-components';
import Loading from '../../components/loading';

interface IPrivacy {
    statics: any;
    isLoading: boolean;
}

const PrivacyComponent: React.FC<IPrivacy> = ({ statics, isLoading }): JSX.Element => {
    const [loading, setLoading] = useState(isLoading);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    if (loading) {
        return <Loading />;
    }

    return (
        <Wrapper>
            {statics.Header && (
                <div>
                    <Heading1>{statics.Header.data}</Heading1>
                    <Container>
                        <div>
                            <Heading5 dangerouslySetInnerHTML={{ __html: statics.Text.data }} />
                        </div>
                    </Container>
                </div>
            )}
        </Wrapper>
    );
};
const BigCapture = styled.img`
    margin-right: 33px;
    float: left;
    max-width: 45vw;
    max-height: 45vh;
    @media (max-width: 1290px) {
        width: 45vw;
    }
    @media (max-width: 600px) {
        width: 40vw;
        margin-right: 20px;
    }
`;
const Wrapper = styled.div`
    min-height: 200px;
    @media (max-width: 1290px) {
        max-width: 95vw;
        width: 100%;
        padding: 0 10px;
    }
`;
const MyHeading = styled.div`
    /* font-family: Playfair Display, serif; */
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    @media (max-width: 900px) {
        font-size: 24px;
        line-height: 36px;
    }
`;
const Container = styled.div`
    display: flex;
`;
export default PrivacyComponent;
