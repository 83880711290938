import React from 'react';
import styled from 'styled-components';
import StyledButton from '../../ui/StyledButton';

const NotFoundComponent = () => {
    return (
        <Wrapper>
            <BigText> 404 </BigText>
            <SmallText> Page not found! </SmallText>
            <Button>
                <StyledButton
                    height={'50px'}
                    onClick={() => {
                        window.location.href = '/';
                    }}
                >
                    Go back to the main page
                </StyledButton>
            </Button>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    font-family: Inter, serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
const BigText = styled.div`
    font-size: 300px;
    font-weight: 900;
    line-height: 363px;
    @media (max-width: 600px) {
        font-size: 140px;
        line-height: 170px;
    }
`;
const SmallText = styled.div`
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
`;

const Button = styled.div`
    margin-top: 82px;
    max-width: 454px;
    width: 100%;
    @media (max-width: 600px) {
        max-width: 90vw;
    }
`;
export default NotFoundComponent;
