export enum CartActionTypes {
    START_CART_ACTION = 'START_CART_ACTION',
    ERROR_CART_ACTION = 'ERROR_CART_ACTION',

    GET_CART = 'GET_CART',
    UPDATE_QUANTITY = 'UPDATE_QUANTITY',
    DELETE_PRODUCT_FROM_CART = 'DELETE_PRODUCT_FROM_CART',

    CREATE_ORDER = 'CREATE_ORDER',

    END_CART_ACTION = 'END_CART_ACTION',
}
