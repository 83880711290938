import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import userReducer from "./reducers/userReducer";
import snackbarReducer from "./reducers/snackbarReducer";
import metalReducer from "../store/reducers/metalReducer";
import cartReducer from "../store/reducers/cartReducer";
import shopReducer from "../store/reducers/shopReducer";
import orderReducer from "../store/reducers/orderReducer";
import searchReducer from "../store/reducers/searchReducer";

const reducers = combineReducers({
    userReducer,
    snackbarReducer,
    metalReducer,
    cartReducer,
    shopReducer,
    orderReducer,
    searchReducer,
});

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

export type RootState = ReturnType<typeof reducers>;
