import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Catalogs from '../components/catalog/cards/Catalog';
import { Container } from '../ui/container';
import Layout from '../components/Layout';
import Paginator from '../ui/components/paginator';
import { useTypedSelector } from '../hooks/useSelector';
import { Helmet } from 'react-helmet';

const Catalog = () => {
    const { total_items } = useTypedSelector((state) => state.shopReducer);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(12);

    // if (process.env.REACT_APP_ENV === 'prod') {
    //     return (
    //         <Layout>
    //             <Container>
    //                 <WholePage>
    //                     <Title>Shop</Title>
    //                     <Wrapper>
    //                         <ShopClosed>
    //                             <span>Shop is under development. Please come back later</span>
    //                         </ShopClosed>
    //                     </Wrapper>
    //                 </WholePage>
    //             </Container>
    //         </Layout>
    //     );
    // }

    useEffect(() => {
        document.title = 'Shop | Bullion Network Melbourne';
    }, []);

    return (
        <Layout>
            <Helmet>
                <meta property="og:site_name" content="Bullion Network" />
                <meta property="og:title" content=" Shop | Bullion Network Melbourne" />
                <meta
                    property="og:description"
                    content="Demo shop page with gold and silver bullion products live spot pricing. Melbourne, Australia"
                />
                <meta property="description" content="Demo shop page with gold and silver bullion products live spot pricing. Melbourne, Australia" />
                {/* <meta
                    property="og:description"
                    content="Shop for Gold & Silver Bullions & rare coins collectibles. Australia wide Shipping from $15 with signature & tracking."
                />
                <meta
                    property="description"
                    content="Shop for Gold & Silver Bullions & rare coins collectibles. Australia wide Shipping from $15 with signature & tracking."
                /> */}
            </Helmet>
            <Container>
                <WholePage>
                    <Title>Shop</Title>
                    <Wrapper>
                        <Catalogs currentPage={currentPage} pageLimit={pageLimit} setCurrentPage={setCurrentPage} />
                    </Wrapper>
                    <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
                        <PaginatorWrapper></PaginatorWrapper>
                        <Paginator max={Math.ceil(Number(total_items) / pageLimit)} page={currentPage} setPage={setCurrentPage} />
                    </div>
                </WholePage>
            </Container>
        </Layout>
    );
};
const WholePage = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 100%;
    max-width: calc(100vw - 30px);
    padding-bottom: 100px;
    @media (max-width: 900px) {
        max-width: calc(100vw - 30px);
        padding: 0 15px;
    }
`;

const PaginatorWrapper = styled.div`
    width: 30%;
    @media (max-width: 1080px) {
        width: 0;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    @media (max-width: 1080px) {
        //align-items: center;
    }
`;

const ShopClosed = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 150px;

    > span {
        font-size: 36px;
    }
`;

const Title = styled.h1`
    /* font-family: 'Playfair Display', serif; */
    font-style: normal;
    font-size: 40px;
    font-weight: 700;
    line-height: 53.32px;
    color: #0b1126;
    margin: 50px 0;
    @media (max-width: 767px) {
        font-size: 36px;
    }
`;
export default Catalog;
