export enum OrderActionTypes {
    START_ORDER_ACTION = 'START_ORDER_ACTION',
    STOP_ORDER_ACTION = 'STOP_ORDER_ACTION',
    ERROR_ORDER_ACTION = 'ERROR_ORDER_ACTION',

    CREATE_ORDER = 'CREATE_ORDER',
    GET_MY_ORDERS = 'GET_MY_ORDERS',

    CLEAR_MY_ORDER_INFO = 'CLEAR_MY_ORDER_INFO',
}
