import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../hooks/useSelector';
import Checkout from '../pages/checkout';
import Settings from '../pages/settings';
import Activate from '../pages/activate';
import { useMetalActions, useUserActions } from '../hooks/useActions';
import CreateNewPassword from '../pages/createNewPassword';
import AboutUs from '../pages/aboutUs';
import ContactUs from '../pages/contactUs';
import Faq from '../pages/faq';
import NotFound from '../pages/404';
import MainPage from '../pages/mainPage';
import Loading from '../components/loading';
import Catalog from '../pages/shop';
import Orders from '../pages/orders';
import Cart from '../pages/cart';
import AfterPayment from '../pages/afterPayment';
import Product from '../pages/product';
import Charts from '../pages/charts';
import VerifyAccount from '../pages/verification';
import AccountActions from '../pages/accountActions';
import PrivacyPolicy from '../pages/privacy';
import SubscribeToNewsletter from '../pages/subscribeToNewsletter';
import TermsAndConditions from '../pages/termsAndConditions';

const Router = () => {
    const token = localStorage.getItem('accessToken');
    const { getUser, initialize } = useUserActions();
    const { getMetalApiStatus } = useMetalActions();
    const navigate = useNavigate();
    const { user, isInitialize, isVerified } = useTypedSelector((state) => state.userReducer);

    useEffect(() => {
        getMetalApiStatus();
        if (token) {
            getUser();
        } else {
            initialize();
        }
    }, []);

    /*    useEffect(() => {
            setIsInitialized(false);
            //get user data
        }, [dispatch])*/

    /*
        useEffect(() => {
            setIsInitialized(true)
        }, [user])
    */
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('/behalf/') && location.pathname.split('/behalf/')[1]) {
            localStorage.setItem('behalf', location.pathname.split('/behalf/')[1]);
            window.location.href = '/';
        } /*else {
            localStorage.removeItem('behalf');
        }*/
    }, [location.pathname]);

    if (!isInitialize) {
        return <Loading />;
    }
    // May add !localStorage behalf check
    // if (user && (isVerified.mail === false || isVerified.phone === false) && user.role !== 'admin' ) {
    if (user && (isVerified.mail === false || isVerified.phone === false)) {
        return (
            <Routes>
                <Route path={'/account-actions'} element={<AccountActions />} />
                <Route path={'*'} element={<VerifyAccount />} />;
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path={'/'} element={<MainPage />} />
            {/* <Route path={'/ui'} element={<UI />} /> */}
            <Route path={'/settings'} element={<Settings />} />
            <Route path={'/checkout'} element={<Checkout />} />
            <Route path={'/profile'} element={user ? <Settings /> : <Navigate to={'/'} />} />
            {/* <Route path={'/ui'} element={<UI />} /> */}
            <Route path={'/registration/success'} element={<Activate />} />
            <Route path={'/reset-password'} element={<CreateNewPassword />} />
            <Route path={'/faq'} element={<Faq />} />
            <Route path={'/about_us'} element={<AboutUs />} />
            <Route path={'/privacy_policy'} element={<PrivacyPolicy />} />
            <Route path={'/terms_and_conditions'} element={<TermsAndConditions />} />
            <Route path={'/contact_us'} element={<ContactUs />} />
            <Route path={'/shop'} element={<Catalog />} />
            <Route path={'/shop/:category_name/:category_id'} element={<Catalog />} />
            <Route path={'/orders'} element={<Orders />} />
            <Route path={'/cart'} element={<Cart />} />
            <Route path={'/shop/product/:product_name/:id'} element={<Product />} />
            <Route path={'/after_payment'} element={<AfterPayment />} />
            <Route path={'/charts'} element={<Charts />} />
            {/* <Route path={'/verification'} element={<VerifyAccount />} /> */}
            <Route path={'/account-actions'} element={<AccountActions />} />
            <Route path={'/subscribe_to_newsletter'} element={<SubscribeToNewsletter />} />
            <Route path={'behalf/:id'} />
            <Route path={'*'} element={<NotFound />} />
        </Routes>
    );
};

export default Router;
