import React, { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import SnakeBarElement from '../../components/modals/SnakeBarElement';
import { ISnackbarData } from '../../../store/types/snackbarTypes';

const modalRootElement = document.getElementById('modal');

export enum SnakeTypes {
    success = 'success',
    warning = 'warning',
    error = 'error',
}

interface ISnakeBarProps {
    type: SnakeTypes | undefined;
    content: string | undefined;
    queue: ISnackbarData[];
    timeMS: number;
}

const TRANSITION_MS = 200;

const SnakeBar: React.FC<ISnakeBarProps> = ({ content, type = SnakeTypes.success, timeMS = 2000, queue }) => {
    const element = useMemo(() => document.createElement('div'), []);

    const snakeContainerRef = useRef<any>(null);

    useEffect(() => {
        modalRootElement?.appendChild(element);
        /*        const html = document.querySelector("html");
        if(html) html.style.overflow = "hidden";*/

        return () => {
            /*            if(html) html.style.overflow = "";*/
            modalRootElement?.removeChild(element);
        };
    }, [queue.length]);

    return (
        <React.Fragment>
            {createPortal(
                <SnakeContainer ref={snakeContainerRef}>
                    {queue.length === 0 ? (
                        <SnakeBarElement
                            content={content}
                            timeMS={timeMS}
                            transitionMS={TRANSITION_MS}
                            type={type}
                            isArrayElement={false}
                            queueLength={queue.length}
                            root={snakeContainerRef.current}
                        />
                    ) : (
                        queue.map((item, index) => {
                            return (
                                <SnakeBarElement
                                    key={`SnakeElement${index}`}
                                    content={item.content}
                                    timeMS={item.ms ? item.ms : timeMS}
                                    transitionMS={TRANSITION_MS}
                                    type={item.type}
                                    isArrayElement={true}
                                    queueLength={queue.length}
                                    root={snakeContainerRef.current}
                                    isLast={index === queue.length - 1}
                                    id={index}
                                />
                            );
                        })
                    )}
                </SnakeContainer>,
                element
            )}
        </React.Fragment>
    );
};

const SnakeContainer = styled.div`
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 99999999999;
`;

const SnakeContent = styled.div<{ isShow: boolean }>`
    transition: transform 0.5s;
    transform: scale(${({ isShow }) => (isShow ? 1 : 0)});
    //position: fixed;
    display: flex;
    height: 88px;
    /*  bottom: 20px;
    left: 20px;*/
    margin-top: 10px;
    max-width: 520px;
    min-width: 320px;
    max-height: 1300px;
    border-radius: 4px;
    background-color: #ffffff;
    z-index: 1001;
`;

const CloseButtonContainer = styled.div`
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;
`;

export default SnakeBar;
