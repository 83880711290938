import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UserImage from '../../assets/header_userImage_icon.png';
import LogoutLogo from '../../assets/svgs/header_logout_icon.svg';
import { useTypedSelector } from '../../hooks/useSelector';
import { useUserActions } from '../../hooks/useActions';
import { Link } from 'react-router-dom';
import FirebaseService from '../../services/firebase-service';

interface IMobileMenuProps {
    display: string;
    height: string;
    width: string;
    setOpen: (open: boolean) => void;
    anchorLink: (url: string) => void;
    setOpenModalSignIn: (open: boolean) => void;
    setOpenModalLogIn: (open: boolean) => void;
}

const MobileMenu: React.FC<IMobileMenuProps> = ({ setOpenModalSignIn, setOpenModalLogIn, setOpen, display, height, width, anchorLink }) => {
    const { user } = useTypedSelector((state) => state.userReducer);
    const { logout } = useUserActions();
    const [auth, setAuth] = useState(user ? true : false);
    const firebaseLogout = async () => {
        return await FirebaseService.auth.signOut();
    };
    useEffect(() => {
        setAuth(user ? true : false);
    }, [user]);
    const headerLinks = [
        {
            title: 'Home',
            url: '/',
        },
        {
            title: 'About Us',
            url: '/about_us',
        },
        {
            title: 'Shop',
            url: '/shop',
        },
        {
            title: 'FAQ',
            url: '/faq',
        },
        {
            title: 'Contacts',
            url: '/contact_us',
        },
    ];
    const authLinks = [
        {
            title: 'Profile',
            url: '/profile',
        },
        {
            title: 'Orders',
            url: '/orders',
        },
    ];
    const userInfo = {
        userIcon: UserImage,
        userName:
            (!(user?.role === 'juristic' || user?.role === 'wholesaler') ? user?.first_name : user?.company_name) +
            ' ' +
            (user?.role === 'juristic' || user?.role === 'wholesaler' ? ' ' : user?.surname),
    };

    return (
        <HeaderMenuTablet display={display} height={height} width={width}>
            <WrapperMobileMenu>
                <MobileMenuContainer>
                    <NavigationWrapper>
                        Navigation
                        <BorderLine />
                    </NavigationWrapper>
                    {headerLinks.map((item, index) => {
                        if (item.url.includes('/')) {
                            return (
                                <Links
                                    key={`links-index-${index}`}
                                    to={`${item.url}`}
                                    onClick={() => {
                                        anchorLink(item.url);
                                    }}
                                >
                                    {item.title}{' '}
                                </Links>
                            );
                        } else {
                            return (
                                <Links
                                    key={`links-index-${index}`}
                                    to={`/${item.url}`}
                                    onClick={() => {
                                        anchorLink(item.url);
                                    }}
                                >
                                    {item.title}{' '}
                                </Links>
                            );
                        }
                    })}
                </MobileMenuContainer>
                { /*process.env.REACT_APP_ENV !== 'prod' && */
                    (!auth ? (
                        <div>
                            <LoginLinks
                                onClick={() => {
                                    setOpen(false);
                                    setOpenModalLogIn(true);
                                }}
                            >
                                Log In{' '}
                            </LoginLinks>
                            <LoginLinks
                                onClick={() => {
                                    setOpen(false);
                                    setOpenModalSignIn(true);
                                }}
                            >
                                {' '}
                                Sign Up{' '}
                            </LoginLinks>
                        </div>
                    ) : (
                        <AuthBlock>
                            <UserInfoLine>
                                <UserInfoBlock>
                                    <ImgUser alt="user" src={userInfo.userIcon} />
                                    <UserName>{userInfo.userName}</UserName>
                                </UserInfoBlock>
                                <Log
                                    bg={LogoutLogo}
                                    onClick={() => {
                                        // logout()
                                        firebaseLogout();
                                    }}
                                />
                            </UserInfoLine>
                            <AuthLinksBlock>
                                {authLinks.map((item, index) => {
                                    if (item.url.includes('/')) {
                                        return (
                                            <Links
                                                key={`links-index-${index}`}
                                                to={`${item.url}`}
                                                onClick={() => {
                                                    anchorLink(item.url);
                                                }}
                                            >
                                                {item.title}{' '}
                                            </Links>
                                        );
                                    } else {
                                        return (
                                            <Links
                                                key={`links-index-${index}`}
                                                to={`/${item.url}`}
                                                onClick={() => {
                                                    anchorLink(item.url);
                                                }}
                                            >
                                                {item.title}{' '}
                                            </Links>
                                        );
                                    }
                                })}
                            </AuthLinksBlock>
                        </AuthBlock>
                    ))}
            </WrapperMobileMenu>
        </HeaderMenuTablet>
    );
};
const ImgUser = styled.img`
    width: 50px;
`;
const UserInfoBlock = styled.div`
    display: flex;
    align-items: center;
`;
const Log = styled.div<{ bg: string }>`
    width: 24px;
    height: 24px;
    background: url(${window.location.origin}${(props) => props.bg});
    cursor: pointer;
`;
const UserName = styled.div`
    font-family: Inter, serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    padding: 0 13px;
`;
const AuthLinksBlock = styled.div``;
const Links = styled(Link)`
    font-family: Inter, serif;
    font-size: 16px;
    font-weight: 400;
    color: black;
    text-decoration: none;
    width: 100%;
    cursor: pointer;
    padding: 19px 0;
    display: flex;
    align-items: center;
    line-height: 32px;
    text-align: center;
    @media (max-width: 600px) {
        font-size: 16px;
        padding: 19px 0;
    }
`;
const MobileMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0;
    border-bottom: #9fabd9 1px solid;
`;
const NavigationWrapper = styled.div`
    font-family: Inter, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
const AuthBlock = styled.div``;
const UserInfoLine = styled.div`
    display: flex;
    padding: 24px 0;
    align-items: center;
    justify-content: space-between;
`;
const LoginLinks = styled(NavigationWrapper)`
    padding-top: 30px;
    cursor: pointer;
`;
const BorderLine = styled.div`
    border-bottom: black 2px solid;
    width: 77px;
    padding-top: 8px;
`;
const WrapperMobileMenu = styled.div`
    max-width: 1360px;
    position: relative;
    padding: 0 19px;
    box-sizing: border-box;
`;
const HeaderMenuTablet = styled.div<{ height: string; width: string; display: string }>`
    top: 0;
    left: 0;
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    background-color: #ffffff;
    display: ${(props) => props.display};
    z-index: 2;
`;
export default MobileMenu;
