import {
    IChangePasswordData,
    IResponseMessage,
    IResponseFirstStep,
    IResponseSecondStep,
    IRequestSignUpPrivateUser,
    ITwoFactor,
    IUserData,
    IUserDataUpdate,
    IUserLogin,
    IRequestSignUpJuristicWholesalerUser,
    ISignUpResponse,
    IFirebaseSignUpResponse,
    IFirebaseLogin,
    IEmailVerification,
} from './userTypes';
import api from '../index';

export const userAPI = {
    async singUpPrivateUser(data: IRequestSignUpPrivateUser) {
        return (await api.post<ISignUpResponse>(`/auth/sign-up/private`, data)).data;
    },
    async signUpJuristicWholesaler(data: IRequestSignUpJuristicWholesalerUser) {
        return (await api.post<ISignUpResponse>(`/auth/sign-up`, data)).data;
    },
    async activateUser(id: string) {
        return (await api.get<IResponseMessage>(`/auth/activate/${id}`)).data;
    },
    async activatePhone() {
        return (await api.post<IResponseMessage>(`/auth/phone-verification/activate`)).data;
    },
    async phoneActivationSessionCreate() {
        return (await api.post<IResponseMessage>(`/auth/phone-verification/create`)).data;
    },
    async signInDev(data: IUserLogin) {
        return (await api.post<IResponseSecondStep>(`/auth/sign-in/dev`, data)).data;
    },
    async signInFirstStep(data: IUserLogin) {
        return (await api.post<IResponseFirstStep>(`/auth/sign-in`, data)).data;
    },
    async signInStepTwo(data: ITwoFactor) {
        return (await api.post<IResponseSecondStep>(`/auth/sign-in/two-factory`, data)).data;
    },
    async signInFirebase(data: IFirebaseLogin) {
        return (await api.post<IResponseSecondStep>(`/auth/sign-in/firebase`, data)).data;
    },
    async sendEmailVerification(data: IEmailVerification) {
        return (await api.post('/auth/send-verification', data)).data;
    },
    async registerFirebaseUser(data: IRequestSignUpPrivateUser) {
        return (await api.post(`/auth/sign-up/firebase`, data)).data;
    },
    async changeFirebasePassword() {
        return (await api.post(`/user/change-password`)).data;
    },
    async getCurrentFirebaseUser() {
        return (await api.post<IResponseSecondStep>(`/auth/firebase-user`)).data;
    },
    async getCurrentUser() {
        return (await api.get<IUserData>(`/auth/user`)).data;
    },
    async logoutUser() {
        return await api.delete(`auth/logout`);
    },
    async resetPasswordSessionCreate(email: string) {
        return (await api.post<IResponseMessage>(`/auth/reset-password/create`, { email })).data;
    },
    async resetPasswordActivate(id: string, password: string) {
        return (await api.post<IResponseMessage>(`/auth/reset-password/${id}/activate`, { password })).data;
    },
    async getCurrentStateResetSession(id: string) {
        return (await api.get<IResponseMessage>(`/auth/reset-password/${id}`)).data;
    },
    async updateUserData(data: IUserDataUpdate) {
        return (await api.patch<IUserData>(`/user`, data)).data;
    },
    async phoneChange(data: { phone: string }) {
        return (await api.post<IUserData>('/user/phone-change', data)).data;
    },
    async changePassword(data: IChangePasswordData) {
        return (await api.patch<IUserData>(`/user/change-password`, data)).data;
    },
    async changeEmailSessionCreate(email: string) {
        return (await api.post<IResponseMessage>(`/user/change-email/create`, { email })).data;
    },
    async changeEmailActivate(id: string) {
        return (await api.post<IResponseMessage>(`/user/change-email/${id}/activate`)).data;
    },
    async getChangeEmailStatus(id: string) {
        return (await api.get<IResponseMessage>(`/user/change-email/${id}`)).data;
    },
    async changePhoneSessionCreate(phone: string) {
        return (await api.post<IResponseMessage>(`/user/change-phone/create`, { phone })).data;
    },
    async changePhoneActivate(id: string) {
        return (await api.post<IResponseMessage>(`/user/change-phone/${id}/activate`)).data;
    },
    async getChangePhoneStatus(id: string) {
        return (await api.get<IResponseMessage>(`/user/change-phone/${id}`)).data;
    },
    async secondStepRegister(request: string, code: string) {
        return (await api.get<IResponseMessage>(`/auth/activate/${request}/${code}`)).data;
    },
    async getInTouch(data: { name: string; message: string; email: string; company?: string; phone: string }) {
        return (
            await api.post<IResponseMessage>(`/mail/get-in-touch`, {
                name: data.name,
                message: data.message,
                email: data.email,
                company: data.company,
                phone: data.phone,
            })
        ).data;
    },
};
