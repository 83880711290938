import { useClickAway } from '../../hooks/useClickAway';
import { useTypedSelector } from '../../hooks/useSelector';
import { LineBar } from '../../pages/charts';
import React, { useRef } from 'react';
import styled from 'styled-components';

const ChartsDropdown = ({ isOpen, setIsOpen }: any) => {
    const { currentMetalPrices } = useTypedSelector((state) => state.metalReducer);

    const refSelector = useRef(null);
    useClickAway(refSelector, () => {
        setIsOpen(false);
    });

    if (!isOpen) return null;
    return (
        <Container ref={refSelector}>
            {currentMetalPrices.map((m) => {
                return <LineBar key={m.id} metal={m} />;
            })}
        </Container>
    );
};

const Container = styled.div`
    position: absolute;
    top: 30px;
    right: -190px;
    box-shadow: 0px 3px 14px -2px #5b73cf3d;
    padding: 5px;
    background: white;
    width: 280px;
    z-index: 101;
    max-height: 600px;
    overflow: scroll;
    @media (max-width: 900px) {
        right: 5px;
    }
`;

export default ChartsDropdown;
