import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTypedSelector } from '../../hooks/useSelector';
import NotStonksIMG from '../../assets/svgs/price_notStonks_icon.svg';
import StonksIMG from '../../assets/svgs/price_stonks_icon.svg';
import Selector from '../..//ui/Selector';
import logIn from '../auth/LogIn';
import { useDispatch } from 'react-redux';
import { roundToTwoDecimals } from '../../helpers/round';

interface IGreyLine {
    statics?: any;
    selectedCurrency: string;
    setSelectedCurrency: any;
}

const currencyes = [
    {
        title: 'AUD',
        symbol: '$',
    },
    {
        title: 'USD',
        symbol: '$',
    },
    {
        title: 'EUR',
        symbol: '€',
    },
    {
        title: 'CNY',
        symbol: '¥',
    },
    {
        title: 'JPY',
        symbol: '¥',
    },
    {
        title: 'GBP',
        symbol: '£',
    },
    {
        title: 'RUB',
        symbol: '₽',
    },
];

const units = [
    {
        title: 'oz',
    },
    {
        title: 'gr',
    },
    {
        title: 'kg',
    },
];

function upperFirst(str: string) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
}

const GreyLineMainPage: React.FC<IGreyLine> = ({ statics, selectedCurrency, setSelectedCurrency }) => {
    const { currentMetalPrices, apiStatus } = useTypedSelector((state) => state.metalReducer);
    const [currentMoneyType, setCurrentMoneyType] = useState('$');
    const [selectedUnit, setSelectedUnit] = useState('oz');
    const dispatch = useDispatch();

    let metals = [] as any;
    if (currentMetalPrices && currentMetalPrices.length) {
        let obj = {
            silver: null,
            gold: null,
            platinum: null,
            palladium: null,
            copper: null,
        };
        //@ts-ignore
        currentMetalPrices.forEach((m) => (obj[m.name.toLowerCase()] = m));
        metals = Object.values(obj);
    }

    return (
        <Wrapper isWithPicture={!!statics?.Banner?.url}>
            <MetalPriceContent>
                {metals.map((m: any, index: any) => (
                    <MetalBlock key={index}>
                        <MetalTitle>{upperFirst(m.name)}</MetalTitle>
                        <PriceBlock>
                            <div>
                                {apiStatus ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        {m.priceChange === 'up' ? (
                                            <img src={StonksIMG} alt={'Stonks'} />
                                        ) : (
                                            <img src={NotStonksIMG} alt={'Not stonks'} />
                                        )}
                                        <Price>
                                            {m.price ? (
                                                <React.Fragment>
                                                    <span>
                                                        {(selectedCurrency === 'AUD' || selectedCurrency === 'USD') && currentMoneyType}

                                                        {`${
                                                            selectedUnit === 'gr'
                                                                ? roundToTwoDecimals(m.price / 31.12)
                                                                : selectedUnit === 'kg'
                                                                ? roundToTwoDecimals((m.price / 31.12) * 1000)
                                                                : roundToTwoDecimals(m.price)
                                                        }`}

                                                        {selectedCurrency !== 'AUD' && selectedCurrency !== 'USD' && currentMoneyType}
                                                    </span>
                                                    <PercentSpan color={m.priceChange === 'up' ? 'green' : 'red'}>
                                                        ({m.priceChange === 'up' ? '+' : '-'}
                                                        {roundToTwoDecimals(m.percent)}%)
                                                    </PercentSpan>
                                                </React.Fragment>
                                            ) : (
                                                '-'
                                            )}
                                        </Price>
                                    </div>
                                ) : (
                                    <OffApiStatus>&mdash;</OffApiStatus>
                                )}
                            </div>
                            {currentMetalPrices.length - 1 !== index && <Separator />}
                        </PriceBlock>
                    </MetalBlock>
                ))}
                <SelectorWrapper>
                    <Selector
                        isFullWidth={true}
                        selected={selectedCurrency}
                        mobileShort={false}
                        setValue={(value) => {
                            if (value) {
                                setSelectedCurrency(value.title);
                                //@ts-ignore
                                setCurrentMoneyType(value.symbol);
                            }
                        }}
                        list={currencyes}
                    />
                </SelectorWrapper>

                <UnitSelectorWrapper>
                    <Selector
                        isFullWidth={true}
                        selected={selectedUnit}
                        mobileShort={false}
                        setValue={(value) => {
                            if (value) {
                                setSelectedUnit(value.title);
                            }
                        }}
                        list={units}
                    />
                </UnitSelectorWrapper>
            </MetalPriceContent>
        </Wrapper>
    );
};

const OffApiStatus = styled.div`
    width: 45px;
    display: flex;
    justify-content: center;
`;

const Separator = styled.div`
    border-right: 2px solid #b0b0b0;
    position: relative;
    left: 30px;
    @media (max-width: 768px) {
        display: none;
    }
`;

const Price = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;

const PriceBlock = styled.div`
    display: flex;
    flex-direction: row;
`;

const MetalTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`;

const MetalBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 180px;
    @media (max-width: 470px) {
        width: 165px;
    }
`;

const MetalPriceContent = styled.div`
    //background: #F5F5F5;
    box-sizing: border-box;
    /*  height: 80px;*/
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 40px;
    padding-left: 40px;
    @media (max-width: 1100px) {
        padding-right: 40px;
        padding-left: 20px;
    }
    @media (max-width: 650px) {
        position: relative;
        padding-right: 0;
        display: grid;
        gap: 7.5px 0px;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 135px;
        margin: 20px 0;
    }
`;

interface IWrapper {
    isWithPicture: boolean;
}

const Wrapper = styled.div<IWrapper>`
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f5f5f5;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    @media (max-width: 1100px) {
        justify-content: start;
    }
    @media (max-width: 650px) {
        padding-top: 0;
        padding-bottom: 0;
        position: ${(props) => (props.isWithPicture ? 'absolute' : 'relative')};
        max-width: 350px;
        max-height: 380px;
        /*    top: 300px;
        left: 10vw;*/
        top: 50%;
        left: 50%;
        transform: ${(props) => (props.isWithPicture ? `translate(-50%, -50%)` : `translate(-50%)`)};
    }
    /*  @media (max-width: 450px) {
      left: 3%;
    }*/
`;
const MetalName = styled.div``;
const MetalPrice = styled.div``;
const Arrow = styled.div``;

const SelectorWrapper = styled.div`
    margin-top: 15px;
    padding-right: 45px;
    width: 120px;
    @media (max-width: 650px) {
        padding-right: 0;
        width: 83%;
        position: absolute;
        top: 170px;
    }
`;

const UnitSelectorWrapper = styled.div`
    margin-top: 15px;
    width: 70px;
    @media (max-width: 1240px) {
        margin-left: 0;
    }
    @media (max-width: 650px) {
        margin-left: 0;
        width: 83%;
        position: absolute;
        top: 220px;
    }
`;

const PercentSpan = styled.span<{ color: string }>`
    color: ${(props) => props.color};
    font-size: 12px;
    margin-left: 5px;
`;

export default GreyLineMainPage;
