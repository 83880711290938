import { IErrors } from '../../api/user/userTypes';
import { ICart } from '../../api/cart/cartTypes';
import { cartActions } from '../../store/types/cartTypes';
import { CartActionTypes } from '../../store/constants/cartConstants';

interface ICartState {
    error: IErrors[] | undefined;
    loading: boolean;
    cart: ICart | undefined;
}

const initialState: ICartState = {
    error: undefined,
    loading: false,
    cart: undefined,
};

const startLoading = (state: ICartState) => {
    return { ...state, loading: true, error: undefined };
};

const stopLoading = (state: ICartState) => {
    return { ...state, loading: false, error: undefined };
};

const stopErrorLoading = (state: ICartState, error: IErrors[]) => {
    return { ...state, loading: false, error: error };
};

const cartReducer = (state = initialState, action: cartActions): ICartState => {
    switch (action.type) {
        case CartActionTypes.START_CART_ACTION: {
            return startLoading(state);
        }
        case CartActionTypes.END_CART_ACTION: {
            return stopLoading(state);
        }
        case CartActionTypes.ERROR_CART_ACTION: {
            return stopErrorLoading(state, action.payload);
        }

        case CartActionTypes.GET_CART:
        case CartActionTypes.UPDATE_QUANTITY:
        case CartActionTypes.DELETE_PRODUCT_FROM_CART: {
            if (!action.payload) {
                return {
                    ...state,
                    cart: undefined,
                    loading: false,
                };
            }
            return {
                ...state,
                cart: action.payload,
                loading: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default cartReducer;
