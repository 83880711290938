import styled from "styled-components"

export const    Container = styled.div`
  margin: 0 auto;
  position: relative;
  max-width: 1290px;
  box-sizing: border-box;
`
export const ContainerFluid = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
`
