import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import FirstBannerMainPage from '../components/mainPage/FirstBannerMainPage';
import LivePriceList from '../components/mainPage/LivePriceList';
import WeHereToHelpComponent from '../components/mainPage/WeHereToHelpComponent';
import styled from 'styled-components';
import axios from 'axios';
import { getMetalPrices } from '../store/actions/metalsActions';
import useSocketDispatchUpdatePrice from '../hooks/useSocketDispatch';
import { getLiveInfo } from '../store/actions/shopActions';
import { useTypedSelector } from '../hooks/useSelector';
import config from '../config';
import { useDispatch } from 'react-redux';
import MainPageTitle from '../components/mainPage/MainPageTitle';
import { Helmet } from 'react-helmet';

interface IField {
    url?: string;
    data?: string;
    type: string;
}

interface IDtoStatic {
    [key: string]: IField;
}

const MainPage = () => {
    const { currentCurrency } = useTypedSelector((state) => state.metalReducer);
    const [staticContent, setStaticContent] = useState<IDtoStatic[]>([]);
    const dispatch = useSocketDispatchUpdatePrice();
    const appDispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get(`${config.REACT_APP_API_BASE_URL || ''}/page/HomePage`)
            .then((response) => {
                setStaticContent(response.data.content);
            })
            .finally(() => {
                setLoading(false);
            });
        dispatch({
            first: () => getMetalPrices(currentCurrency),
        });
    }, [currentCurrency]);

    useEffect(() => {
        dispatch({
            first: () => getLiveInfo(currentCurrency),
        });
    }, [currentCurrency]);

    useEffect(() => {
        let requestInterval = setInterval(() => {
            appDispatch(getLiveInfo(currentCurrency));
        }, 10 * 1000);
        return () => {
            clearInterval(requestInterval);
        };
    }, [currentCurrency, dispatch]);

    useEffect(() => {
        document.title = 'Live Price List | Bullion Network Melbourne';
    }, []);

    return (
        <Layout>
            <Helmet>
                <meta property="og:site_name" content="Bullion Network" />
                <meta property="og:title" content="Bullion Network Melbourne" />
                <meta
                    property="og:description"
                    content="Custom software website developed for live spot price of silver gold bullion &and world coins. Melbourne, Australia"
                />
                {/* <meta
                    property="og:description"
                    content="Buy & Sell Gold, Silver Bullion, browse our shop for rare coins collectibles. Based in Melbourne"
                /> */}
                <meta
                    property="description"
                    content="Custom software website developed for live spot price of silver gold bullion &and world coins. Melbourne, Australia"
                />
                {/* <meta
                    property="description"
                    content="Buy & Sell Gold, Silver Bullion, browse our shop for rare coins collectibles. Based in Melbourne"
                /> */}
            </Helmet>
            <MainPageTitle statics={staticContent} />
            <LPLContainer>
                <LivePriceList />
            </LPLContainer>
            <MyContainer>
                <WeHereToHelpComponent statics={staticContent} />
            </MyContainer>
            {/* <FirstBannerMainPage statics={staticContent} isLoading={loading} /> */}
        </Layout>
    );
};
const MyContainer = styled.div`
    margin-top: 20px;
    position: relative;
    z-index: -1;
    margin-bottom: 100px;
`;
const LPLContainer = styled.div`
    /* margin-bottom: 200px; */
`;
export default MainPage;
