import { auth } from '../services/firebase-service';
import { ApplicationVerifier, RecaptchaVerifier } from 'firebase/auth';
import { useEffect, useState } from 'react';

export function useRecaptcha(componentId: string) {
    const [recaptcha, setRecaptcha] = useState<ApplicationVerifier>();
    const element = document.getElementById(componentId);

    useEffect(() => {
        if (!recaptcha) {
            const recaptchaVerifier = new RecaptchaVerifier(
                componentId,
                {
                    size: 'invisible',
                    callback: () => {},
                },
                auth
            );

            setRecaptcha(recaptchaVerifier);

            return () => {
                recaptchaVerifier.clear();
            };
        }
    }, [componentId]);

    return recaptcha;
}
