import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Arrow from '../../../assets/svgs/Down.svg'


interface IButtonProps {
    title: string
    index: number
    onClick: () => void
    isFirst?: boolean
    isLast?: boolean
}

const ButtonCategories: React.FC<IButtonProps> = ({index, title, onClick, isFirst, isLast}) => {
    const [position, setPosition] = useState(true)
    useEffect(() => {
        if (index !== 0) {
            setPosition(true)
        } else {
            setPosition(false)
        }
    }, [])

    return (
        <Button
            onClick={() => {
                onClick()
            }}
            isFirst={isFirst}
            isLast={isLast}
            position={position}
        >
            <Text>
                {title}
            </Text>
            <RightArrow> <Images src={Arrow} alt='arrow'/> </RightArrow>
        </Button>

    );
};
const Text = styled.div`
  font-family: Inter, serif;
  font-size: 16px;
  color: #0B1126;
  margin: 20px 0 20px 16px;
  font-weight: 400;
  line-height: 19px;

`

const Images = styled.img`
  height: 9px;
  width: 16px;
  user-select: none;
`
const RightArrow = styled.div`
  position: relative;
  transform: rotate(90deg);
  margin-right: 30px;

`

interface IButtonParams {
    isFirst?: boolean
    isLast?: boolean
    position?: boolean
}

const Button = styled.div<IButtonParams>`
  width: 300px;
  height: 60px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  border-top: ${props => props.position ? "1px" : "0"} solid #DBD5CC;
  transition: background-color .2s;
  cursor: pointer;
  border-radius: ${props => props.isFirst? "6px 6px 0px 0px": props.isLast? "0px 0px 6px 6px": "0"} ;

  &:hover {
    background-color: #F8DCB3;
  }

  &:active {
    background-color: #F8C882;
  }
`
export default ButtonCategories;
