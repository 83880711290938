import React, { useEffect, useState } from 'react';
import Header from '../components/header/index';
import { ContainerFluid } from '../ui/container';
import Footer from '../components/footer/index';
import MobileMenu from '../components/mobileMenu/index';
import styled from 'styled-components';
import GreyLineMainPage from './mainPage/GreyLineMainPage';
import { getMetalPrices } from '../store/actions/metalsActions';
import useSocketDispatchUpdatePrice from '..//hooks/useSocketDispatch';
// import { useTypedSelector } from "../hooks/useSelector";

type LayoutProps = {
    children: React.ReactNode;
    isInShop?: boolean;
};
const Layout = ({ children, isInShop = false }: LayoutProps) => {
    //const {currentCurrency} = useTypedSelector(state => state.metalReducer)
    const dispatch = useSocketDispatchUpdatePrice();
    const [open, setOpen] = useState(false);
    const [openModalSignIn, setOpenModalSignIn] = useState(false);
    const [openModalLogIn, setOpenModalLogIn] = useState(false);
    const [show, setShow] = useState({
        height: '0',
        display: 'none',
        width: '0',
    });

    const [selectorValue, setSelectorValue] = useState('AUD');

    const anchorLink = async (id: string) => {
        await setOpen(false);
    };
    useEffect(() => {
        if (open) {
            setShow({ height: 'calc(88vh)', display: 'block', width: '100%' });
        } else {
            setShow({ display: 'none', height: '0', width: '0' });
        }
    }, [open]);

    useEffect(() => {
        dispatch({
            first: () => getMetalPrices(selectorValue),
        });
    }, [selectorValue]);

    useEffect(() => {
        let requestInterval = setInterval(() => {
            dispatch({
                first: () => getMetalPrices(selectorValue),
            });
        }, 10 * 1000);
        return () => {
            clearInterval(requestInterval);
        };
    }, [dispatch]);

    return (
        <MainContainer>
            <ContainerFluid>
                <Header
                    openModalSignIn={openModalSignIn}
                    openModalLogIn={openModalLogIn}
                    setOpenModalSignIn={setOpenModalSignIn}
                    setOpenModalLogIn={setOpenModalLogIn}
                    open={open}
                    setOpen={setOpen}
                    show={show}
                    setShow={setShow}
                />
            </ContainerFluid>

            <GreyLineMainPage selectedCurrency={selectorValue} setSelectedCurrency={setSelectorValue} />

            {open && window.innerWidth <= 600 ? (
                <main>
                    <MobileMenuWrapper>
                        <MobileMenu
                            anchorLink={anchorLink}
                            setOpenModalSignIn={setOpenModalSignIn}
                            setOpenModalLogIn={setOpenModalLogIn}
                            display={show.display}
                            height={show.height}
                            width={show.width}
                            setOpen={setOpen}
                        />
                    </MobileMenuWrapper>
                </main>
            ) : (
                <main>{children}</main>
            )}
            <div id="sign-in"></div>

            <ContainerFluid>
                <Footer open={open} />
            </ContainerFluid>
        </MainContainer>
    );
};

const MainContainer = styled.div``;

const MobileMenuWrapper = styled.div``;
export default Layout;
