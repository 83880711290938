import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Container } from '../ui/container';
import styled from 'styled-components';
import ContactUsComponent from '../components/contactUs';
import axios from 'axios';
import config from '../config';
import { Helmet } from 'react-helmet';

interface IField {
    url?: string;
    data?: string;
    type: string;
}

interface IDtoStatic {
    [key: string]: IField;
}

const ContactUs = () => {
    const [staticContent, setStaticContent] = useState<IDtoStatic[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = 'Contact Us | Bullion Network Melbourne';
        axios
            .get(`${config.REACT_APP_API_BASE_URL || ''}/page/Contact%20us`)
            .then((response) => {
                setStaticContent(response.data.content);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <Layout>
            <Helmet>
                <meta property="og:site_name" content="Bullion Network" />
                <meta property="og:title" content=" Contact Us | Bullion Network Melbourne" />
                <meta property="og:description" content="Bullion Network Melbourne | Speak to us" />
                <meta property="description" content="Bullion Network Melbourne | Speak to us" />
            </Helmet>
            <Container>
                <Component>
                    <ContactUsComponent statics={staticContent} isLoading={loading} />
                </Component>
            </Container>
        </Layout>
    );
};
const Component = styled.div`
    margin-top: 40px;
    margin-bottom: 300px;
    @media (max-width: 900px) {
        margin-top: 36px;
        margin-bottom: 100px;
    }
`;
export default ContactUs;
