import React, { useEffect, useState } from 'react';
import { useTypedSelector } from './hooks/useSelector';
import Router from './routes';
import SnakeBar from './ui/components/modals/SnakeBar';
import { AuthProvider } from './services/firebase-service';
import { useDispatch } from 'react-redux';
import { getUser, logout } from './store/actions/userActions';
import { UserActionTypes } from './store/constants/userConstants';
import { useNavigate } from 'react-router-dom';

const ReduxAuthProvider = ({ children }: { children: any }) => {
    const dispatch = useDispatch();
    const setIsVerified = (verified: { phone: boolean; mail: boolean }) => {
        if (verified.mail === true && verified.phone === true) {
            return {
                type: UserActionTypes.SET_USER_VERIFIED,
            };
        } else {
            return {
                type: UserActionTypes.SET_USER_UNVERIFIED,
                payload: { ...verified },
            };
        }
    };
    const _setIsVerified = (value: { phone: boolean; mail: boolean }) => dispatch(setIsVerified(value));
    return (
        <AuthProvider onLogin={() => dispatch(getUser())} onLogout={() => dispatch(logout())} setIsVerified={_setIsVerified}>
            {children}
        </AuthProvider>
    );
};

const App = () => {
    const { type, content, ms, queue } = useTypedSelector((state) => state.snackbarReducer);
    // const { isVerified } = useTypedSelector((state) => state.userReducer);
    const navigate = useNavigate();

    // if (isVerified === false) {
    //     navigate('/verification');
    // }

    return (
        <ReduxAuthProvider>
            <React.Fragment>
                <SnakeBar type={type} content={content} timeMS={ms} queue={queue} />
                <Router />
            </React.Fragment>
        </ReduxAuthProvider>
    );
};

export default App;
