import React, {useEffect, useState} from 'react';
import Layout from '../components/Layout';
import {Container} from '../ui/container';
import styled from "styled-components";
import RegistrationSuccessIMG from '../assets/svgs/activate_handshake_img.svg'
import StyledButton from "../ui/StyledButton";
import Modal from "../ui/components/modals/Modal";
import LogIn from "../components/auth/LogIn";
import {useTypedSelector} from "../hooks/useSelector";
import {useNavigate} from "react-router-dom";
import {useUserActions} from "../hooks/useActions";

const Activate = () => {
    const navigate = useNavigate()

    const {user} = useTypedSelector(state => state.userReducer)
    const {clearMessage, clearUserError} = useUserActions()

    const [showLogModal, setShowLogModal] = useState(false)

    const logInHandler = () => {
        setShowLogModal(true)
    }

    useEffect(() => {
      if (user){
          navigate('/')
      }
    }, [user]);

    useEffect(() => {
      return () => {
          clearMessage()
          clearUserError()
      }
    }, []);

    return (
        <Layout>
            <Modal show={showLogModal} setShow={setShowLogModal} ModalElement={LogIn}/>
            <Container>
                <Content>
                    <Tittle> Successful!</Tittle>
                    <MainInfo>
                        <ImgContainer src={RegistrationSuccessIMG} alt={"registrationSuccess"}/>
                        <Description>
                            The account has been successfully registered!
                        </Description>
                        <ButtonContainer>
                            <StyledButton onClick={logInHandler} height={"50px"}>
                                Log In
                            </StyledButton>
                        </ButtonContainer>
                    </MainInfo>
                </Content>
            </Container>
        </Layout>
    );
};

const Content = styled.div`
  padding-top: 50px;
  height: 900px
`

const Tittle = styled.div`
  /* font-family: 'Playfair Display'; */
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 53px;
  text-transform: capitalize;
  padding-bottom: 140px;
`

const MainInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  width: 250px;
  padding-top: 40px;
`

const ImgContainer = styled.img`
  @media (max-width: 768px) {
    height: 278px;
    width: 278px;
  }
`

const Description = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  display: flex;
  align-items: center;
  text-align: center;
`

export default Activate;
