import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SettingsMenu = () => {
    const Menu = [
        {
            title: 'Profile',
            link: '/profile',
        },

        {
            title: 'Orders',
            link: '/orders',
        },
    ];

    return (
        <Wrapper>
            {Menu.map((item, index) => {
                return !(window.location.href === window.location.origin + item.link) ? (
                    <Links key={`links-index-${index}`} to={`${item.link}`}>
                        {item.title}{' '}
                    </Links>
                ) : (
                    <Links to={`${item.link}`} style={{ color: '#5B73CF' }} key={`links-index-${index}`}>
                        {item.title}{' '}
                    </Links>
                );
            })}
        </Wrapper>
    );
};

const Links = styled(Link)`
    font-family: Inter, serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
    color: #0b1126;
    cursor: pointer;
    margin-bottom: 28px;
    &:hover {
        color: #5b73cf;
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 102px;
    margin-top: 20px;
    @media (max-width: 600px) {
        display: none;
    }
`;
export default SettingsMenu;
