export default {
    REACT_APP_API_BASE_URL: (() => {
        if (process.env.REACT_APP_ENV === 'local') {
            return 'http://localhost:5001/api';
        }
        if (process.env.REACT_APP_ENV === 'dev') {
            return 'https://dev.api.bullionnetwork.com.au';
        }
        if (process.env.REACT_APP_ENV === 'qa') {
            return 'https://qa.api.bullionnetwork.com.au/';
        }
        if (process.env.REACT_APP_ENV === 'uat') {
            return 'https://uat.api.bullionnetwork.com.au/';
        }
        if (process.env.REACT_APP_ENV === 'prod') {
            return 'https://prod.api.bullionnetwork.com.au/';
        }
    })(),
    REACT_APP_ADMIN_URL: (() => {
        if (process.env.REACT_APP_ENV === 'local') {
            return 'http://localhost:3001';
        }
        if (process.env.REACT_APP_ENV === 'dev') {
            return 'https://dev.admin.bullionnetwork.com.au/';
        }
        if (process.env.REACT_APP_ENV === 'qa') {
            return 'https://qa.admin.bullionnetwork.com.au/';
        }
        if (process.env.REACT_APP_ENV === 'uat') {
            return 'https://uat.admin.bullionnetwork.com.au/';
        }
        if (process.env.REACT_APP_ENV === 'prod') {
            return 'https://admin.bullionnetwork.com.au/';
        }
    })(),
    // REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY: "6Lc77XkiAAAAAHIybp6PSwMQPrfnQKk2L4sXhp4r",
    REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY: '6LeQdf8kAAAAAGA09jE7GhWgA2J2bLuuLK8bHIsm',
    REACT_APP_MAPBOX_TOKEN: 'k.eyJ1Ijoia2VtdXJvMTIiLCJhIjoiY2tvMDN1YW81MDc2ejJ1bm53ZTY2bGdrMyJ9.DJ0_bSsdjgDpaPeQ3kC6qw',
    REACT_APP_WEBSOCKET: 'wss://api.new.bullionnetwork.com.au',
    FIREBASE_CONFIG: (() => {
        if (process.env.REACT_APP_ENV === 'local') {
            return {
                apiKey: 'AIzaSyCu7jfaCRTLvspkPmLt1hOBfQ6MXskmfPo',
                authDomain: 'bullion-dev.firebaseapp.com',
                projectId: 'bullion-dev',
                storageBucket: 'bullion-dev.appspot.com',
                messagingSenderId: '810291424109',
                appId: '1:810291424109:web:2613474fc114551af74864',
                measurementId: 'G-2GJ0H4WGP8',
            };
        }
        if (process.env.REACT_APP_ENV === 'dev') {
            return {
                apiKey: 'AIzaSyCu7jfaCRTLvspkPmLt1hOBfQ6MXskmfPo',
                authDomain: 'bullion-dev.firebaseapp.com',
                projectId: 'bullion-dev',
                storageBucket: 'bullion-dev.appspot.com',
                messagingSenderId: '810291424109',
                appId: '1:810291424109:web:2613474fc114551af74864',
                measurementId: 'G-2GJ0H4WGP8',
            };
        }
        if (process.env.REACT_APP_ENV === 'qa') {
            return {
                apiKey: 'AIzaSyDjyzNIEY4pSSQur_Vb01Yu5w9z--sCbv4',
                authDomain: 'bullion-qa.firebaseapp.com',
                projectId: 'bullion-qa',
                storageBucket: 'bullion-qa.appspot.com',
                messagingSenderId: '629438855014',
                appId: '1:629438855014:web:270159510014690ce03aa7',
                measurementId: 'G-XFVM25TXP8',
            };
        }
        if (process.env.REACT_APP_ENV === 'uat') {
            return {
                apiKey: 'AIzaSyAa34MbpDScZdgIHkhUcCXCBjzljoZQzJ4',
                authDomain: 'bullion-uat-a1341.firebaseapp.com',
                projectId: 'bullion-uat-a1341',
                storageBucket: 'bullion-uat-a1341.appspot.com',
                messagingSenderId: '783437651697',
                appId: '1:783437651697:web:03b985203111f3ca4d1350',
            };
        }
        if (process.env.REACT_APP_ENV === 'prod') {
            return {
                apiKey: 'AIzaSyD5wTWPBU7GiYS6uHuCTUaseOoMLhclZ5s',
                authDomain: 'bullion-prod.firebaseapp.com',
                projectId: 'bullion-prod',
                storageBucket: 'bullion-prod.appspot.com',
                messagingSenderId: '10250076029',
                appId: '1:10250076029:web:7866cd575b367279af1614',
                measurementId: 'G-KZ4KT6HQWE',
            };
        }
        return {
            apiKey: 'AIzaSyCu7jfaCRTLvspkPmLt1hOBfQ6MXskmfPo',
            authDomain: 'bullion-dev.firebaseapp.com',
            projectId: 'bullion-dev',
            storageBucket: 'bullion-dev.appspot.com',
            messagingSenderId: '810291424109',
            appId: '1:810291424109:web:2613474fc114551af74864',
            measurementId: 'G-2GJ0H4WGP8',
        };
    })(),
};
