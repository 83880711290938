import Layout from '../components/Layout';
import { Container } from '../ui/container';
import React, { useEffect, useState } from 'react';
import { Heading1 } from '../ui/HeadingTags';
import SettingsMenu from '../components/settings/Menu';
import StyledButton from '../ui/StyledButton';
import styled from 'styled-components';
import { useTypedSelector } from '../hooks/useSelector';
import OrderList from '../components/orders/OrderList';
import { useOrderActions } from '../hooks/useActions';
import Paginator from '../ui/components/paginator';
import OrderPagePlaceholder from '../assets/svgs/orders_empty_image.svg';
import config from '../config';

const linkAdmin = config.REACT_APP_ADMIN_URL;
const Orders = () => {
    const { user } = useTypedSelector((state) => state.userReducer);
    const { myOrders, total_items, page_limit } = useTypedSelector((state) => state.orderReducer);

    const { getMyOrders } = useOrderActions();

    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);

    useEffect(() => {
        getMyOrders({
            page,
            pageLimit,
        });
    }, [page, pageLimit]);

    return (
        <Layout>
            <Container>
                <Content>
                    <HeadTitle>Orders</HeadTitle>
                    <Wrapper>
                        <SettingsMenu />
                        {!(user?.role === 'admin' || user?.role === 'super-admin') ? (
                            <FieldDiv>
                                {myOrders.length === 0 ? (
                                    <EmptyOrderInfo>
                                        <EmptyImage src={OrderPagePlaceholder} alt="Empty" />
                                        <TextNearEmpty>
                                            Your orders will be displayed here. Use the search or go to the Shop to buy products and place an order
                                        </TextNearEmpty>
                                    </EmptyOrderInfo>
                                ) : (
                                    <div>
                                        <OrderList orders={myOrders} />
                                        <Paginator max={Math.floor(total_items / page_limit) + 1} page={page} setPage={setPage} />
                                    </div>
                                )}
                            </FieldDiv>
                        ) : (
                            <GoToAdminPanelBlock>
                                <a href={linkAdmin}>
                                    <StyledButton height={'50px'}>OPEN ADMIN PAGE</StyledButton>
                                </a>
                            </GoToAdminPanelBlock>
                        )}
                    </Wrapper>
                </Content>
            </Container>
        </Layout>
    );
};

const EmptyImage = styled.img`
    width: 400px;
    @media (max-width: 1080px) {
        width: 300px;
    }
    @media (max-width: 600px) {
        width: 220px;
    }
`;

const TextNearEmpty = styled.div`
    width: 422px;
    @media (max-width: 1080px) {
        width: 300px;
        font-size: 16px;
    }
`;

const EmptyOrderInfo = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;

    text-align: center;

    color: #0b1126;
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    min-height: 80vh;
`;
const Content = styled.div`
    margin-top: 60px;
    @media (max-width: 1290px) {
        padding: 0 16px;
    }
    @media (max-width: 600px) {
        padding: 0 11px;
    }
`;
const HeadTitle = styled(Heading1)`
    font-weight: 900;
`;
const FieldDiv = styled.div`
    margin-top: 25px;
    margin-bottom: 100px;
    width: 100%;
    margin-left: 145px;
    @media (max-width: 900px) {
        margin-left: 100px;
    }
    @media (max-width: 600px) {
        margin-left: 0;
    }
`;

const GoToAdminPanelBlock = styled.div`
    height: 350px;
    width: 200px;
    margin: 0 auto auto auto;
`;

export default Orders;
