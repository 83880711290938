import FirebaseService from '../services/firebase-service';
import Loading from '../components/loading';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../store/actions/snackbarActions';
import { SnakeTypes } from '../ui/components/modals/SnakeBar';
import { useTypedSelector } from '../hooks/useSelector';
import { setResponseMessage } from '../store/actions/userActions';

const AccountActions = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useTypedSelector((state) => state.userReducer);

    useEffect(() => {
        try {
            const location = window.location.search.split('?mode=')[1].split('&')[0] as string;
            if (location === 'resetPassword') {
                navigate(`/reset-password${window.location.search}`);
            } else if (location === 'verifyEmail') {
                const oobCode = window.location.search.split('&')[1].split('oobCode=')[1];
                if (oobCode) {
                    (async () => {
                        const response = await FirebaseService.auth.email.verifyEmail(oobCode);

                        if (response === true) {
                            dispatch(showSnackbar({ type: SnakeTypes.success, content: 'Email verified! You can now login', ms: 4000 }));
                            // setTimeout(() => (window.location.href = '/'), 3000);
                            if (user) {
                                window.location.href = '/';
                            } else {
                                dispatch(setResponseMessage({ message: 'open_sign_in_modal' }));
                                navigate('/');
                            }
                        } else {
                            if (response === 'invalidCode') {
                                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Verification link is invalid or has expired', ms: 4000 }));
                            } else if (response === 'userDisabled') {
                                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'User was disabled', ms: 4000 }));
                            } else if (response === 'userNotFound') {
                                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'User cannot be found', ms: 4000 }));
                            } else {
                                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Error during email verification!', ms: 4000 }));
                            }
                            navigate('/');
                        }
                    })();
                }
            } else {
                navigate('/');
            }
        } catch (error) {
            navigate('/');
        }
    }, []);

    return <Loading />;
};

export default AccountActions;
