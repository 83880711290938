import { IErrors } from '../../api/user/userTypes';
import { IMyOrdersData, IOrder, IResponseOrderData } from '../../api/order/orderTypes';
import { orderActions } from '../../store/types/orderTypes';
import { OrderActionTypes } from '../../store/constants/orderConstants';

interface IOrderState {
    error: IErrors[] | undefined;
    loading: boolean;
    myOrders: IOrder[];
    total_items: number;
    page_limit: number;
    orderInfo: IResponseOrderData | undefined;
}

const initialState: IOrderState = {
    error: undefined,
    loading: false,
    myOrders: [],
    orderInfo: undefined,
    total_items: -1,
    page_limit: -1,
};

const startLoading = (state: IOrderState) => {
    return { ...state, loading: true, error: undefined };
};

const stopLoading = (state: IOrderState) => {
    return { ...state, loading: false, error: undefined };
};

const stopErrorLoading = (state: IOrderState, error: IErrors[]) => {
    return { ...state, loading: false, error: error };
};

const orderReducer = (state = initialState, action: orderActions): IOrderState => {
    switch (action.type) {
        case OrderActionTypes.START_ORDER_ACTION: {
            return startLoading(state);
        }
        case OrderActionTypes.ERROR_ORDER_ACTION: {
            return stopErrorLoading(state, action.payload);
        }

        case OrderActionTypes.STOP_ORDER_ACTION: {
            return stopLoading(state);
        }

        case OrderActionTypes.CLEAR_MY_ORDER_INFO: {
            return {
                ...state,
                orderInfo: undefined,
            };
        }

        case OrderActionTypes.CREATE_ORDER:
            return {
                ...state,
                orderInfo: action.payload,
            };

        case OrderActionTypes.GET_MY_ORDERS:
            return {
                ...state,
                myOrders: action.payload.data,
                page_limit: action.payload.page_limit,
                total_items: action.payload.total_items,
            };

        default: {
            return state;
        }
    }
};

export default orderReducer;
