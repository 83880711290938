export enum UserActionTypes {
    INITIALIZED = 'INITIALIZED',

    START_USER_ACTION = 'START_USER_ACTION',
    ERROR_USER_ACTION = 'ERROR_USER_ACTION',
    FINISH_USER_ACTION = 'FINISH_USER_ACTION',

    CLEAR_STATE_USER = 'CLEAR_STATE_USER',
    CLEAR_USER_ERROR = 'CLEAR_USER_ERROR',
    CLEAR_USER_MESSAGE = 'CLEAR_USER_MESSAGE',
    CLEAR_USER_REQUEST = 'CLEAR_USER_REQUEST',

    REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
    GET_RESPONSE_MESSAGE = 'GET_RESPONSE_MESSAGE',

    LOGIN_FIRST_STEP_SUCCESS = 'LOGIN_FIRST_STEP_SUCCESS',
    LOGIN_SECOND_STEP_SUCCESS = 'LOGIN_SECOND_STEP_SUCCESS',
    LOGIN_SECOND_STEP_FAIL = 'LOGIN_SECOND_STEP_FAIL',

    SET_USER_VERIFIED = 'SET_USER_VERIFIED',
    SET_USER_UNVERIFIED = 'SET_USER_UNVERIFIED',

    GET_IN_TOUCH = 'GET_IN_TOUCH',
    SUBSCRIBE_TO_NEWSLETTER = 'SUBSCRIBE_TO_NEWSLETTER',
    UNSUBSCRIBE_FROM_NEWSLETTER = 'UNSUBSCRIBE_FROM_NEWSLETTER',
}
