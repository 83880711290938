import React, { KeyboardEventHandler, useState } from 'react';
import styled from 'styled-components';

type TextFieldsProps = {
    onClick?: () => void;
    padding?: string;
    placeholder?: string;
    type: string;
    textError?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    height: string;
    required?: boolean;
};

const TextFields = (props: TextFieldsProps) => {
    const [req, isReq] = useState(false);
    return (
        <Wrapper>
            {props.textError === '' || props.textError === undefined ? (
                <Input
                    onKeyDown={(e) => {
                        if (props.onKeyDown) {
                            props.onKeyDown(e);
                        }
                    }}
                    id={props.placeholder}
                    disabled={props.disabled}
                    height={props.height}
                    style={{ padding: props.padding }}
                    placeholder={''}
                    type={props.type}
                    value={props.value}
                    onClick={props.onClick}
                    required={req === props.required}
                    aria-invalid={true}
                    onBlur={(e) => {
                        isReq(e.target.value.length === 0);
                    }}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                />
            ) : (
                <Input
                    id={props.placeholder}
                    disabled={props.disabled}
                    height={props.height}
                    style={{ color: '#D92E23', borderColor: '#D92E23', padding: props.padding }}
                    placeholder={''}
                    type={props.type}
                    value={props.value}
                    onClick={props.onClick}
                    required={(props.value?.length === 0) === props.required}
                    aria-invalid={true}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                />
            )}

            {props.textError === '' || !props.textError ? (
                !(props.value?.length === 0) ? (
                    <Input_active height={props.height} htmlFor={props.placeholder}>
                        {' '}
                        {props.placeholder}{' '}
                    </Input_active>
                ) : (
                    <Label height={props.height} htmlFor={props.placeholder}>
                        {props.placeholder}
                    </Label>
                )
            ) : !(props.value?.length === 0) ? (
                <Input_active style={{ color: '#D92E23' }} height={props.height} htmlFor={props.placeholder}>
                    {' '}
                    {props.placeholder}{' '}
                </Input_active>
            ) : (
                <Label style={{ color: '#D92E23' }} height={props.height} htmlFor={props.placeholder}>
                    {props.placeholder}
                </Label>
            )}

            <div style={{ color: '#D92E23', paddingTop: 3, paddingLeft: 10, fontSize: 14, position: 'absolute' }}> {props.textError}</div>
        </Wrapper>
    );
};
export const Input = styled.input<{ height: string }>`
    font-family: Inter, serif;
    height: ${(props) => props.height};
    width: 100%;
    font-weight: 400;
    border-radius: 4px;
    cursor: text;
    transition: border 0.15s ease-in-out 0s, color 0.15s ease-in-out 0s;
    border: #b6b6b6 solid 1px;
    position: relative;
    line-height: 16px;
    font-size: 16px;
    padding-left: 15px;
    box-sizing: border-box;

    &:focus-visible {
        outline: none;
        border: #394985 solid 1px;
        color: #394985;
    }

    &:focus-within + label {
        transform: scale(0.75) translateY(calc(${(props) => props.height} / -1 + 0.3 * ${(props) => props.height}));
        color: #394985;
    }

    &:focus + label {
        transform: scale(0.75) translateY(calc(${(props) => props.height} / -1 + 0.3 * ${(props) => props.height}));
        color: #394985;
    }

    &:invalid {
        border-color: #d92e23;
        color: #d92e23;
    }

    &:invalid + label {
        color: #d92e23;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
`;
const Label = styled.label<{ height: string }>`
    font-family: Inter, serif;
    position: absolute;
    transition: transform 150ms cubic-bezier(0, 0, 0.7, 1) 150ms, background-color 150ms cubic-bezier(0, 0, 0.7, 1) 150ms;
    color: #b6b6b6;
    background-color: #fff;
    font-size: 16px;
    pointer-events: none;
    left: 10px;
    top: calc((${(props) => props.height} / 2) - 7px);
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 10px;
`;
const Input_active = styled.label<{ height: string }>`
    transform: scale(0.75) translateY(calc(${(props) => props.height} / -1 + 0.3 * ${(props) => props.height}));
    transition: transform 0.15s;
    position: absolute;
    color: #000;
    background-color: #fff;
    top: calc((${(props) => props.height} / 2) - 7px);
    left: 10px;
    font-style: normal;
    line-height: 16px;
    font-weight: 400;
    font-family: Inter, serif;
    z-index: 1;
`;
export default TextFields;
