import React, { useEffect, useState } from 'react';
import { Heading1 } from '../../ui/HeadingTags';
import styled from 'styled-components';
import { Container } from '../..//ui/container';
import BackgroundImage from '../../assets/svgs/main_page_background_2.svg';
import { useShopActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useSelector';
import { ILivePriceMainInfo } from '../../api/shop/shopTypes';
import MobileTable from '../../components/mainPage/mobileTable';
import { roundToTwoDecimals } from '../../helpers/round';

const LivePriceList = () => {
    const { lplInfo } = useTypedSelector((state) => state.shopReducer);

    useEffect(() => {
        if (lplInfo) {
            setCurrentLiveInfo(lplInfo);
        }
    }, [lplInfo]);

    const [currentLiveInfo, setCurrentLiveInfo] = useState(lplInfo);
    const [currentCurrency, setCurrentCurrency] = useState('$');

    return (
        <Wrapper>
            <Container>
                {currentLiveInfo &&
                    currentLiveInfo.map((item, index) => {
                        if (item.categories.length === 0) return;
                        return (
                            <div key={`links-index-${index}`}>
                                <BlockName>{item.name.replace(/^\w/, (c) => c.toUpperCase())}</BlockName>
                                <BlocksWrapper>
                                    {item.categories.map((item, index) => {
                                        return (
                                            <Block key={`links-index-${index}-${index}`}>
                                                <Table>
                                                    <TableName>{item.name.replace(/^\w/, (c) => c.toUpperCase())}</TableName>
                                                    <HeaderTr>
                                                        <FirstTh>Product</FirstTh>
                                                        <TableTh>Buy</TableTh>
                                                        <TableTh>Sell</TableTh>
                                                    </HeaderTr>
                                                    {item.products.map((item, index) => {
                                                        return (
                                                            <TableTr key={`links-index-${index}-${index}-${index}`}>
                                                                <FirstTd>{item.name}</FirstTd>
                                                                <TableTd>
                                                                    {item.buy_price && currentCurrency}
                                                                    {typeof item.buy_price === 'number' ? roundToTwoDecimals(item.buy_price) : '-'}
                                                                </TableTd>
                                                                <TableTd>
                                                                    {item.sell_price && currentCurrency}
                                                                    {typeof item.sell_price === 'number' ? roundToTwoDecimals(item.sell_price) : '-'}
                                                                </TableTd>
                                                            </TableTr>
                                                        );
                                                    })}
                                                </Table>
                                            </Block>
                                        );
                                    })}

                                    {/* <Farm> swap tables for familiarization </Farm>*/}
                                </BlocksWrapper>
                            </div>
                        );
                    })}

                <MobileBlocksWrapper>
                    {currentLiveInfo.map((item, index) => {
                        if (item.categories.length === 0) return;
                        return <MobileTable key={`links-index-${index}`} currentCurrency={currentCurrency} item={item} />;
                    })}
                </MobileBlocksWrapper>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div<{ bg?: string }>`
    padding-top: 70px;
    height: auto;
    background: ${(props) => (props.bg ? `no-repeat left top -40% url(${window.location.origin}${props.bg})` : 'none')};
    @media (max-width: 1290px) {
        //max-width: 94vw ;
        //width: 100%;
        padding: 0 10px;
    }
    @media (max-width: 900px) {
        background-image: none;
        width: unset;
    }
`;
const Heading = styled(Heading1)`
    margin: 0;
`;
const HeaderTr = styled.tr`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 14px;
    max-width: 396px;
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    @media (max-width: 600px) {
        width: 100%;
        max-width: 90vw;
    }
`;

const TableTh = styled.th`
    width: 95px;
    font-family: Inter, serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    max-width: 364px;
    display: flex;
    justify-content: start;
`;
const FirstTh = styled.th`
    font-family: Inter, serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    max-width: 205px;
    width: 100%;
    display: flex;
    justify-content: start;
    @media (max-width: 600px) {
        max-width: 142px;
    }
`;
const TableTr = styled.tr`
    //max-width: 410px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border-bottom: 1px #9FABD9 solid;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;

    :nth-child(odd) {
        background-color: #394985;
        color: white;
    }

    :nth-child(even) {
        background-color: #fff;
    }
`;
const TableTd = styled.td`
    width: 95px;
    display: flex;
    justify-content: start;
`;

const FirstTd = styled.td`
    width: 210px;
    @media (max-width: 600px) {
        width: 157px;
    }
`;

const BlockName = styled.h2`
    text-align: center;
    color: #5b73cf;
    font-family: Inter, serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    @media (max-width: 600px) {
        display: none;
    }
`;

const BlocksWrapper = styled.div`
    margin-bottom: 40px;
    /*  display: flex;
  flex-wrap: wrap;*/
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    @media (max-width: 1290px) {
        overflow-y: auto;
    }
    @media (max-width: 600px) {
        display: none;
    }
`;
const MobileBlocksWrapper = styled.div`
    margin: 40px 0;
    display: none;
    @media (max-width: 600px) {
        max-width: 95vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
const Block = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    @media (max-width: 600px) {
        width: 100%;
        max-width: 95vw;
    }
`;
const Table = styled.div`
    //margin-right: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 410px;
    width: 100%;
    @media (max-width: 600px) {
        width: 100%;
        max-width: 95vw;
        align-items: center;
    }
`;
const TableName = styled.h3`
    //color: #0B1126;
    font-family: Inter, serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
    color: #5b73cf;
    text-decoration: underline;
`;
const SourceText = styled.div`
    font-family: Inter, serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #a0a0a0;
    margin-bottom: 45px;
`;

export default LivePriceList;
