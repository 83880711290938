import { IMetalApiStatus, IMetalPrices } from '../../api/metal/metalTypes';
import { IErrorMetalAction, IGetMetalApiStatus, IGetMetalsPrice, IStartMetalAction, metalsAction } from '../../store/types/metalTypes';
import { MetalsActionTypes } from '../../store/constants/metalsConstants';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { IErrors } from '../../api/user/userTypes';
import { metalApi } from '../../api/metal/metalApi';
import { showSnackbar } from '../../store/actions/snackbarActions';
import { SnakeTypes } from '../../ui/components/modals/SnakeBar';

const startAction = (): IStartMetalAction => ({
    type: MetalsActionTypes.START_METAL_ACTION,
});

const errorAction = (payload: IErrors[]): IErrorMetalAction => ({
    type: MetalsActionTypes.ERROR_METAL_ACTION,
    payload,
});

const getMetalsPrices_ = (prices: IMetalPrices[], currency: string): IGetMetalsPrice => ({
    type: MetalsActionTypes.GET_METAL_PRICES,
    payload: {
        prices,
        currency,
    },
});

export const getMetalPrices = (currency?: string) => async (dispatch: Dispatch<metalsAction>) => {
    try {
        dispatch(startAction());
        const response = await metalApi.getMetalPrices(currency);
        dispatch(getMetalsPrices_(response, currency ? currency : 'AUD'));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorAction(e.response?.data.message));
    }
};

const getMetalApiStatus_ = (payload: IMetalApiStatus): IGetMetalApiStatus => ({
    type: MetalsActionTypes.GET_METAL_API_STATUS,
    payload,
});

export const getMetalApiStatus = () => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAction());
        const response = await metalApi.getMetalApiStatus();
        dispatch(getMetalApiStatus_(response));
        if (!response.status) {
            dispatch(
                showSnackbar({
                    content: 'Online shop is currently unavailable. We apologise for any inconvenience',
                    type: SnakeTypes.error,
                    ms: 1000 * 60 * 5,
                })
            );
        }
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorAction(e.response?.data.message));
    }
};
