import React from 'react';
import styled from "styled-components";
import {ReactComponent as Plus} from "../assets/svgs/counter_plus_icon.svg";
import {ReactComponent as Minus} from "../assets/svgs/counter_minus_icon.svg";
type CounterProps = {
    onClickPlus?: () => void,
    onClickMinus?: () => void
    children: string,

}
const CounterComponent = (props: CounterProps) => {
    return (
        <Button>
            <ButtonContainer>
                <LogoMinus onClick={props.onClickMinus}/>
                <div style={{userSelect: "none" }}>{Number(props.children)}</div>
                <LogoPlus onClick={props.onClickPlus}/>
            </ButtonContainer>
        </Button>
    );
};

export default CounterComponent;
export const Button = styled.div`
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 500;
  height: 44px;
  background-color: #9FABD9;
  width: 100%;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  color: #ffffff;
  @media (max-width: 1294px){
    height: auto;
  }
`
export const ButtonContainer = styled.div`
  width: 100%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LogoPlus = styled(Plus)`
  cursor: pointer;
  &:hover * {
    fill: #394985;

  }
  &:active * {
    fill:#000000;
  }
`
export const LogoMinus = styled(Minus)`
  cursor: pointer;
  &:hover * {
    fill: #394985;

  }
  &:active * {
    fill:#000000;
  }
`


