import { ChangeEvent, useState } from 'react';
import jsonp from 'jsonp';
import queryString from 'query-string';

export const useFormFields = (initialState: { [key: string]: any }) => {
    const [fields, setValues] = useState(initialState);
    const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...fields,
            [event.target.id]: event.target.value,
        });
    };
    return { fields, handleFieldChange };
};

export const useMailChimpForm: (url: string) => {
    handleSubmit: (params: { [p: string]: any }) => Promise<{ status: string; message: string }>;
    success: boolean;
    reset: () => void;
    loading: boolean;
    error: boolean;
    message: string;
} = (url: string) => {
    const initStatusState = {
        loading: false,
        error: false,
        success: false,
    };
    const [status, setStatus] = useState(initStatusState);
    const [message, setMessage] = useState('');

    // eslint-disable-next-line
    const handleSubmit = async (params: { [key: string]: any }): Promise<any> => {
        const query = queryString.stringify(params);
        const endpoint = url.replace('/post?', '/post-json?') + '&' + query;
        setStatus({ ...status, loading: true });
        setMessage('');
        const promise = new Promise((resolve, reject) => {
            jsonp(endpoint, { param: 'c' }, (error, data) => {
                if (error) {
                    setStatus({ ...initStatusState, error: true });
                    setMessage(error.message);
                } else {
                    if (data.result !== 'success') {
                        setStatus({ ...initStatusState, error: true });
                    } else {
                        setStatus({ ...initStatusState, success: true });
                    }
                    setMessage(data.msg);
                }
                return resolve(data);
            });
        });
        const data:
            | {
                  status: 'success' | 'error' | undefined;
                  message: string | undefined;
              }
            | any = await Promise.resolve(promise);
        const result = {
            status: data.result || 'error',
            message: data.msg || 'Something went wrong',
        };

        return result;
    };

    const reset: () => void = async () => {
        setMessage('');
        setStatus(initStatusState);
    };

    return {
        loading: status.loading,
        success: status.success,
        error: status.error,
        message,
        handleSubmit,
        reset,
    };
};
