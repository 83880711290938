import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Container } from '../ui/container';
import { useTypedSelector } from '../hooks/useSelector';
import { useMetalActions } from '../hooks/useActions';
import styled from 'styled-components';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import axios from 'axios';
import { Heading1 } from '../ui/HeadingTags';
import config from '../config';

import loading from '../assets/loading.svg';
import { roundToTwoDecimals } from '../helpers/round';

const Loading = () => {
    const Wrapper = styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    `;

    return (
        <Wrapper>
            <img src={loading} alt="loading" width="60px" height="60px" />
        </Wrapper>
    );
};

const COLORS = ['#3499CF', '#5E8F66', '#BA456A', '#A39B92', '#EF463B', '#8A2040'];

function upperFirst(str: string) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
}

function parseToOneData(data: any) {
    //@ts-ignore
    let response = [];
    if (!data) return;

    Object.keys(data).forEach((key) => {
        if (!Array.isArray(data[key])) return;
        data[key].forEach((item: any, c: any) => {
            //@ts-ignore
            response[c] = { ...response[c], date: item.date, [key]: item.price };
        });
    });
    //@ts-ignore
    return response;
}

const LineMetalChart = (data: any, isMultiply: boolean = false) => {
    const parsedData = data.isMultiply ? parseToOneData(data.data) : data.data;
    if (!parsedData || !parsedData.length) return <div></div>;
    const keys = Object.keys(parsedData[0])
        .map((key) => {
            if (key === 'date') return;
            return key;
        })
        .filter((m) => m);

    return (
        <ResponsiveContainer>
            <LineChart data={parsedData} margin={{ top: 30, right: 30, bottom: 15, left: -10 }}>
                {keys.map((k, c) => (
                    <Line key={k} dot={false} dataKey={k} strokeWidth={2} stroke={COLORS[c % COLORS.length]} />
                ))}

                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="date" style={{ fontSize: 10 }} />
                <YAxis
                    style={{ fontWeight: 700, color: 'black', fontSize: 11 }}
                    tickFormatter={(v: any) => `$${v}`}
                    domain={[
                        (dataMin: any) => (Math.round(dataMin) - 30 < 0 ? 0 : Math.round(dataMin) - 30),
                        (dataMax: any) => (dataMax < 1 ? Math.round(dataMax) + 0.5 : Math.round(dataMax) + 30),
                    ]}
                />
                <Tooltip wrapperStyle={{ fontSize: 14 }} />
                {keys.length > 1 && <Legend />}
            </LineChart>
        </ResponsiveContainer>
    );
};

const tabs = ['7d', '1m', '3m', '1y'];

export const LinesBar = ({ metals }: any) => {
    const [date, setDate] = useState('7d');
    const [graph, setGraph] = useState<any>(null);

    useEffect(() => {
        if (!metals) return;
        const end_date = new Date();
        end_date.setDate(end_date.getDate() - 1);
        end_date.setHours(0);
        end_date.setMinutes(0);
        end_date.setSeconds(0);
        end_date.setMilliseconds(0);

        const start_date = new Date();
        let minus_days = 7;
        if (date === '7d') minus_days = 7;
        else if (date === '1m') minus_days = 30;
        else if (date === '3m') minus_days = 60;
        else if (date === '1y') minus_days = 365;

        start_date.setDate(end_date.getDate() - minus_days);
        start_date.setHours(0);
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);

        const params = {
            start_date: +start_date,
            end_date: +end_date,
        };

        const promises = metals.map((m: any) => {
            return axios.get(`${config.REACT_APP_API_BASE_URL || ''}metal/${m.id}/chart`, { params });
        });

        Promise.all(promises).then((values) => {
            let obj = {};
            //@ts-ignore
            values.forEach((v, c) => (obj[metals[c].name] = v.data));
            setGraph(obj);
        });
    }, [metals, date]);

    return (
        <GraphContainer>
            <div style={{ padding: '0 20px' }}>
                <div style={{ fontSize: 22 }}>All metals $</div>
                <Tabs>
                    {tabs.map((t) => (
                        <Tab key={t} onClick={() => setDate(t)} isActive={t === date}>
                            {t}
                        </Tab>
                    ))}
                </Tabs>
                <GraphMain>
                    <LineMetalChart data={graph} isMultiply={true} />
                </GraphMain>
            </div>
        </GraphContainer>
    );
};

export const LineBar = ({ metal }: any) => {
    const [date, setDate] = useState('3m');
    const [graph, setGraph] = useState<any>(null);
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        if (!metal) return;
        setIsloading(true);
        const end_date = new Date();
        end_date.setDate(end_date.getDate() - 1);
        end_date.setHours(0);
        end_date.setMinutes(0);
        end_date.setSeconds(0);
        end_date.setMilliseconds(0);

        const start_date = new Date();
        let minus_days = 7;
        if (date === '7d') minus_days = 7;
        else if (date === '1m') minus_days = 30;
        else if (date === '3m') minus_days = 60;
        else if (date === '1y') minus_days = 365;

        start_date.setDate(end_date.getDate() - minus_days);
        start_date.setHours(0);
        start_date.setMinutes(0);
        start_date.setSeconds(0);
        start_date.setMilliseconds(0);

        const params = {
            start_date: +start_date,
            end_date: +end_date,
        };

        axios.get(`${config.REACT_APP_API_BASE_URL || ''}/metal/${metal.id}/chart`, { params }).then((response) => {
            setGraph(response.data);
            setIsloading(false);
        });
    }, [metal, date]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <GraphContainer>
            <div style={{ padding: '0 20px' }}>
                <div style={{ fontSize: 14 }}>
                    {upperFirst(metal.name)}
                    <b style={{ marginLeft: 10 }}>$ {roundToTwoDecimals(metal.price)}</b>
                </div>
                <Tabs>
                    {tabs.map((t) => (
                        <Tab key={t} onClick={() => setDate(t)} isActive={t === date}>
                            {t}
                        </Tab>
                    ))}
                </Tabs>
                <GraphMain>
                    <LineMetalChart data={graph} isMultiply={false} />
                </GraphMain>
            </div>
        </GraphContainer>
    );
};

const Charts = () => {
    const [graph, setGraph] = useState({});
    const { currentMetalPrices } = useTypedSelector((state) => state.metalReducer);
    const { getMetalPrices } = useMetalActions();

    useEffect(() => {
        getMetalPrices();
    }, []);

    return (
        <Layout>
            <Container>
                <Content>
                    <Heading1>Charts</Heading1>

                    {currentMetalPrices.map((m) => {
                        return <LineBar key={m.id} metal={m} />;
                    })}

                    <LinesBar metals={currentMetalPrices} />
                </Content>
            </Container>
        </Layout>
    );
};

const Content = styled.div`
    min-height: 280px;
`;

const GraphContainer = styled.div`
    width: 100%;
    padding: 20px 0;
    color: black;
    height: 220px;
`;

const GraphMain = styled.div`
    height: 180px;
    width: 100%;
`;

const Tabs = styled.div`
    display: flex;
    align-items: center;
    background: #f4f6f9;
    border-radius: 10px;
    margin-top: 2.5px;
    padding: 3px 10px;
    justify-content: space-between;
`;

const Tab = styled.div<{ isActive: boolean }>`
    font-size: 12px;
    cursor: pointer;
    border-radius: 40%;
    padding: 2px 4px;
    background: ${(props) => (props.isActive ? '#5B73CF' : 'none')};
    color: ${(props) => (props.isActive ? 'white' : 'black')};
`;

export default Charts;
