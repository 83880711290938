import React, {useState} from 'react';
import styled from "styled-components";

type TextFieldsProps = {
    onClick?: () => void,
    padding?: string,
    placeholder?: string,
    textError?: string
    value?: string,
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    disabled?: boolean,
    rows: number
    required?: boolean
    height: string
    resize?: string
}

const MultilineInput = (props: TextFieldsProps) => {
    const [req, isReq] = useState(false)
    return (
        <Wrapper>
            {
                props.textError === "" || props.textError === undefined ?
                    <Input
                        height={props.height}
                        rows={props.rows}
                        id={props.placeholder}
                        disabled={props.disabled}
                        style={{padding: props.padding}} placeholder={""} value={props.value}
                        onClick={props.onClick} required={(req) === props.required}
                        aria-invalid={true} onBlur={(e) => {
                        isReq(e.target.value.length === 0)
                    }}
                        onChange={(e) => {
                            if (props.onChange) {
                                props.onChange(e);
                            }
                        }}
                    /> : <Input
                        height={props.height}
                        rows={props.rows}
                        id={props.placeholder} disabled={props.disabled}
                        style={{color: "#D92E23", borderColor: "#D92E23", padding: props.padding}}
                        placeholder={""} value={props.value}
                        onClick={props.onClick} required={(props.value?.length === 0) === props.required}
                        aria-invalid={true} onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                    />
            }

            {props.textError === "" || !props.textError ?
                !(props.value?.length === 0) ?
                    <Input_active height={props.height} htmlFor={props.placeholder}> {props.placeholder} </Input_active>
                    : <Label height={props.height} htmlFor={props.placeholder}>{props.placeholder}</Label> :
                !(props.value?.length === 0) ?
                    <Input_active style={{color: "#D92E23"}} height={props.height}
                                  htmlFor={props.placeholder}> {props.placeholder} </Input_active>
                    : <Label style={{color: "#D92E23"}} height={props.height}
                             htmlFor={props.placeholder}>{props.placeholder}</Label>
            }

            <div
                style={{color: "#D92E23", paddingLeft: 10, fontSize: 14, position: "absolute"}}> {props.textError}</div>


        </Wrapper>

    );
};

const Input = styled.textarea<{ height: string, resize?: string }>`
  font-family: Inter, serif;
  resize: vertical;//либо none
  width: 100%;
  font-weight: 400;
  border-radius: 4px;
  cursor: text;
  transition: border 0.15s ease-in-out 0s, color 0.15s ease-in-out 0s;
  border: #B6B6B6 solid 1px;
  position: relative;
  line-height: 16px;
  font-size: 16px;
  padding-left: 10px;
  box-sizing: border-box;
  padding-top: 20px;

  &:focus-visible {
    outline: none;
    border: #394985 solid 1px;
    color: #394985;
  }

  &:focus-within + label {
    transform: scale(0.75) translateY(calc(${props => props.height} / -1 + 0.3 * ${props => props.height}));
    color: #394985;
  }

  &:focus + label {
    transform: scale(0.75) translateY(calc(${props => props.height} / -1 + 0.3 * ${props => props.height}));
    color: #394985;
  }

  &:invalid {
    border-color: #D92E23;
    color: #D92E23;
  }

  &:invalid + label {
    color: #D92E23;
  }

`
const Label = styled.label <{ height: string }>`
  font-family: Inter, serif;
  position: absolute;
  transition: transform 100ms cubic-bezier(0, 0, 0.2, 1) 100ms, background-color 100ms cubic-bezier(0, 0, 0.2, 1) 100ms;
  color: #B6B6B6;
  background-color: #FFF;
  font-size: 16px;
  pointer-events: none;
  left: 10px;
  top: calc((${props => props.height} / 2) - 7px);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 5px;
`
const Input_active = styled.label<{ height: string }>`
  transform: scale(0.75) translateY(calc(${props => props.height} / -1 + 0.3 * ${props => props.height}));
  transition: transform 0.1s;
  position: absolute;
  color: #000;
  background-color: #FFF;
  top: calc((${props => props.height} / 2) - 7px);
  left: 10px;
  font-style: normal;
  line-height: 16px;
  font-weight: 400;
  font-family: Inter, serif;
`

export default MultilineInput;