import React from 'react';
import styled from 'styled-components';
import StyledButton from '../../../ui/StyledButton';
import { Link } from 'react-router-dom';

interface ICardData {
    text: string;
    image: string;
    price: string;
    onClick: () => void;
    redirectOnProductPage?: () => void;
    is_out_of_stock?: boolean;
    isApiOff?: boolean;
    quantity: number;
    link: string;
}

const Card: React.FC<ICardData> = ({
    text = '',
    image = '',
    price = '',
    onClick,
    redirectOnProductPage,
    is_out_of_stock,
    isApiOff,
    quantity,
    link,
}) => {
    return (
        <Wrapper onClick={() => {}}>
            <LinkToProduct to={link}>
                <Image
                    onClick={() => {
                        if (redirectOnProductPage) {
                            redirectOnProductPage();
                        }
                    }}
                    width={'100%'}
                    height={'100%'}
                    src={image}
                    alt="coin"
                />
                <TextBlock
                    onClick={() => {
                        if (redirectOnProductPage) {
                            redirectOnProductPage();
                        }
                    }}
                >
                    <Text>{text}</Text>
                    <Price>{price}</Price>
                </TextBlock>
                <TextBlock
                    style={{ marginTop: '5px' }}
                    onClick={() => {
                        if (redirectOnProductPage) {
                            redirectOnProductPage();
                        }
                    }}
                >
                    <Text style={{ color: '#66676e' }}>Quantity: {quantity}</Text>
                    {/* <Price style={{ fontSize: '12px' }}>{quantity}</Price> */}
                </TextBlock>
            </LinkToProduct>

            <ButtonWrapper>
                <StyledButton
                    onClick={() => {
                        onClick();
                    }}
                    height={'50px'}
                    disabled={is_out_of_stock || isApiOff}
                >
                    {is_out_of_stock ? 'Out of Stock' : 'Add to cart'}
                </StyledButton>
            </ButtonWrapper>
        </Wrapper>
    );
};

const ButtonWrapper = styled.div`
    padding: 0 10px 10px;
    @media (max-width: 450px) {
        padding: 0 0 10px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: 390px;
    width: 300px;
    padding-top: 10px;
    //padding: 20px;
    justify-content: space-around;
    box-shadow: 3px 3px 10px 3px rgba(91, 115, 207, 0.12);
    border-radius: 6px;
    margin-bottom: 20px;
    gap: 5px;

    &:hover p {
        transition: color 0.25s;
        color: #5b73cf;
    }

    @media (max-width: 1270px) {
        height: 280px;
        max-width: 22vw;
    }

    @media (max-width: 1000px) {
        //height: 280px;
        max-width: 170px;
    }

    @media (max-width: 900px) {
        //height: 260px;
        //max-width: 200px;
        //padding: 14px;
        //padding: 0;
        //padding-top: 0;
    }

    @media (max-width: 730px) {
        max-width: 30vw;
    }

    @media (max-width: 600px) {
        max-width: 45vw;
    }

    @media (max-width: 450px) {
        //height: 302px;
        //max-width: 165px;
        padding-left: 10px;
        padding-right: 10px;
        max-width: 140px;
    }
`;
const Price = styled.div`
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #0f0927;
    font-weight: 600;
    line-height: 19px;
    @media (max-width: 900px) {
        font-size: 14px;
        line-height: 16px;
    }
`;
const Text = styled.h3`
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0e1d56;
    font-weight: 500;
    line-height: 19px;
    max-height: 38px;
    margin: 0;
    // width: 50%;
    @media (max-width: 900px) {
        font-size: 14px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;
const TextBlock = styled.div`
    //margin: 12px 0 16px 0;
    padding-left: 17px;
    padding-right: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 3px;
    @media (max-width: 450px) {
        padding: 0;
    }
`;
const Image = styled.img`
    margin: auto;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 260px;
    object-fit: contain;
    width: 100%;
    max-width: 260px;
    @media (max-width: 1270px) {
        height: 150px;
        width: auto;
    }
    @media (max-width: 1080px) {
    }
    @media (max-width: 900px) {
        //height: 135px;
        margin: auto;
        width: 137px;
        height: 142px;
    }
`;

const LinkToProduct = styled(Link)`
    text-decoration: none;
`;

export default Card;
