import React from 'react';
import styled from 'styled-components';
import OrderCard from '../../components/orders/OrderCard';
import { IOrder } from '../../api/order/orderTypes';

interface IOrderList {
    orders: IOrder[];
}

function formatDate(date: string) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = '' + d.getFullYear();
    year = year.slice(2);
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('.');
}

const OrderList: React.FC<IOrderList> = ({ orders }) => {
    return (
        <Wrapper>
            {orders.map((item, index) => {
                return (
                    <React.Fragment key={`card-index-${index}`}>
                        <div style={{ marginBottom: '22px' }}>
                            <OrderCard
                                orderNumber={item.id}
                                totalPrice={item.total_price + ''}
                                date={formatDate(item.createdAt)}
                                items={item.products}
                                status={item.status}
                            />
                        </div>
                    </React.Fragment>
                );
            })}
        </Wrapper>
    );
};
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 80vh;
`;
export default OrderList;
