import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Container } from '..//ui/container';
import CartStonkIMG from '../assets/svgs/cart_stonk_img.svg';
import styled from 'styled-components';
import CheckboxComponent from '../ui/CheckboxComponent';
import StyledButton, { Button } from '../ui/StyledButton';
import { useCartActions } from '../hooks/useActions';
import { useTypedSelector } from '../hooks/useSelector';
import Timer from '../ui/Timer';
import CardProductElement from '../components/cart/CardProductElement';
import { ILocalCart, IProduct } from '../api/cart/cartTypes';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../ui/components/modals/Modal';
import WarningModal from '../ui/components/modals/WarningModal';
import { orderApi } from '../api/order/orderApi';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../store/actions/snackbarActions';
import { SnakeTypes } from '../ui/components/modals/SnakeBar';
import { roundToTwoDecimals } from '../helpers/round';
import Loading from '../components/loading';
import loadingImg from '../assets/loading.svg';
import emptyCartImg from '../assets/svgs/empty_cart.svg';

interface ILocalProduct {
    id: string;
    quantity: number;
    totalPrice: number;
    gst: boolean;
}

const Cart = () => {
    const { getCart, updateQuantityProductInCart, deleteProductFromCart, startCartAction, endCartAction } = useCartActions();
    const { cart, loading } = useTypedSelector((state) => state.cartReducer);
    const { user } = useTypedSelector((state) => state.userReducer);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [currentProducts, setCurrentProducts] = useState<ILocalProduct[]>([]);
    const [chosenID, setChosenID] = useState('');
    const [subTotal, setSubTotal] = useState(0);
    const [currentTax, setCurrentTax] = useState<number>(0);
    const [isSelectedAll, setIsSelectAll] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentMoneyType, setCurrentMoneyType] = useState('$');

    const onClickMinus = (id: string, quantity: number) => {
        updateQuantityProductInCart(id, { quantity: quantity - 1 });
    };

    const onClickPlus = (id: string, quantity: number) => {
        updateQuantityProductInCart(id, { quantity: quantity + 1 });
    };

    const addProductToList = (item: IProduct) => {
        if (currentProducts.find((i) => i.id === item.id)) {
            setCurrentProducts([...currentProducts.filter((i) => i.id !== item.id)]);
        } else {
            setCurrentProducts([
                ...currentProducts,
                {
                    id: item.id,
                    totalPrice: item.price * item.quantity,
                    quantity: item.quantity,
                    gst: item.gst,
                },
            ]);
        }
    };

    const deleteCartElem = (id: string) => {
        setChosenID(id);
        setShowDeleteModal(true);
        /*deleteProductFromCart(id)*/
    };

    const clickSelectAll = () => {
        if (isSelectedAll) {
            setCurrentProducts([]);
        } else {
            const newObj = cart?.products.map((i, index) => {
                return {
                    id: i.id,
                    totalPrice: i.price * i.quantity,
                    quantity: i.quantity,
                    gst: i.gst,
                };
            });
            if (!newObj) return;
            setCurrentProducts([...newObj]);
        }
        setIsSelectAll(!isSelectedAll);
    };

    const goToCheckout = async () => {
        dispatch(
            showSnackbar({
                type: SnakeTypes.success,
                content: 'The demo ends here, you can not proceed to checkout',
                ms: 10000,
            })
        );

        // if (!cart) return;

        // if (
        //     cart.products.every((item) => {
        //         return item.quantity === 0;
        //     })
        // ) {
        //     return;
        // }
        // try {
        //     startCartAction();
        //     for (const cpr of [...cart.products].filter((item) => currentProducts.find((i) => i.id === item.id))) {
        //         await orderApi.checkAvailability({ product_id: cpr.id, quantity: cpr.quantity });
        //     }
        //     endCartAction();
        //     const newCart: ILocalCart = {
        //         tax: currentTax,
        //         sub_total: subTotal,
        //         total_price: subTotal + currentTax,
        //         products: [
        //             ...cart.products
        //                 .filter((item) => currentProducts.find((i) => i.id === item.id))
        //                 .map((i) => ({
        //                     id: i.id,
        //                     name: i.name,
        //                     price: i.price,
        //                     quantity: i.quantity,
        //                 })),
        //         ],
        //     };
        //     localStorage.setItem('cart', JSON.stringify(newCart));
        //     navigate('/checkout');
        // } catch (error: any) {
        //     const e = error as AxiosError;
        //     dispatch(
        //         showSnackbar({
        //             content: error.response?.data.message || 'Not in stock',
        //             type: SnakeTypes.error,
        //             ms: 4000,
        //         })
        //     );
        // }
    };

    useEffect(() => {
        if (!(user?.role === 'admin' || user?.role === 'super-admin')) {
            getCart();
        }
    }, []);

    useEffect(() => {
        setCurrentTax(0);
        const newCurrentProducts: ILocalProduct[] = [];
        let newTax = 0;
        currentProducts.forEach((item) => {
            const curItem = cart?.products.find((i) => i.id === item.id);
            if (curItem) {
                newCurrentProducts.push({
                    id: item.id,
                    totalPrice: curItem.price * curItem.quantity,
                    quantity: curItem.quantity,
                    gst: curItem.gst,
                });

                if (curItem.gst) {
                    setCurrentTax((prev) => prev + curItem.price * curItem.quantity * (curItem.price - curItem.price / 1.1));
                }
            }
        });
        setCurrentProducts(newCurrentProducts);
    }, [cart]);

    useEffect(() => {
        setSubTotal(0);
        setCurrentTax(0);
        currentProducts.forEach((cpr) => {
            if (cpr.gst) {
                setCurrentTax((prev) => prev + +roundToTwoDecimals(cpr.totalPrice) - +roundToTwoDecimals(cpr.totalPrice / 1.1));
            }
            setSubTotal((prev) => prev + (cpr.gst ? +roundToTwoDecimals(cpr.totalPrice / 1.1) : +roundToTwoDecimals(cpr.totalPrice)));
        });
        setIsSelectAll(cart?.products.length === 0 ? false : currentProducts.length === cart?.products.length);
    }, [currentProducts]);

    useEffect(() => {
        if (cart && isSelectedAll === false) {
            clickSelectAll();
        }
    }, [cart]);

    return (
        <Layout>
            <Modal
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                ModalElement={() =>
                    WarningModal({
                        deleteAction: () => {
                            deleteProductFromCart(chosenID);
                            setShowDeleteModal(false);
                        },
                        closeModal: () => setShowDeleteModal(false),
                        description: 'Do you want to delete this product?',
                    })
                }
            />
            <Container>
                <Content>
                    {/*                    <StyledButton onClick={onClick}>
                        Add Product
                    </StyledButton>*/}
                    <Title>Your Cart</Title>
                    <CartDescriptionBlock>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                            <img src={CartStonkIMG} alt={'stonk'} />
                            <CartDescriptionText>
                                The price of the product is locked in for 10 minutes, After that, you need to refresh the cart to see the updated
                                price.
                            </CartDescriptionText>
                        </div>
                        <CartTime>
                            {cart && +new Date(cart.exp) - +new Date() > 0 && (
                                <Timer
                                    initSeconds={
                                        new Date(new Date(cart.exp).getTime() - new Date().getTime()).getMinutes() * 60 +
                                        new Date(new Date(cart.exp).getTime() - new Date().getTime()).getSeconds()
                                    }
                                    handleFinish={() => {
                                        getCart();
                                    }}
                                />
                            )}
                        </CartTime>
                    </CartDescriptionBlock>
                    {(!cart || cart?.products.length === 0) && (
                        <EmptyCartBlock>
                            <EmptyCartDescriptionWrapper>
                                <EmptyCartIcon alt="empty" src={emptyCartImg} />
                                <ShopNow>
                                    Your cart is empty
                                    <br />
                                </ShopNow>
                            </EmptyCartDescriptionWrapper>

                            <ShopNowWrapper>
                                <ShopLink to={'/shop'}>Shop now!</ShopLink>
                            </ShopNowWrapper>
                        </EmptyCartBlock>
                    )}

                    {cart && (
                        <MainContainer>
                            <LeftBlock>
                                <CheckboxContainer>
                                    <CheckboxComponent onClick={clickSelectAll} value={isSelectedAll} width={'18px'} height={'18px'} />
                                    <div onClick={clickSelectAll} style={{ cursor: 'pointer' }}>
                                        Select All
                                    </div>
                                </CheckboxContainer>
                                <CartGrid>
                                    <Label>Product</Label>
                                    <Label style={{ textAlign: 'center' }}>Quantity</Label>
                                    <Label style={{ textAlign: 'center' }}>Total price</Label>
                                </CartGrid>
                                {cart?.products.map((item, index) => {
                                    const isSelectElem = currentProducts.find((i) => i.id === item.id);
                                    return (
                                        <CardProductElement
                                            key={`card_element${item.id} ${index}`}
                                            isSelected={!!isSelectElem}
                                            item={item}
                                            onClickPlus={onClickPlus}
                                            onClickMinus={onClickMinus}
                                            addProductToList={addProductToList}
                                            deleteAction={deleteCartElem}
                                        />
                                    );
                                })}
                            </LeftBlock>
                            <RightBlock>
                                <OrderBlock>
                                    <OrderTitle>Order summary</OrderTitle>
                                    <Subtotal>
                                        <div>Subtotal</div>
                                        <div>
                                            {currentMoneyType}
                                            {roundToTwoDecimals(subTotal)}
                                        </div>
                                    </Subtotal>
                                    <Subtotal>
                                        <div>Tax</div>
                                        <div>
                                            {currentMoneyType}
                                            {roundToTwoDecimals(currentTax)}
                                        </div>
                                    </Subtotal>
                                    <Total>
                                        <div>Total</div>
                                        <div>
                                            {currentMoneyType}
                                            {roundToTwoDecimals(+roundToTwoDecimals(subTotal) + +roundToTwoDecimals(currentTax))}
                                        </div>
                                    </Total>
                                    <BTNContainer>
                                        <StyledButton
                                            onClick={goToCheckout}
                                            height={'50px'}
                                            disabled={
                                                currentProducts.length === 0 ||
                                                !cart ||
                                                cart.products.every((item) => {
                                                    return item.quantity === 0;
                                                }) ||
                                                loading
                                            }
                                        >
                                            {loading ? <img alt="loading" src={loadingImg} width="48" height="48" /> : 'Proceed to checkout'}
                                        </StyledButton>
                                    </BTNContainer>
                                </OrderBlock>
                            </RightBlock>
                        </MainContainer>
                    )}
                </Content>
                {currentProducts.length !== 0 && (
                    <FixedBottomBlock>
                        <BottomPriceBlock>
                            {currentMoneyType}
                            {roundToTwoDecimals(subTotal + Number(roundToTwoDecimals(currentTax)))}
                        </BottomPriceBlock>
                        <div style={{ width: 180, marginRight: 10 }}>
                            <StyledButton
                                onClick={goToCheckout}
                                height={'50px'}
                                disabled={
                                    currentProducts.length === 0 ||
                                    !cart ||
                                    cart.products.every((item) => {
                                        return item.quantity === 0;
                                    }) ||
                                    loading
                                }
                            >
                                {loading ? <img alt="loading" src={loadingImg} width="48" height="48" /> : 'Proceed to checkout'}
                            </StyledButton>
                        </div>
                    </FixedBottomBlock>
                )}
            </Container>
        </Layout>
    );
};

const BottomPriceBlock = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    margin-left: 30px;
`;

const FixedBottomBlock = styled.div`
    display: none;
    @media (max-width: 1420px) {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #ffffff;
        box-shadow: 3px 0px 14px -2px rgba(91, 115, 207, 0.24);
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
    }
`;

const BTNContainer = styled.div`
    @media (max-width: 1420px) {
        width: 95%;
    }
`;

const Total = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
    @media (max-width: 1420px) {
        width: 95%;
    }
    /*  width: 95%;*/
`;

const Subtotal = styled.div`
    margin-top: 10px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #9fabd9;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    @media (max-width: 1420px) {
        width: 95%;
    }
`;

const OrderTitle = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

const OrderBlock = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    width: 400px;
    min-height: 300px;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    border-radius: 6px;
    padding: 25px 25px 0 25px;
    @media (max-width: 1420px) {
        width: 100%;
        align-items: center;
        padding: 0;
    }
`;

const Label = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
`;

const CartGrid = styled.div`
    display: grid;
    grid-template-columns: 4fr 1fr 2fr;
    border-bottom: 1px solid #9fabd9;
    padding-bottom: 10px;
    min-width: 850px;
    @media (max-width: 1420px) {
        min-width: 100%;
    }
    @media (max-width: 770px) {
        display: none;
    }
`;

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
`;

const RightBlock = styled.div`
    padding-bottom: 50px;
`;

const LeftBlock = styled.div`
    padding-bottom: 50px;
`;

const MainContainer = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    @media (max-width: 1420px) {
        flex-direction: column;
        padding-right: 16px;
    }
`;

const CartDescriptionBlock = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 50px;
    margin-top: 30px;
    @media (max-width: 770px) {
        flex-direction: column;
    }
`;

const CartDescriptionText = styled.div`
    width: 416px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
`;

const CartTime = styled.div`
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;

    color: #5b73cf;
`;

const Title = styled.div`
    font-weight: 900;
    font-size: 40px;
    line-height: 53px;

    color: #0b1126;
`;

const Content = styled.div`
    padding: 50px 16px 0 16px;
    min-height: 1020px;
`;

const EmptyCartBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 60px 0 60px 0;
    box-shadow: rgb(239, 239, 240) 0px 0px 0px 20px;
    box-sizing: border-box;
    padding: 50px;
    /* width: 300px; */

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ShopNow = styled.p`
    font-weight: 600;
    font-size: 18px;
    text-align: center;

    a {
        text-decoration: none;
    }
`;

const ShopNowWrapper = styled.div`
    font-weight: 600;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    width: 100%;
    height: 100px;
    margin: 0 auto;

    a {
        text-decoration: none;
    }
`;

const EmptyCartIcon = styled.img`
    width: 100%;
    height: 250px;
`;

const ShopLink = styled(Link)`
    text-decoration: none;
    color: black;
    line-height: 30;
`;

const EmptyCartDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export default Cart;
