import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Down from '../assets/svgs/settings_backArrow_icon.svg';
import MobileSelectorIcon from '../assets/svgs/selector-short-icon.svg';
import { useClickAway } from '../hooks/useClickAway';

export interface IList {
    id?: string;
    title: string;
}

interface IProps {
    mobileShort?: boolean;
    isFullWidth?: boolean;
    isShopFilter?: boolean;
    selected: string;
    setValue?: (value: IList) => void;
    list: IList[];
    placeholder?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const Selector = (props: IProps) => {
    const [isShown, setIsShown] = useState(false);

    const refSelector = useRef(null);
    useClickAway(refSelector, () => {
        setIsShown(false);
    });

    return (
        <Wrapper
            ref={refSelector}
            onClick={() => {
                setIsShown(!isShown);
            }}
        >
            {!props.mobileShort ? (
                <WrapperSelector
                    isFullWidth={props.isFullWidth || false}
                    mobileShort={props.mobileShort || false}
                    onClick={(e) => {
                        if (props.onClick) {
                            props.onClick(e);
                        }
                    }}
                >
                    {props.selected}
                    <DownImg opens={isShown} src={Down} alt="down" />
                </WrapperSelector>
            ) : (
                <>
                    {' '}
                    <WrapperSelector
                        isFullWidth={props.isFullWidth || false}
                        mobileShort={props.mobileShort || false}
                        onClick={(e) => {
                            if (props.onClick) {
                                props.onClick(e);
                            }
                        }}
                    >
                        {props.selected}
                        <DownImg opens={isShown} src={Down} alt="down" />
                    </WrapperSelector>
                    <MobileIcon bg={MobileSelectorIcon} mobileShort={props.mobileShort || false} />
                </>
            )}
            {isShown && (
                <AccountMenuBlock
                    isFullWidth={props.isFullWidth || false}
                    mobileShort={props.mobileShort || false}
                    isShopFilter={props.isShopFilter || false}
                >
                    {props.list
                        ? props.list.map((item, index) => {
                              return (
                                  <div
                                      key={`links-index-${index}`}
                                      onClick={(e) => {
                                          if (props.setValue) {
                                              props.setValue(item);
                                          }
                                      }}
                                  >
                                      <AccountMenuLinks>{item.title} </AccountMenuLinks>
                                  </div>
                              );
                          })
                        : ''}
                </AccountMenuBlock>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    cursor: pointer;
`;
const MobileIcon = styled.div<{ bg: string; mobileShort: boolean }>`
    cursor: pointer;
    background: url(${window.location.origin}${(props) => props.bg});
    width: 45px;
    height: 45px;
    background-size: contain;
    @media (min-width: 900px) {
        display: ${(props) => (props.mobileShort ? 'none' : 'flex')};
    }
`;
const WrapperSelector = styled.div<{ mobileShort: boolean; isFullWidth: boolean }>`
    position: relative;
    z-index: 1;
    background: white;
    width: ${(props) => (props.isFullWidth ? '100%' : '260px')};
    height: 18px;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    border-radius: 6px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 11px 15px;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    @media (max-width: 900px) {
        display: ${(props) => (props.mobileShort ? 'none' : 'flex')};
    }
    @media (max-width: 600px) {
        max-width: ${(props) => (props.isFullWidth ? '100%' : '240px')};
    }
`;
const DownImg = styled.img<{ opens: boolean }>`
    height: 16px;
    width: 9px;
    transform: rotate(${(props) => (props.opens ? '90deg' : '270deg')});
    transition: transform 0.2s;
`;
const AccountMenuLinks = styled.p`
    text-decoration: none;
    color: #202020;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
`;
const AccountMenuBlock = styled.div<{ mobileShort: boolean; isFullWidth: boolean; isShopFilter: boolean }>`
    width: ${(props) => (props.isFullWidth ? '100%' : '260px')};
    padding: 15px 13px;
    background: white;
    position: absolute;
    overflow: visible;
    z-index: 100;
    top: 40px;

    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.38);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    @media (max-width: 900px) {
        //right: 0;
    }
    @media (max-width: 600px) {
        max-width: ${(props) => (props.isFullWidth ? '100%' : '240px')};
        right: ${(props) => (props.isShopFilter ? 0 : 'unset')};
        top: ${(props) => (props.isShopFilter ? '65px' : '40px')};
    }
`;
export default Selector;
