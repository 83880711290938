import Layout from '../components/Layout';
import { Container } from '../ui/container';
import React from 'react';
import { Heading1 } from '../ui/HeadingTags';
import styled from 'styled-components';
import SettingsMenu from '../components/settings/Menu';
import BackArrow from '../assets/svgs/settings_backArrow_icon.svg';
import SettingsFieldsComponent from '../components/settings/SettingsComponentFields';
import SettingsAddressesComponent from '../components/settings/SettingsComponentAdresses';
import { useTypedSelector } from '../hooks/useSelector';
import { Link } from 'react-router-dom';
import StyledButton from '../ui/StyledButton';

const linkAdmin = process.env.REACT_APP_ADMIN_URL || '';
//const linkAdmin1 = 'http://localhost:3001/'

const Settings = () => {
    const { user } = useTypedSelector((state) => state.userReducer);

    return (
        <Layout>
            <Container>
                <Content>
                    {/* <BackBox to={'/'}>
                        <img src={BackArrow} onClick={() => {}} /> Back
                    </BackBox> */}
                    <Heading1> Profile</Heading1>
                    <Wrapper>
                        <SettingsMenu />
                        {!(user?.role === 'admin' || user?.role === 'super-admin') ? (
                            <FieldDiv>
                                <SettingsFieldsComponent />
                                <SettingsAddressesComponent />
                            </FieldDiv>
                        ) : (
                            <GoToAdminPanelBlock>
                                <a href={linkAdmin}>
                                    <StyledButton height={'50px'}>OPEN ADMIN PAGE</StyledButton>
                                </a>
                            </GoToAdminPanelBlock>
                        )}
                    </Wrapper>
                </Content>
            </Container>
        </Layout>
    );
};

const Wrapper = styled.div`
    display: flex;
    width: 100%;
`;
const Content = styled.div`
    @media (max-width: 1290px) {
        padding: 0 16px;
    }
    @media (max-width: 600px) {
        padding: 0 11px;
    }
`;
const BackBox = styled(Link)`
    text-decoration: none;
    margin: 47px 0;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #0f0927;
    display: flex;
    align-items: center;
    width: 52px;
    justify-content: space-between;
`;
const FieldDiv = styled.div`
    margin-bottom: 200px;
    width: 100%;

    & * {
        @media (max-width: 600px) {
            margin-left: 0;
        }
    }
`;

const GoToAdminPanelBlock = styled.div`
    height: 350px;
    width: 200px;
    margin: 0 auto auto auto;
`;

const LinkToAdmin = styled(Link)`
    margin: auto;
`;
export default Settings;
