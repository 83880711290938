import {IRequestSignUpJuristicWholesalerUser, IRequestSignUpPrivateUser} from "@api/user/userTypes";
import {IUserDataSignUp} from "@/components/auth/SignUp";

interface nonNaturalUser extends Omit<IRequestSignUpJuristicWholesalerUser, 'role'> {
}

export const formNaturalUser = (data: IUserDataSignUp): IRequestSignUpPrivateUser => ({
    first_name: data.firstName,
    surname: data.lastName,
    email: data.email,
    phone: data.phone.includes('+610') ? data.phone.replace('0','').replace(/ /g, '') : data.phone.replace(/ /g, ''),
    residential_address: data.residentialAddress,
    delivery_address: data.deliveryAddress,
    actual_address: data.actualAddress,
    password: data.password,
})

const formNonNaturalUser = (data: IUserDataSignUp): nonNaturalUser => ({
    company_code: data.company_code,
    company_name: data.companyName,
    email: data.email,
    phone: data.phone.includes('+610') ? data.phone.replace('0','').replace(/ /g, '') : data.phone.replace(/ /g, ''),
    residential_address: data.residentialAddress,
    delivery_address: data.deliveryAddress,
    actual_address: data.actualAddress,
    website: data.website,
    password: data.password,
})

export const formJuristicUser = (data: IUserDataSignUp): IRequestSignUpJuristicWholesalerUser => ({
    ...formNonNaturalUser(data),
    role: "juristic"
})

export const formWholesalerUser = (data: IUserDataSignUp): IRequestSignUpJuristicWholesalerUser => ({
    ...formNonNaturalUser(data),
    role: "wholesaler"
})