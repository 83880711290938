import React, {useState} from 'react';
import styled from "styled-components";
import Down from "../../assets/svgs/Down.svg"

const HiddenCard = ({item}:any) => {
    const [open, setOpen] = useState(false)

    return (

        <Wrapper>
            <ManageHeader onClick={()=>{console.log(item[1])}}>
                <LittlePiece/>
                <HeaderFAQBlock open={open} onClick={() => {
                    setOpen(!open);
                }}>
                    <Title>
                        {
                            item[0].replace(";quest;", "?")
                        }
                    </Title>
                    <ImgDown open={open}>
                        <img src={Down} alt="down"/>
                    </ImgDown>
                </HeaderFAQBlock>
            </ManageHeader>

            <TextBlock aria-expanded={!open} open={open}>
                <ContentTextBlock dangerouslySetInnerHTML={{__html:  item[1].data}}>

                </ContentTextBlock>
            </TextBlock>


        </Wrapper>
    )
        ;
};
const Wrapper = styled.div`
  margin: 10px;
`

const ManageHeader = styled.div`

  display: flex;
  box-shadow: 2px 2px 2px 2px rgba(91, 115, 207, 0.12);
  @media (max-width: 1290px) {
    width: 40vw;
  }
  @media (max-width: 900px) {
    width: 92vw;
  }
`

const ImgDown = styled.div<{ open: boolean }>`
  display: flex;
  transform: rotate(${props => (props.open ? "360deg" : "180deg")});
  margin: 20px;
  transition: transform 500ms;
`

const ContentTextBlock = styled.div`
  font-family: Inter, serif;
  padding: 13px 27px;
  color: #0B1126;
  font-weight: 400;
  font-size: 16px;
  @media (max-width: 900px) {
    font-size: 14px;
    padding: 15px 15px 10px 15px;
  }
`

const TextBlock = styled.div<{  open: boolean }>`
  overflow: hidden;
  max-height: ${props => props.open ? "1000px" : "0px"};
  box-shadow: 0 10px 10px rgba(91, 115, 207, 0.12);
  width: 647px;
  transition: max-height .5s ease;


  @media (max-width: 1290px) {
    width: 40vw;
  }
  @media (max-width: 900px) {
    width: 92vw;
  }
`

const Title = styled.h2`
  margin-left: 4px ;
  color: #0B1126;
  font-style: normal;
  font-family: 'Inter', serif;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`

const LittlePiece = styled.div`
  background-color: #F8C882;
  width: 17px;
  height: 70px;
  @media (max-width: 900px) {
    height: 54px;
  }
`

const HeaderFAQBlock = styled.button<{ open: boolean }>`
  background-color: ${props => (props.open ? "#F8C882" : "#FFFFFF")};
  cursor: pointer;
  transition: background-color 500ms;
  &:hover {
    background-color: ${(props) => (props.open ? "#F8C882" : "#FFF0DB")};
  }

  width: 630px;
  border-width: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1290px) {
    width: 40vw;
  }
  @media (max-width: 900px) {
    width: 92vw;
  }
`
export default HiddenCard;
