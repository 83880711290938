import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/svgs/footerHeader_logo_img.svg';
import CallIcon from '../../assets/svgs/footer_call_icon.svg';
import LocationIcon from '../../assets/svgs/footer_location_icon.svg';
import MailLogo from '../../assets/svgs/footer_message_icon.svg';
import Youtube from '../../assets/svgs/footer_youtubeLogo_icon.svg';
import Twitter from '../../assets/svgs/footer_twitterLogo_icon.svg';
import Facebook from '../../assets/svgs/footer_facebookLogo_icon.svg';
import UsefulLinksLogo from '../../assets/svgs/footer_usefulLinks_icon.svg';
import SecureDeliveryLogo from '../../assets/svgs/footer_secureDelivery_icon.svg';
import PaymentMethodLogo from '../../assets/svgs/footer_paymentMethod_icon.svg';
import { Link } from 'react-router-dom';

interface IFooterProps {
    open: boolean;
}

const goToMail = () => {
    window.location.href = 'mailto:sales@bullionnetwork.com.au';
};
const goToPhone = () => {
    window.location.href = 'tel:';
};
const usefulLinks = [
    {
        title: 'About us',
        link: '/about_us',
    },
    {
        title: 'Shop',
        link: '/shop',
    },
    {
        title: 'Contact us',
        link: '/contact_us',
    },
    {
        title: 'FAQ',
        link: '/faq',
    },
    {
        title: 'Privacy policy',
        link: '/privacy_policy',
    },
    {
        title: 'Terms and conditions',
        link: '/terms_and_conditions',
    },
    {
        title: 'Blog',
        link: 'https://blog.bullionnetwork.com.au/',
    },
    {
        title: 'Subscribe to our newsletter',
        link: '/subscribe_to_newsletter',
    },
];
const footerMainContent = [
    {
        icon: UsefulLinksLogo,
        title: 'Useful Links',
        // text: 'Below are some useful links:',
        text: '',
        list: usefulLinks,
    },
    {
        icon: SecureDeliveryLogo,
        title: 'Secure Delivery',
        text: 'Secure and discreet delivery to your door, anywhere in Australia. Shipping costs are calculated based on your total order and insurance preferences.',
        list: '',
    },
    {
        icon: PaymentMethodLogo,
        title: 'Payment Method',
        text: 'For online orders we currently accept electronic bank transfer and cash payment in store (within 1 business day of placing order).',
        list: '',
    },
];
const footerInfo = [
    {
        icon: LocationIcon,
        text: 'Suite 517 level 5 220 Collins St Manchester Unity Building Melbourne 3004',
    },
    {
        icon: CallIcon,
        text: '03.8618.6941',
        func: goToPhone,
    },
    {
        icon: MailLogo,
        text: 'sales@bullionnetwork.com.au',
        func: goToMail,
    },
];
const mediaInfo = [
    {
        icon: Youtube,
        link: '/',
    },
    {
        icon: Twitter,
        link: '/',
    },
    {
        icon: Facebook,
        link: 'https://www.facebook.com/people/Bullion-Network/100083234923655/',
    },
];
let today = new Date();
let year = today.getFullYear();
const Footer: React.FC<IFooterProps> = ({ open }) => {
    return (
        <Wrapper>
            {!open ? (
                <Container>
                    <MainPart>
                        <LinksLogoBlock>
                            <LogoBlock href={`/`}>
                                <Logo src={logo} alt="logo" onClick={() => {}} />
                            </LogoBlock>
                            <MobileMedia>
                                {mediaInfo.map((item, index) => {
                                    return (
                                        <MediaLinks key={`links-index-${index}`} href={`${item.link}`} target="_blank">
                                            <img src={item.icon} alt="icon" />
                                        </MediaLinks>
                                    );
                                })}
                            </MobileMedia>
                            <FooterInfoLinks>
                                {footerInfo.map((item, index) => {
                                    return (
                                        <LinkBlock key={`links-index-${index}`}>
                                            <LinksLogoBox>
                                                <LinksLogo src={item.icon} alt="icon" />
                                            </LinksLogoBox>
                                            <LinksInfo onClick={item.func} style={{ cursor: item.func ? 'pointer' : 'default' }}>
                                                {item.text}
                                            </LinksInfo>
                                        </LinkBlock>
                                    );
                                })}
                            </FooterInfoLinks>
                            <Media>
                                {mediaInfo.map((item, index) => {
                                    return (
                                        <MediaLinks key={`links-index-${index}`} href={`${item.link}`} target="_blank">
                                            <img src={item.icon} alt="icon" />
                                        </MediaLinks>
                                    );
                                })}
                            </Media>
                        </LinksLogoBlock>

                        <MainContentBlock>
                            {footerMainContent.map((item, index) => {
                                return (
                                    <MainContentLinks key={`main-index-${index}`}>
                                        <HeadOfMain>
                                            <MainLogos src={item.icon} alt="icon" />
                                            <MainContentTitle>{item.title}</MainContentTitle>
                                        </HeadOfMain>
                                        {item.text && <MainContentText>{item.text}</MainContentText>}
                                        {item.list !== '' ? (
                                            <UsefulList>
                                                {usefulLinks.map((item, index) => {
                                                    return (
                                                        <MainLinks key={`main-useful-index-${index}`} to={`${item.link}`}>
                                                            <UsefulLi>{item.title}</UsefulLi>
                                                        </MainLinks>
                                                    );
                                                })}
                                            </UsefulList>
                                        ) : (
                                            <></>
                                        )}
                                    </MainContentLinks>
                                );
                            })}
                        </MainContentBlock>
                    </MainPart>
                    <ProductionBlock>
                        <ProducedBy> © {year} Bullion Network. All Rights Reserved. </ProducedBy>
                    </ProductionBlock>
                </Container>
            ) : (
                <></>
            )}
        </Wrapper>
    );
};
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    background-color: #394985;
    justify-content: center;
    align-items: center;
    font-family: Inter, serif;
`;
const MobileMedia = styled.div`
    display: none;
    @media (max-width: 750px) {
        display: flex;
        align-items: center;
    }
`;

const Media = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 750px) {
        display: none;
    }
`;
const ProductionBlock = styled.div`
    font-family: Manrope, serif;
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    @media (max-width: 900px) {
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 750px) {
        margin-top: 20px;
    }
`;
const ProducedBy = styled.div`
    font-family: Inter, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: 900px) {
        font-size: 14px;
    }
    @media (max-width: 600px) {
        font-size: 12px;
        line-height: 17px;
        max-width: 165px;
    }
`;

const MainLinks = styled(Link)`
    font-family: Manrope, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #f8c882;
    text-decoration: none;
    @media (max-width: 900px) {
        font-size: 14px;
        line-height: 20px;
    }
`;
const MainLogos = styled.img`
    height: 25px;
`;
const LinksLogoBlock = styled.div`
    width: 100%;
    max-width: 320px;
    padding-right: 100px;
    @media (max-width: 900px) {
        max-width: 250px;
        padding-right: 25px;
    }
    @media (max-width: 750px) {
        max-width: calc(95vw);
        padding: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;
const UsefulLi = styled.li``;

const UsefulList = styled.ul`
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 200px);
    /* grid-template-rows: repeat(4, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-left: 20px;
    @media screen and (max-width: 600px) {
        column-gap: 20px;
        grid-template-columns: repeat(2, 100px);
    }
`;
const HeadOfMain = styled.div`
    display: flex;
    margin-bottom: 22px;
    align-items: center;
    @media (max-width: 600px) {
        margin-bottom: 10px;
    }
`;
const MainContentText = styled.div`
    max-width: 300px;
    width: 100%;
    font-family: Inter, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: white;
    margin-bottom: 10px;

    @media (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
        max-width: 100%;
    }
`;
const MainPart = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 750px) {
        flex-wrap: wrap;
    }
`;
const MainContentTitle = styled.div`
    /* font-family: Playfair Display, serif; */
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    margin-left: 19px;
    color: white;
    @media (max-width: 900px) {
        font-size: 14px;
        line-height: 20px;
    }
`;
const MainContentLinks = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 18px;

    @media (max-width: 900px) {
        margin-right: 19px;
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 1290px) {
        /* max-width: calc(30vw); */
    }
    @media (max-width: 600px) {
        /* width: 166px; */
        margin-right: 0;
        max-width: 100%;
    }
`;
const MainContentBlock = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 900px) {
        /* padding-left: 20px; */
        max-width: 75vw;
    }
    @media (max-width: 900px) {
        /* padding-left: 10px; */
    }

    @media (max-width: 900px) {
        flex-direction: column;
    }

    @media (max-width: 450px) {
        max-width: calc(95vw);
        width: 100%;
        div:nth-child(3) {
            width: 100%;
        }
    }
`;
const MediaLinks = styled.a`
    margin-right: 21px;
`;
const LinksInfo = styled.div`
    margin-left: 8px;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
    max-width: 298px;
`;
const FooterInfoLinks = styled.div`
    display: flex;

    flex-direction: column;
    margin: 40px 0 20px 0;
    @media (max-width: 900px) {
        /* margin: 20px; */
        width: 200px;
    }
    @media (max-width: 600px) {
        width: auto;
    }
`;
const LinkBlock = styled.div`
    display: flex;
    align-items: start;
    margin-bottom: 12px;
`;

const LinksLogo = styled.img`
    width: 24px;
    height: 24px;
`;
const LinksLogoBox = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
    margin: 0;
    padding: 40px 0 26px 0;
    max-width: 1290px;
    width: 100%;
    @media (max-width: 1290px) {
        padding: 19px 16px;
        max-width: calc(100vw - 33px);
    }
    @media (max-width: 900px) {
        padding: 16px;
    }
    @media (max-width: 600px) {
        padding: 11px;
        max-width: 100vw;
    }
`;
const LogoBlock = styled.a`
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 2;
    margin-right: 18px;
    margin-top: 9px;
`;
const Logo = styled.img`
    max-width: 206px;
    width: 100%;
    @media (max-width: 900px) {
        width: 110px;
    }
    @media (max-width: 600px) {
        width: 127px;
    }
`;
export default Footer;
