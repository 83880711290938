import { useEffect, useState } from 'react';

import * as firebase from 'firebase/app';
import {
    EmailAuthProvider,
    FacebookAuthProvider,
    GoogleAuthProvider,
    User,
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    reauthenticateWithCredential,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updatePassword,
    multiFactor,
    sendSignInLinkToEmail,
    getMultiFactorResolver,
    PhoneMultiFactorGenerator,
    PhoneAuthProvider,
    updatePhoneNumber,
    MultiFactorError,
    ApplicationVerifier,
    MultiFactorResolver,
    confirmPasswordReset,
    verifyBeforeUpdateEmail,
    applyActionCode,
} from 'firebase/auth';
import { useDispatch } from 'react-redux';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import { userAPI } from '../api/user/userApi';

const firebaseConfig = config.FIREBASE_CONFIG;

const app = firebase.initializeApp(firebaseConfig);

export const auth = getAuth(app);

const FirebaseService = {
    auth: {
        email: {
            signUp: async (email: string, password: string) => {
                try {
                    const { user } = await createUserWithEmailAndPassword(auth, email, password);
                    return user;
                } catch (error) {
                    console.error(error);
                }
            },
            signIn: async (email: string, password: string) => {
                try {
                    const userData = await signInWithEmailAndPassword(auth, email, password);
                    return userData;
                } catch (error: any) {
                    throw error;
                }
            },
            linkSignIn: async (email: string, password: string) => {
                try {
                    const actionCodeSettings = {
                        // URL you want to redirect back to. The domain (www.example.com) for this
                        // URL must be in the authorized domains list in the Firebase Console.
                        url: 'https://dev.bullionnetwork.com.au/login',
                        // This must be true.
                        handleCodeInApp: true,
                        iOS: {
                            bundleId: 'com.example.ios',
                        },
                        android: {
                            packageName: 'com.example.android',
                            installApp: true,
                            minimumVersion: '12',
                        },
                        dynamicLinkDomain: 'example.page.link',
                    };
                    return await sendSignInLinkToEmail(auth, email, actionCodeSettings);
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            },
            handleMFA: async (
                error: MultiFactorError,
                recaptchaVerifier: ApplicationVerifier,
                selectedIndex: number
            ): Promise<false | { verificationId: string; resolver: MultiFactorResolver } | void | 'too-many-attempts'> => {
                const resolver = getMultiFactorResolver(auth, error);

                if (resolver.hints[selectedIndex].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
                    const phoneInfoOptions = {
                        multiFactorHint: resolver.hints[selectedIndex],
                        session: resolver.session,
                    };

                    const phoneAuthProvider = new PhoneAuthProvider(auth);

                    try {
                        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
                        return { verificationId, resolver };
                    } catch (error: any) {
                        if (error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER' || error.code === 'auth/too-many-requests') {
                            return 'too-many-attempts';
                        }
                        if (error.message.split(' ').includes('(auth/too-many-requests).')) {
                            return 'too-many-attempts';
                        }
                        return false;
                    }
                }
            },
            handleMFASubmit: async (verificationMFA: { verificationId: string; resolver: MultiFactorResolver }, verificationCode: string) => {
                const { verificationId, resolver } = verificationMFA;

                const credentials = PhoneAuthProvider.credential(verificationId, verificationCode);

                const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credentials);

                try {
                    const result = await resolver.resolveSignIn(multiFactorAssertion);
                    return true;
                } catch (error: any) {
                    if (error.code === 'auth/invalid-verification-code') {
                        return 'invalidCode';
                    }
                    if (error.code === 'auth/code-expired') {
                        return 'sessionExpired';
                    }
                    if (error.code === 'auth/missing-code') {
                        return 'missingCode';
                    }
                    if (error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER' || error.code === 'auth/too-many-requests') {
                        return 'too-many-attempts';
                    }
                    return false;
                }
            },
            verifyIfUserMFA: async (user: User) => {
                const enrolledFactors = multiFactor(user).enrolledFactors;
                return enrolledFactors.length > 0;
            },
            verifyPhoneNumber: async (phoneNumber: string, recaptchaVerifier: ApplicationVerifier) => {
                const user = auth.currentUser;
                if (!user) {
                    return false;
                }

                const session = await multiFactor(user).getSession();
                const phoneInfoOptions = {
                    phoneNumber,
                    session,
                };

                const phoneAuthProvider = new PhoneAuthProvider(auth);

                try {
                    return await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
                } catch (error: any) {
                    if (error.message === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN' || error.code === 'auth/requires-recent-login') {
                        return 'relogin';
                    }
                    if (error.code === 'auth/code-expired') {
                        return 'codeExpired';
                    }
                    if (error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER' || error.code === 'auth/too-many-requests') {
                        return 'too-many-attempts';
                    }
                    return false;
                }
            },
            enrollMFA: async (verificationCode: string, verificationCodeId: string) => {
                const user = auth.currentUser;
                if (!user) {
                    return false;
                }

                const phoneAuthCredential = PhoneAuthProvider.credential(verificationCodeId, verificationCode);
                const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);

                try {
                    await multiFactor(user).enroll(multiFactorAssertion, 'Personal Phone Number');
                    await userAPI.activatePhone();
                    return true;
                } catch (error: any) {
                    if (error.code === 'auth/invalid-verification-code') {
                        return 'invalidCode';
                    }
                    if (error.code === 'auth/missing-code') {
                        return 'missingCode';
                    }
                    if (error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER' || error.code === 'auth/too-many-requests') {
                        return 'too-many-attempts';
                    }
                    return false;
                }
            },
            resetPassword: async (oobCode: string, newPassword: string) => {
                try {
                    await confirmPasswordReset(auth, oobCode, newPassword);
                    return true;
                } catch (error) {
                    return false;
                }
            },
            verifyEmail: async (oobCode: string) => {
                try {
                    await applyActionCode(auth, oobCode);
                    return true;
                } catch (error: any) {
                    if (error.code === 'auth/invalid-action-code') {
                        return 'invalidCode';
                    }
                    if (error.code === 'auth/expired-action-code') {
                        return 'invalidCode';
                    }
                    if (error.code === 'auth/user-disabled') {
                        return 'userDisabled';
                    }
                    if (error.code === 'auth/user-not-found') {
                        return 'userNotFound';
                    }
                    return false;
                }
            },
        },
        signOut: async () => {
            try {
                await signOut(auth);
                return true;
            } catch (error) {
                console.error(error);
            }
        },
        getAccessToken: () => {
            const token = auth.currentUser?.getIdToken();
            return token;
        },
        changePassword: async (oldPassword: string, newPassword: string) => {
            const user = auth.currentUser;
            try {
                await reauthenticate(oldPassword);
                if (user === null) return console.log(`User is null`);
                await updatePassword(user, newPassword);
            } catch (error: any) {
                console.error(error);
            }
        },
        sendVerificationEmail: async (user: User) => {
            try {
                const mail = await sendEmailVerification(user);
            } catch (error) {
                console.error(error);
            }
        },
    },
};

type AuthProviderProps = {
    children: any;
    onLogin?: () => void;
    onLogout?: () => void;
    setIsVerified: (verified: { phone: boolean; mail: boolean }) => void;
};

export const AuthProvider = (props: AuthProviderProps) => {
    const { children, onLogin = () => {}, onLogout = () => {}, setIsVerified = () => {} } = props;

    const [initialized, setInitialized] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, (firebaseUser) => {
            if (firebaseUser) {
                // localStorage.removeItem('behalf');
                if (firebaseUser?.emailVerified === false || !firebaseUser?.phoneNumber) {
                    if (!firebaseUser?.phoneNumber && firebaseUser?.emailVerified === false) {
                        setIsVerified({ phone: false, mail: false });
                    }
                    if (firebaseUser?.phoneNumber && firebaseUser?.emailVerified === false) {
                        setIsVerified({ phone: true, mail: false });
                    }
                    if (!firebaseUser?.phoneNumber && firebaseUser?.emailVerified === true) {
                        setIsVerified({ phone: false, mail: true });
                    }
                    // if (window.location.pathname !== 'account-actions') {
                    //     navigate('/verification');
                    // }
                } else {
                    setIsVerified({
                        mail: true,
                        phone: true,
                    });
                }
            }

            if (firebaseUser) {
                onLogin();
            } else {
                onLogout();
                // navigate('/');
            }
            setInitialized(true);
        });
    }, []);

    if (!initialized) {
        return null;
    }

    return children;
};

export const reauthenticate = async (currentPassword: string) => {
    try {
        const user = auth.currentUser;
        if (user === null || user.email === null) return console.log(`User is null`);

        const cred = EmailAuthProvider.credential(user.email, currentPassword);
        return reauthenticateWithCredential(user, cred);
    } catch (error) {
        console.error(error);
    }
};

export default FirebaseService;
