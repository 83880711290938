import React, { useEffect, useState } from 'react';
import TextFields from '../../ui/TextFields';
import { FIELD_HEIGHT } from './SignUp';
import StyledButton from '../../ui/StyledButton';
import styled from 'styled-components';
import { useUserActions } from '../../hooks/useActions';
import Timer from '../../ui/Timer';
import { useTypedSelector } from '../../hooks/useSelector';
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { MultiFactorResolver } from 'firebase/auth';
import FirebaseService from '../../services/firebase-service';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../store/actions/snackbarActions';
import { SnakeTypes } from '../../ui/components/modals/SnakeBar';

interface IEnterCodeStep {
    email: string;
    password: string;
}

const EnterCode: React.FC<IEnterCodeStep> = ({ email, password }) => {
    const [currentValue, setCurrentValue] = useState<string>('');

    const recaptcha = useRecaptcha('sign-in');
    const [verificationId, setVerificationId] = useState<string>();

    const [resolver, setResolver] = useState<MultiFactorResolver>();

    const dispatch = useDispatch();

    const { loading, multiFactorError } = useTypedSelector((state) => state.userReducer);
    const { logInSecondStep, logInFirstStep, closeSecondStepLogIn } = useUserActions();
    const [timerTime, setTimerTime] = useState(120);
    const [isShowTimer, setIsShowTimer] = useState(true);
    const [isShowResendButton, setIsShowResendButton] = useState(false);

    const sendCode = () => {
        logInFirstStep({ email, password, type: 'email' });
        setTimerTime(120);
        setIsShowTimer(false);
        setTimeout(() => setIsShowTimer(true), 1);
    };

    async function verify() {
        if (multiFactorError && recaptcha) {
            const data = await FirebaseService.auth.email.handleMFA(multiFactorError, recaptcha, 0);
            if (!data) {
            } else if (data === 'too-many-attempts') {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Too many attempts, try again later', ms: 4000 }));
                dispatch(closeSecondStepLogIn());
            } else {
                const { verificationId, resolver } = data;
                setVerificationId(verificationId);
                setResolver(resolver);
            }
            setIsShowResendButton(false);
            setIsShowTimer(true);
        }
    }

    const confirmHandler = async () => {
        if (resolver && verificationId && currentValue.length > 0) {
            const response = await FirebaseService.auth.email.handleMFASubmit({ verificationId, resolver }, currentValue);
            if (response === 'invalidCode') {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Invalid code', ms: 4000 }));
                return;
            }
            if (response === 'missingCode') {
                dispatch(showSnackbar({ type: SnakeTypes.warning, content: 'Code is missing', ms: 4000 }));
                return;
            } else if (response === 'sessionExpired') {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Code expired', ms: 4000 }));
                return;
            } else if (response === 'too-many-attempts') {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Too many attempts', ms: 4000 }));
                return;
            }
            if (response === false) {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Something went wrong', ms: 4000 }));
                return;
            }
        }
    };

    useEffect(() => {
        verify();
    }, [multiFactorError, recaptcha]);

    return (
        <>
            <ModalTitle>Enter The Code</ModalTitle>
            <SubText>We have sent an SMS with a code to log in to your account</SubText>
            <FieldContainer>
                <TextFields
                    value={currentValue}
                    type={'text'}
                    height={FIELD_HEIGHT}
                    placeholder={'Code'}
                    onChange={(e) => {
                        const currentValue = e.target.value;
                        setCurrentValue(currentValue);
                    }}
                />
            </FieldContainer>
            {isShowTimer && (
                <SendCodeButton>
                    Send the code in{' '}
                    {isShowTimer && (
                        <Timer
                            initSeconds={timerTime}
                            handleFinish={() => {
                                setIsShowTimer(false);
                                setIsShowResendButton(true);
                            }}
                        />
                    )}
                </SendCodeButton>
            )}

            {isShowResendButton && (
                <ButtonContainer>
                    <StyledButton height={'50px'} onClick={sendCode} disabled={loading}>
                        Resend
                    </StyledButton>
                </ButtonContainer>
            )}
            <ButtonContainer>
                <StyledButton height={'50px'} onClick={confirmHandler} disabled={loading}>
                    Confirm
                </StyledButton>
            </ButtonContainer>
        </>
    );
};

const ModalTitle = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    text-align: center;

    color: #0b1126;

    margin-bottom: 5px;
`;

const FieldContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
`;
const SubText = styled.div`
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    color: #828282;
`;

const ButtonContainer = styled.div`
    margin-top: 20px;
`;

const SendCodeButton = styled.div`
    text-align: center;
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #5b73cf;
    text-decoration: none;
    display: flex;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
`;

export default EnterCode;
