import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { checkIsAgreeWithPolicy, checkPassword, FIELD_TYPE, validateField } from '../../helpers/registerValidation';
import StyledButton from '../../ui/StyledButton';
import TextFields from '../../ui/TextFields';
import CheckboxComponent from '../../ui/CheckboxComponent';
import { useUserActions } from '../../hooks/useActions';
import { formJuristicUser, formNaturalUser, formWholesalerUser } from '../../helpers/formObjectRegistration';
import LogIn from './LogIn';
import { useTypedSelector } from '../../hooks/useSelector';
import PasswordField from '../../ui/PasswordField';
import { IErrors } from '../../api/user/userTypes';
import EnterCodeRegister from './EnterCodeRegister';
import { IModalElement } from '../../ui/components/modals/Modal';
import SignUpNotification from '../../ui/components/modals/SignUpNotification';
import { useDispatch } from 'react-redux';
import { clearRequest } from '../../store/actions/userActions';
import { Link } from 'react-router-dom';

//Changed file name 2

export const FIELD_HEIGHT = '56px';

enum PERSON_TYPES {
    naturalPerson = 'Natural person',
    juristicPerson = 'Juristic person',
    wholesaler = 'Wholesaler',
}

const BASIC_FIELDS: IBaseField[] = [
    {
        id: 'firstName',
        placeholder: 'First name*',
        altPlaceholder: 'ABN or ACN*',
        type: 'text',
        altType: 'company_code',
    },
    {
        id: 'lastName',
        placeholder: 'Last name*',
        altPlaceholder: 'Company name*',
        type: 'text',
        altType: 'companyName',
    },
    { id: 'email', placeholder: 'Email*', type: 'text', altType: 'email' },
    { id: 'phone', placeholder: 'Phone*', type: 'tel', altType: 'phone' },
    { id: 'website', placeholder: 'Website', type: 'text' },
];

const ADDRESS_FIELDS: IBaseField[] = [
    {
        id: 'residentialAddress',
        placeholder: 'Residential address*',
        altPlaceholder: 'Business registration address*',
        altType: 'businessRegistrationAddress',
        type: 'text',
    },
    { id: 'deliveryAddress', placeholder: 'Delivery address*', type: 'text' },
];

interface IBaseField {
    id: string;
    placeholder: string;
    altPlaceholder?: string;
    type: string;
    altType?: string;
}

interface IErrorState {
    firstName: string;
    lastName: string;
    company_code: string;
    companyName: string;
    email: string;
    phone: string;
    website: string | undefined;
    residentialAddress: string;
    deliveryAddress: string;
    actualAddress: string | undefined;
    password: string;
    confirmPassword: string;
    isAgreeWithPolicy: string;
}

export interface IUserDataSignUp extends Omit<IErrorState, 'isAgreeWithPolicy'> {
    currentPersonType: PERSON_TYPES;
    isAgreeWithPolicy: boolean;
}

enum currentStateEnum {
    signUp,
    logIn,
    successRegister,
    // signUpStepTwo,
}

const ALLOW_CODE_NUMBERS = /[23478]/;

const SignUp: React.FC<IModalElement> = ({ closeModal }) => {
    const dispatch = useDispatch();
    const { error, request, loading } = useTypedSelector((state) => state.userReducer);
    const { registrationNaturalUser, registrationJuristicWholesalerUser, registerFirebaseUser, clearMessage } = useUserActions();
    const [currentState, setCurrentState] = useState<currentStateEnum>(currentStateEnum.signUp);

    const [userData, setUserData] = useState<IUserDataSignUp>({
        currentPersonType: PERSON_TYPES.naturalPerson,
        firstName: '',
        lastName: '',
        company_code: '',
        companyName: '',
        email: '',
        phone: '',
        website: undefined,
        residentialAddress: '',
        deliveryAddress: '',
        actualAddress: undefined,
        password: '',
        confirmPassword: '',
        isAgreeWithPolicy: false,
    });
    const [errorState, setErrorState] = useState<IErrorState>({
        firstName: '',
        lastName: '',
        company_code: '',
        companyName: '',
        email: '',
        phone: '',
        website: '',
        residentialAddress: '',
        deliveryAddress: '',
        actualAddress: '',
        password: '',
        confirmPassword: '',
        isAgreeWithPolicy: '',
    });
    const [showActualAddressField, setShowActualAddressField] = useState(false);
    const [serverError, setServerError] = useState<IErrors[] | undefined>(undefined);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if ((error as any) !== 'You need authorization') {
            if (typeof error === 'string') {
                // @ts-ignore
                if (error.split(' ').includes('another')) {
                    setErrorState((e) => ({
                        ...e,
                        email: 'Choose another email',
                    }));
                } else if (error.split(' ').includes('phone')) {
                    setErrorState((e) => ({
                        ...e,
                        phone: 'Choose another phone',
                    }));
                } else if (error.split(' ').includes('banned')) {
                    setErrorState((e) => ({
                        ...e,
                        email: 'This email is banned from this site',
                    }));
                } else {
                    setServerError([
                        {
                            property: '',
                            code: error,
                            trace: [],
                        },
                    ]);
                }
            } else {
                setServerError(error);
            }
        }
    }, [error]);

    useEffect(() => {
        if (request) {
            //localStorage.setItem("request", request)
            setCurrentState(currentStateEnum.successRegister);
        }
    }, [request]);

    useEffect(() => {
        /*        if (localStorage.getItem("request")){
            setCurrentState(currentStateEnum.signUpStepTwo)  //TODO можно сделать активацию аккаунта по телефону позжу, но для этого требуется возможность повторно запросить код
        }*/
        return () => {
            clearMessage();
        };
    }, []);

    //const [phone, setPhone] = useState('')

    const inputPhoneNumber = (value: string, setPhone: (arg1: string) => void) => {
        let val = value.replace(/[ \D]/gm, '');

        let firstCode = val.substring(0, 2);
        let userPhone = value;

        userPhone = (() => {
            const env = process.env.REACT_APP_ENV;
            if (env === 'prod') {
                return `+61 ${val.substring(2, 3)} ${val.substring(3, 7)} ${val.substring(7, 11)}`;
            }
            if (env === 'qa') {
                return `+380 ${val.substring(3, 5)} ${val.substring(5, 8)} ${val.substring(8, 12)}`;
            }
            if (env === 'dev') {
                return `+421 ${val.substring(3, 5)} ${val.substring(5, 8)} ${val.substring(8, 12)}`;
            }
            if (env === 'uat') {
                return `+61 ${val.substring(2, 3)} ${val.substring(3, 7)} ${val.substring(7, 11)}`;
            }
            return `+421 ${val.substring(3, 5)} ${val.substring(5, 8)} ${val.substring(8, 12)}`;
        })();

        userPhone = userPhone.replace(/[a-zA-Zа-яА-Я]/, '');

        setPhone(userPhone.trim());
    };

    const renderBaseField = useMemo(
        () =>
            BASIC_FIELDS.map((field) => {
                let placeholder;
                let currentType: string;
                switch (field.id) {
                    case 'firstName':
                    case 'lastName': {
                        const isNaturalPerson = userData.currentPersonType === PERSON_TYPES.naturalPerson;
                        placeholder = isNaturalPerson ? field.placeholder : field.altPlaceholder;
                        currentType = isNaturalPerson ? field.id : (field.altType as string);
                        break;
                    }
                    default: {
                        placeholder = field.placeholder;
                        currentType = field.id;
                    }
                }
                const value = userData[currentType as keyof IErrorState];

                return (
                    ((field.id === 'website' && userData.currentPersonType !== PERSON_TYPES.naturalPerson) || field.id !== 'website') && (
                        <TextFields
                            key={'field_type_' + field.id}
                            value={value ? (value as string) : ''}
                            placeholder={placeholder}
                            height={FIELD_HEIGHT}
                            type={field.type}
                            onClick={() => {
                                if (field.id === 'phone') {
                                    if (userData.phone === '') {
                                        // setUserData((u) => ({ ...u, [currentType]: "+614" }));
                                        setUserData((u) => ({ ...u, [currentType]: '' }));
                                    }
                                }
                            }}
                            onChange={(e) => {
                                const value = e.target.value;
                                const fieldType = FIELD_TYPE[currentType as keyof typeof FIELD_TYPE];

                                if (field.id === 'phone') {
                                    inputPhoneNumber(value, (phone) => {
                                        setUserData((u) => ({ ...u, [currentType]: phone }));
                                    });
                                    //setUserData(u => ({...u, [currentType]: value}))
                                } else {
                                    setServerError(undefined);
                                    if (field.id === 'website') {
                                        setUserData((u) => ({ ...u, [currentType]: value.length === 0 ? undefined : value }));
                                    } else setUserData((u) => ({ ...u, [currentType]: value }));
                                }

                                let errorContent: string;

                                if (value.length === 0) {
                                    errorContent = '';
                                } else {
                                    errorContent = validateField(value, fieldType);
                                }
                                setErrorState((e) => ({
                                    ...e,
                                    [currentType]: errorContent,
                                }));
                                setHasError(false);
                            }}
                            textError={
                                serverError
                                    ? serverError?.find(
                                          (item) =>
                                              item.property === (userData.currentPersonType === PERSON_TYPES.naturalPerson ? field.id : field.altType)
                                      )?.code
                                    : errorState[currentType as keyof IErrorState]
                            }
                            onKeyDown={(e) => {
                                if (e.code === 'Enter') {
                                    registerUser();
                                }
                            }}
                        />
                    )
                );
            }),
        [userData, errorState, serverError]
    );

    const renderAddresses = useMemo(
        () =>
            ADDRESS_FIELDS.map((address) => {
                let placeholder;
                let addressType = address.id;
                if (address.id !== 'residentialAddress') {
                    placeholder = address.placeholder;
                    //addressType = address.id
                } else {
                    const isNaturalPerson = userData.currentPersonType === PERSON_TYPES.naturalPerson;
                    placeholder = isNaturalPerson ? address.placeholder : address.altPlaceholder;
                    //addressType = isNaturalPerson ? address.id : address.altType as string
                }

                return (
                    <TextFields
                        key={'field_' + address.id}
                        value={userData[address.id as keyof IErrorState] as string}
                        height={FIELD_HEIGHT}
                        placeholder={placeholder}
                        type={address.type}
                        onChange={(e) => {
                            const value = e.target.value;
                            const fieldType = FIELD_TYPE[addressType as keyof typeof FIELD_TYPE];
                            setUserData((u) => ({ ...u, [address.id]: value }));
                            let errorContent: string;
                            if (value.length === 0) {
                                errorContent = '';
                            } else {
                                errorContent = validateField(value, fieldType);
                            }
                            setErrorState((e) => ({
                                ...e,
                                [addressType]: errorContent,
                            }));
                            setHasError(false);
                        }}
                        textError={
                            serverError
                                ? serverError.find((item) => item.property === addressType)?.code
                                : errorState[addressType as keyof IErrorState]
                        }
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                registerUser();
                            }
                        }}
                    />
                );
            }),
        [userData, errorState]
    );

    const renderPersonTypes = useMemo(
        () =>
            Object.values(PERSON_TYPES).map((person) => (
                <PersonType
                    key={'type_' + person}
                    style={{ border: userData.currentPersonType === person ? '' : 'none' }}
                    show={userData.currentPersonType === person}
                    onClick={() => {
                        let newErrorState = JSON.parse(JSON.stringify(errorState));
                        for (let key in errorState) {
                            newErrorState[key as keyof IErrorState] = '';
                        }
                        setErrorState(newErrorState);
                        setUserData((u) => ({ ...u, currentPersonType: person }));
                        setHasError(false);
                    }}
                >
                    {person}
                </PersonType>
            )),
        [userData.currentPersonType, errorState]
    );

    const registerUser = () => {
        const errorInPassword = validateField(userData.confirmPassword, FIELD_TYPE.confirmPassword);
        const isNaturalPerson = userData.currentPersonType === PERSON_TYPES.naturalPerson;
        let obj: IErrorState = {
            firstName: isNaturalPerson ? validateField(userData.firstName, FIELD_TYPE.firstName) : '',
            lastName: isNaturalPerson ? validateField(userData.lastName, FIELD_TYPE.lastName) : '',
            company_code: !isNaturalPerson ? validateField(userData.company_code, FIELD_TYPE.abnAcn) : '',
            companyName: !isNaturalPerson ? validateField(userData.companyName, FIELD_TYPE.companyName) : '',
            email: validateField(userData.email, FIELD_TYPE.email),
            phone: validateField(userData.phone, FIELD_TYPE.phone),
            website: userData.website ? validateField(userData.website, FIELD_TYPE.website) : '',
            password: validateField(userData.password, FIELD_TYPE.password),
            confirmPassword: errorInPassword.length > 0 ? errorInPassword : checkPassword(userData.password, userData.confirmPassword),
            residentialAddress: isNaturalPerson
                ? validateField(userData.residentialAddress, FIELD_TYPE.residentialAddress)
                : validateField(userData.residentialAddress, FIELD_TYPE.businessRegistrationAddress),
            deliveryAddress: validateField(userData.deliveryAddress, FIELD_TYPE.deliveryAddress),
            actualAddress: showActualAddressField
                ? validateField(userData.actualAddress ? userData.actualAddress : '', FIELD_TYPE.actualAddress)
                : '',
            isAgreeWithPolicy: checkIsAgreeWithPolicy(userData.isAgreeWithPolicy),
        };

        if (Object.values(obj).some((x) => x !== '')) {
            setErrorState(obj);
            setHasError(true);
        } else {
            // if (userData.currentPersonType === PERSON_TYPES.naturalPerson) {
            //     registrationNaturalUser(formNaturalUser(userData));
            // } else {
            //     if (userData.currentPersonType === PERSON_TYPES.juristicPerson) {
            //         registrationJuristicWholesalerUser(formJuristicUser(userData));
            //     } else {
            //         registrationJuristicWholesalerUser(formWholesalerUser(userData));
            //     }
            // }
            registerFirebaseUser(formNaturalUser(userData));
            // setCurrentState(currentStateEnum.successRegister);
        }
    };

    const closeSignUpModal = () => {
        // dispatch(setRequestMessage({ request: '' }));
        dispatch(clearRequest());
    };

    const SignUpData = () => {
        return (
            <>
                <ModalTitle>Sign Up</ModalTitle>
                {/* <WhoAmI>{renderPersonTypes}</WhoAmI> */}
                {/* <Slider /> */}
                <FieldContainer>
                    {renderBaseField}
                    <PasswordField
                        placeholder={'Password*'}
                        setPasswordData={(value) => {
                            const password = value;
                            setUserData((u) => ({ ...u, password }));
                            let errorContent: string;
                            if (value.length === 0) {
                                errorContent = '';
                            } else {
                                errorContent = validateField(password, FIELD_TYPE.password);
                            }
                            setErrorState((e) => ({
                                ...e,
                                password: errorContent,
                            }));
                            setHasError(false);
                        }}
                        password={userData.password}
                        errorPassword={errorState.password}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                registerUser();
                            }
                        }}
                    />
                    <PasswordField
                        placeholder={'Confirm password*'}
                        setPasswordData={(value) => {
                            setUserData((u) => ({ ...u, confirmPassword: value }));
                            let errorContent: string;
                            if (value.length === 0) {
                                errorContent = '';
                            } else {
                                errorContent = validateField(value, FIELD_TYPE.confirmPassword);
                            }
                            setErrorState((e) => ({
                                ...e,
                                confirmPassword: errorContent.length > 0 ? errorContent : checkPassword(userData.password, value),
                            }));
                            setHasError(false);
                        }}
                        password={userData.confirmPassword}
                        errorPassword={errorState.confirmPassword}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                registerUser();
                            }
                        }}
                    />
                </FieldContainer>
                <Description>Address format: house number, street name, district name, state, zip code, town</Description>
                <FieldContainer>{renderAddresses}</FieldContainer>
                <CheckBoxFieldContainer>
                    <CheckBoxField>
                        <CheckboxComponent
                            height={'18px'}
                            width={'18px'}
                            value={showActualAddressField}
                            onClick={(e) => {
                                setShowActualAddressField(!showActualAddressField);
                                setUserData((u) => ({ ...u, actualAddress: undefined }));
                                setErrorState((e) => ({
                                    ...e,
                                    actualAddress: '',
                                }));
                                setHasError(false);
                            }}
                        />
                        <TextNearCheckBox
                            onClick={(e) => {
                                setShowActualAddressField(!showActualAddressField);
                                setUserData((u) => ({ ...u, actualAddress: undefined }));
                                setErrorState((e) => ({
                                    ...e,
                                    actualAddress: undefined,
                                }));
                                setHasError(false);
                            }}
                        >
                            the actual address is different from the residential address
                        </TextNearCheckBox>
                    </CheckBoxField>
                    {showActualAddressField && (
                        <TextFields
                            value={userData.actualAddress ? userData.actualAddress : ''}
                            type={'text'}
                            onChange={(e) => {
                                const actualAddress = e.target.value;
                                setUserData((u) => ({ ...u, actualAddress }));
                                let errorContent: string;
                                if (actualAddress.length === 0) {
                                    errorContent = '';
                                } else {
                                    errorContent = validateField(actualAddress, FIELD_TYPE.actualAddress);
                                }
                                setErrorState((e) => ({
                                    ...e,
                                    actualAddress: errorContent,
                                }));
                                setHasError(false);
                            }}
                            height={FIELD_HEIGHT}
                            placeholder={'Actual address'}
                            textError={errorState.actualAddress}
                            onKeyDown={(e) => {
                                if (e.code === 'Enter') {
                                    registerUser();
                                }
                            }}
                        />
                    )}
                    <div>
                        <CheckBoxField>
                            <CheckboxComponent
                                height={'18px'}
                                width={'18px'}
                                value={userData.isAgreeWithPolicy}
                                onClick={(e) => {
                                    setErrorState((e) => ({ ...e, isAgreeWithPolicy: '' }));
                                    setUserData((u) => ({ ...u, isAgreeWithPolicy: !userData.isAgreeWithPolicy }));
                                    setHasError(false);
                                }}
                            />
                            <TextNearCheckBox>
                                <span
                                    onClick={(e) => {
                                        setErrorState((e) => ({ ...e, isAgreeWithPolicy: '' }));
                                        setUserData((u) => ({ ...u, isAgreeWithPolicy: !userData.isAgreeWithPolicy }));
                                        setHasError(false);
                                    }}
                                >
                                    I agree with{' '}
                                </span>
                                <LinkPolicy to={'/privacy_policy'}>
                                    Privacy Policy, Terms & Conditions
                                </LinkPolicy>
                            </TextNearCheckBox>
                        </CheckBoxField>
                        {errorState.isAgreeWithPolicy !== '' && <ErrorText>{errorState.isAgreeWithPolicy}</ErrorText>}
                    </div>
                </CheckBoxFieldContainer>
                <ErrorWrap>
                    {error && error !== 'Unauthorized' && (
                        <div
                            style={{
                                color: '#D92E23',
                                fontSize: 16,
                                paddingBottom: 10,
                                marginTop: 0,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            {error}
                        </div>
                    )}
                </ErrorWrap>
                <ButtonContainer>
                    <StyledButton
                        disabled={loading || hasError}
                        height={'50px'}
                        onClick={() => {
                            setCurrentState(currentStateEnum.signUp);
                            registerUser();
                        }}
                    >
                        Sign Up
                    </StyledButton>
                </ButtonContainer>
                <HaveAccount>
                    Already have an account?{' '}
                    <LinkLogIn
                        onClick={() => {
                            setCurrentState(currentStateEnum.logIn);
                        }}
                    >
                        Log In
                    </LinkLogIn>
                </HaveAccount>
            </>
        );
    };

    return (
        <>
            {currentState === currentStateEnum.signUp && SignUpData()}
            {currentState === currentStateEnum.logIn && <LogIn />}
            {currentState === currentStateEnum.successRegister && <SignUpNotification closeModal={closeSignUpModal} />}
            {/* {currentState === currentStateEnum.signUpStepTwo && <EnterCodeRegister closeModal={closeModal} />} */}
        </>
    );
};

const ModalTitle = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    text-align: center;
    margin-bottom: 5px;

    color: #0b1126;
`;

const WhoAmI = styled.div`
    display: flex;
    justify-content: space-between;
`;

interface ICurrentPerson {
    show: boolean;
}

const PersonType = styled.p<ICurrentPerson>`
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-bottom: ${(props) => (props.show ? '4px solid #0B1126' : 'none')};
    color: #0b1126;
    width: 120px;
    height: 33px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 5px 5px 5px;
    @media (max-width: 460px) {
        text-align: center;
        font-size: 14px;
        line-height: 17px;
    }
`;

const Slider = styled.div`
    margin-top: -20px;
    border-bottom: 4px solid #9fabd9;
    @media (max-width: 460px) {
        margin-top: -18px;
    }
`;

const FieldContainer = styled.div`
    display: flex;
    gap: 21px;
    flex-direction: column;
    margin-top: 25px;
`;

const Description = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin-top: 10px;
    margin-bottom: -10px;
    display: flex;
    align-items: center;

    color: #0b1126;
`;

const CheckBoxFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
`;

const CheckBoxField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    gap: 6px;
`;

const TextNearCheckBox = styled.div`
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    color: #0b1126;
    @media (max-width: 460px) {
        line-height: 17px;
    }
    @media (max-width: 460px) {
        line-height: 14px;
        font-size: 11px;
    }
`;

const LinkPolicy = styled(Link)`
    font-size: 14px;
    line-height: 24px;

    text-decoration: none;
    color: #9fabd9;

    padding-left: 3px;

    @media (max-width: 460px) {
        line-height: 17px;
    }

    @media (max-width: 460px) {
        line-height: 14px;
        font-size: 11px;
    }
`;

const ButtonContainer = styled.div`
    margin-top: 26px;
`;

const HaveAccount = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 18px;
    color: #0b1126;
`;

const LinkLogIn = styled.a`
    color: #f8c882;
    text-decoration: none;
    cursor: pointer;
`;

const ErrorText = styled.div`
    color: #d92e23;
    font-size: 14px;
    padding-left: 10px;
    padding-top: 2px;
`;

const ErrorWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export default SignUp;
