import React from 'react';
import styled from 'styled-components';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

const center = [-37.81517825513134, 144.96619541980508];

const MapboxOffice = ({ width, height }) => {
    return (
        <MapContainer style={{ width: width, height: height, zIndex: 1 }} center={center} zoom={18}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
            />
            <Marker position={center}></Marker>
        </MapContainer>
    );
};

const Wrapper = styled.div`
    max-width: 960px;
    width: 100%;
    @media (max-width: 900px) {
        max-width: 70vw;
    }
    @media (max-width: 600px) {
        box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.22);
        max-width: 100vw;
    }
`;
export default MapboxOffice;
