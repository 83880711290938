import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextFields from '../../TextFields';
import { FIELD_HEIGHT } from '../../../components/auth/SignUp';
import StyledButton from '../../StyledButton';
import { FIELD_TYPE, validateField } from '../../../helpers/registerValidation';
import { useUserActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useSelector';
import CheckYourEmail from '../../components/modals/CheckYourEmail';

interface IResetPass {
    closeModal?: () => void;
}

const ResetPassword: React.FC<IResetPass> = ({ closeModal }) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const { error, loading, message } = useTypedSelector((state) => state.userReducer);
    const { restorePasswordSessionCreate, clearMessage, clearUserError } = useUserActions();

    const [localError, setLocalError] = useState(error);
    const [localMessage, setLocalMessage] = useState(message);

    const resetPassword = () => {
        const value = validateField(email, FIELD_TYPE.email);
        if (value === '') {
            restorePasswordSessionCreate(email);
        } else {
            setEmailError(value);
        }
    };

    useEffect(() => {
        setLocalError(error);
    }, [error]);

    useEffect(() => {
        setLocalMessage(message);
    }, [message]);

    useEffect(() => {
        return () => {
            clearMessage();
            clearUserError();
        };
    }, []);

    const resetPart = () => (
        <>
            <ModalTitle>Reset Password</ModalTitle>
            <Description>You will receive an email with a link to reset your password</Description>
            <FieldContainer>
                <TextFields
                    value={email}
                    type={'email'}
                    height={FIELD_HEIGHT}
                    placeholder={'Email'}
                    onChange={(e) => {
                        const currentValue = e.target.value;
                        setEmail(currentValue);
                        setEmailError(validateField(currentValue, FIELD_TYPE.email));
                        setLocalError(undefined);
                    }}
                    textError={emailError}
                />
            </FieldContainer>
            <ErrorWrap>
                {localError && (
                    <div
                        style={{
                            color: '#D92E23',
                            fontSize: 14,
                            paddingBottom: 0,
                            marginTop: 10,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        {!localError[0].code ? (localError as any) : localError[0].code}
                    </div>
                )}
            </ErrorWrap>
            <ButtonContainer>
                <StyledButton height={'50px'} onClick={resetPassword} disabled={!!localError || !!emailError || loading}>
                    Send Instructions
                </StyledButton>
            </ButtonContainer>
        </>
    );

    return <>{!localMessage ? resetPart() : <CheckYourEmail description={'We have sent a password recover instructions to your email'} />}</>;
};

const ErrorWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const ModalTitle = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    text-align: center;

    color: #0b1126;

    margin-bottom: 5px;
`;

const FieldContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 10px;
`;

const ButtonContainer = styled.div`
    margin-top: 20px;
`;

const Description = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: #828282;
    margin: 17px 0;
`;

export default ResetPassword;
