import {IErrors} from "../../api/user/userTypes";
import {IMetalPrices} from "../../api/metal/metalTypes";
import {metalsAction} from "../../store/types/metalTypes";
import {MetalsActionTypes} from "../../store/constants/metalsConstants";

interface IMetalState {
    error: IErrors[] | undefined
    loading: boolean
    currentCurrency: string
    currentMetalPrices: IMetalPrices[]
    apiStatus: boolean
}

const initialState: IMetalState = {
    currentCurrency: "AUD",
    error: undefined,
    loading: false,
    currentMetalPrices: [],
    apiStatus: false
}

const startLoading = (state: IMetalState) => {
    return {...state, loading: true, error: undefined}
}

const stopErrorLoading = (state: IMetalState, error: IErrors[]) => {
    return {...state, loading: false, error: error}
}


const metalReducer = (state= initialState, action: metalsAction):IMetalState => {
    switch (action.type) {
        case MetalsActionTypes.START_METAL_ACTION:{
            return startLoading(state)
        }
        case MetalsActionTypes.ERROR_METAL_ACTION:{
            return stopErrorLoading(state, action.payload)
        }

        case MetalsActionTypes.GET_METAL_PRICES:{
            return {
                ...state,
                currentMetalPrices: action.payload.prices,
                currentCurrency: action.payload.currency
            }
        }

        case MetalsActionTypes.GET_METAL_API_STATUS:
            return {
                ...state,
                apiStatus: action.payload.status
            }

        default:{
            return state
        }
    }
};

export default metalReducer;
