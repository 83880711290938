import {
    IClearUserError,
    IClearUserMessage,
    IClearUserRequest,
    IClearUserState,
    IErrorUserAction,
    IFinishUserAction,
    IFirstStepLoginSuccess,
    IGetResponseMessage,
    IInitialized,
    IRegistrationSuccess,
    ISecondStepLoginSuccess,
    IStartUserAction,
    userAction,
} from '../../store/types/userTypes';
import { UserActionTypes } from '../../store/constants/userConstants';
import {
    IErrors,
    IRequestSignUpJuristicWholesalerUser,
    IRequestSignUpPrivateUser,
    IResponseMessage,
    ISignUpResponse,
    IUserData,
    IUserDataUpdate,
    IUserLogin,
} from '../../api/user/userTypes';
import { AnyAction, Dispatch } from 'redux';
import { userAPI } from '../../api/user/userApi';
import axios, { AxiosError } from 'axios';
import { showSnackbar } from './snackbarActions';
import SnakeBar, { SnakeTypes } from '../../ui/components/modals/SnakeBar';
import FirebaseService from '../../services/firebase-service';
import { snackbarAction } from '../types/snackbarTypes';
import { MultiFactorError, MultiFactorResolver, getMultiFactorResolver } from 'firebase/auth';
import { z } from 'zod';
import { isDate } from 'util/types';

const stringToValidDate = z.string().transform((dateString, ctx) => {
    const date = new Date(dateString);
    if (!z.date().safeParse(date).success) {
        ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
        });
    }
    return date;
});
class emailVerificationError extends Error {
    private _errorExtraParams: Object;
    constructor(message: string, errorExtraParams: Object) {
        super(message);
        this._errorExtraParams = errorExtraParams;
    }

    get errorExtraParams() {
        return this._errorExtraParams;
    }
}

enum Keys {
    firebaseToken = 'firebaseToken',
    accessToken = 'accessToken',
    id = 'id',
    time = 'time',
}

export const startUserAction = (): IStartUserAction => ({
    type: UserActionTypes.START_USER_ACTION,
});
export const finishUserAction = (): IFinishUserAction => ({
    type: UserActionTypes.FINISH_USER_ACTION,
});

export const errorUserAction = (payload: IErrors[]): IErrorUserAction => ({
    type: UserActionTypes.ERROR_USER_ACTION,
    payload,
});

export const setResponseMessage = (payload: IResponseMessage): IGetResponseMessage => ({
    type: UserActionTypes.GET_RESPONSE_MESSAGE,
    payload,
});

export const setRequestMessage = (payload: ISignUpResponse): IRegistrationSuccess => ({
    type: UserActionTypes.REGISTRATION_SUCCESS,
    payload,
});

export const initialize = (): IInitialized => ({
    type: UserActionTypes.INITIALIZED,
});

export const clearUserError = (): IClearUserError => ({
    type: UserActionTypes.CLEAR_USER_ERROR,
});

export const clearMessage = (): IClearUserMessage => ({
    type: UserActionTypes.CLEAR_USER_MESSAGE,
});

export const clearRequest = (): IClearUserRequest => ({
    type: UserActionTypes.CLEAR_USER_REQUEST,
});

export const registrationNaturalUser = (data: IRequestSignUpPrivateUser) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.singUpPrivateUser(data);
        dispatch(setRequestMessage(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const registrationJuristicWholesalerUser = (data: IRequestSignUpJuristicWholesalerUser) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.signUpJuristicWholesaler(data);
        dispatch(setRequestMessage(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const activateUserByPhoneCode = (request: string, code: number) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.secondStepRegister(request, code + '');
        dispatch(setResponseMessage(response));
        dispatch(clearRequest());
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const registerFirebaseUser = (data: IRequestSignUpPrivateUser) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.registerFirebaseUser(data);
        dispatch(setRequestMessage({ request: 'success' }));
    } catch (_e: any) {
        const e = _e as AxiosError;
        if (_e.message.split(' ').includes('(auth/too-many-requests).')) {
            dispatch(
                errorUserAction([
                    {
                        property: '',
                        code: 'Too many attempts. Try again later or reset your password',
                        trace: [''],
                    },
                ])
            );
            return;
        }

        dispatch(errorUserAction(e.response?.data.message));
    }
};

/*export const activateUser = (id: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction())
        const response = await userAPI.activateUser(id)
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(errorUserAction(e.response?.data.message))
    }
}*/

const firstStepLogIn = (payload: { MFE: MultiFactorError }): IFirstStepLoginSuccess => ({
    type: UserActionTypes.LOGIN_FIRST_STEP_SUCCESS,
    payload,
});

export const closeSecondStepLogIn = () => ({
    type: UserActionTypes.LOGIN_SECOND_STEP_FAIL,
});

const setUserData = (payload: IUserData): ISecondStepLoginSuccess => ({
    type: UserActionTypes.LOGIN_SECOND_STEP_SUCCESS,
    payload,
});

export const sendVerificationLink = (uid: string) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.sendEmailVerification({ uid });
        // dispatch(setResponseMessage(response));
        dispatch(showSnackbar({ type: SnakeTypes.success, content: 'Email sent', ms: 4000 }));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Error while sending email', ms: 4000 }));
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const logInFirstStep = (data: IUserLogin) => async (dispatch: Dispatch<any>) => {
    try {
        const firebaseResponse = await FirebaseService.auth.email.signIn(data.email, data.password);
        if (firebaseResponse) {
            const idToken = await firebaseResponse.user.getIdToken();
            const uid = firebaseResponse.user.uid;
            localStorage.setItem(Keys.firebaseToken, idToken);

            // if (firebaseResponse.user.emailVerified === false) {
            //     throw new emailVerificationError('verify', { uid: firebaseResponse.user.uid });
            // }
            const response = await userAPI.signInFirebase({ uid });

            dispatch(setUserData(response.user));
        }
    } catch (_e: any) {
        if (_e.message.split(' ').includes('(auth/user-disabled).')) {
            dispatch(
                errorUserAction([
                    {
                        property: '(auth/user-disabled).',
                        code: 'Access to the account was restricted',
                        trace: [''],
                    },
                ])
            );
            return;
        }
        if (_e.message.split(' ').includes('(auth/user-not-found).')) {
            dispatch(
                errorUserAction([
                    {
                        property: '(auth/user-not-found).',
                        code: 'User with this email is not found',
                        trace: [''],
                    },
                ])
            );
            return;
        }
        if (_e.message.split(' ').includes('(auth/wrong-password).')) {
            dispatch(
                errorUserAction([
                    {
                        property: '(auth/wrong-password).',
                        code: 'Incorrect email or password',
                        trace: [''],
                    },
                ])
            );
            return;
        }

        if (_e.message.split(' ').includes('(auth/too-many-requests).')) {
            dispatch(
                errorUserAction([
                    {
                        property: '(auth/too-many-requests).',
                        code: 'Too many attempts. Try again later or reset your password',
                        trace: [''],
                    },
                ])
            );
            return;
        }

        if (_e.message === 'verify') {
            dispatch(
                showSnackbar({
                    type: SnakeTypes.error,
                    content: 'Your email is not verified. Link for verification has been sent to your email.',
                    ms: 10000,
                })
            );
            const uid = _e.errorExtraParams.uid;
            await userAPI.sendEmailVerification({ uid });

            return;
        }

        if (_e.message.split(' ').includes('(auth/multi-factor-auth-required).')) {
            const MFE = _e as MultiFactorError;
            dispatch(firstStepLogIn({ MFE }));
            // const response = await FirebaseService.auth.email.handleMFA(_e)
            return;
        }

        const e = _e as AxiosError;

        let error = e.response?.data.message;

        if (error === 'verify') {
            // TODO: add blocker screen
        }

        if (typeof error === 'string') {
            error = [
                {
                    property: '',
                    code: error,
                    trace: [''],
                },
            ];
        }

        if (e)
            /*        error[0].code = 'Incorrect login or password'*/
            dispatch(
                showSnackbar({
                    content: 'Error',
                    type: SnakeTypes.error,
                    ms: 4000,
                })
            );
        dispatch(errorUserAction(error));
    }
};

export const logInDev = (data: IUserLogin) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.signInDev(data);
        dispatch(setUserData(response.user));
        localStorage.setItem(Keys.accessToken, response.access_token);
    } catch (_e) {
        const e = _e as AxiosError;
        let error = e.response?.data.message;

        if (typeof error === 'string') {
            error = [
                {
                    property: '',
                    code: error,
                    trace: [''],
                },
            ];
        }

        error[0].code = 'Incorrect login or password';
        /*        let errorText: string*/
        /*        if (e.response?.status === 404) {
                    errorText = e.response.data.message
                } else {
                    errorText = e.response?.data.message[0].code
                }*/
        dispatch(errorUserAction(error));
    }
};

export const logInSecondStep =
    (code: number, verificationMFA: { verificationId: string; resolver: MultiFactorResolver }) => async (dispatch: Dispatch<userAction>) => {
        try {
            // const id = localStorage.getItem(Keys.id);
            // if (!id) {
            //     dispatch(
            //         errorUserAction([
            //             {
            //                 property: 'secondStep',
            //                 code: 'First step not done',
            //                 trace: [''],
            //             },
            //         ])
            //     );
            //     dispatch(clearState());
            //     return;
            // }
            dispatch(startUserAction());
            // const response = await userAPI.signInStepTwo({ id, code });
            // dispatch(setUserData(response.user));
            // localStorage.setItem(Keys.accessToken, response.access_token);
            localStorage.removeItem(Keys.id);
        } catch (_e) {
            const e = _e as AxiosError;
            dispatch(clearState());
            /*        let errorText: string
                if (e.response?.status === 403) {
                    errorText = e.response.data.message
                } else {
                    errorText = e.response?.data.message[0].code
                }*/
            dispatch(errorUserAction(e.response?.data.message));
        }
    };

export const getUser = () => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.getCurrentUser();
        dispatch(setUserData(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorUserAction(e.response?.data.message));
    }
    dispatch(initialize());
};

export const clearState = (): IClearUserState => ({
    type: UserActionTypes.CLEAR_STATE_USER,
    payload: undefined,
});

export const contactUs =
    (data: { name: string; email: string; message: string; company?: string; phone: string }) => async (dispatch: Dispatch<any>) => {
        try {
            dispatch(startUserAction());
            const last_sent_form = localStorage.getItem('last_sent_form');

            // new Date(invalid_date_string) returns Invalid Date objects. InvalidDate.getTime() returns NaN. !isNaN(NaN) returns false.

            if (last_sent_form && !isNaN(new Date(last_sent_form).getTime())) {
                const date = new Date(last_sent_form);
                const diff = new Date().getTime() - date.getTime();
                if (diff < 1000 * 60 * 5) {
                    dispatch(
                        showSnackbar({
                            content: 'You can send message only once in 5 minutes',
                            type: SnakeTypes.warning,
                            ms: 4000,
                        })
                    );
                    return;
                }
            }

            const response = await userAPI.getInTouch(data);
            localStorage.setItem('last_sent_form', new Date().toUTCString());
            dispatch(setResponseMessage({ message: response.message }));
            dispatch(showSnackbar({ content: 'The message was successfully sent!', type: SnakeTypes.success, ms: 4000 }));
        } catch (_e) {
            if (axios.isAxiosError(_e)) {
                dispatch(errorUserAction(_e.response?.data.message));
            }
            dispatch(showSnackbar({ content: 'Error while sending the message!', type: SnakeTypes.error, ms: 4000 }));
        }
    };

export const logout = () => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        await userAPI.logoutUser();
        dispatch(clearState());
        // localStorage.removeItem(Keys.accessToken);
        localStorage.removeItem(Keys.firebaseToken);
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const phoneChange = (phone: string) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startUserAction());
        const user = await userAPI.phoneChange({ phone });
        dispatch(showSnackbar({ content: 'Phone number was changed', type: SnakeTypes.success }));
        dispatch(setUserData(user));
        dispatch(setResponseMessage({ message: 'ok' }));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: e.response?.data.message, type: SnakeTypes.error }));
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const verifyPhoneNumberSessionCreate = () => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.phoneActivationSessionCreate();
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: e.response?.data.message, type: SnakeTypes.error }));
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const verifyPhoneNumberActivate = () => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startUserAction());
        // await userAPI.getCurrentStateResetSession(id);
        const response = await userAPI.activatePhone();
        dispatch(setResponseMessage(response));
        dispatch(showSnackbar({ content: 'Phone number was confirmed', type: SnakeTypes.success }));
        window.location.reload();
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: e.response?.data.message, type: SnakeTypes.error }));
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const restorePasswordSessionCreate = (email: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.resetPasswordSessionCreate(email);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const restorePasswordGetStatus = (id: string) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.getCurrentStateResetSession(id);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: e.response?.data.message, type: SnakeTypes.error }));
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const restorePasswordActivate = (id: string, password: string, callback?: () => void) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startUserAction());
        await userAPI.getCurrentStateResetSession(id);
        const response = await userAPI.resetPasswordActivate(id, password);
        dispatch(setResponseMessage(response));
        dispatch(showSnackbar({ content: 'Password successfully changed', type: SnakeTypes.success }));
        if (callback) {
            callback();
        }
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: e.response?.data.message, type: SnakeTypes.error }));
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const updateUserInfo = (data: IUserDataUpdate) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.updateUserData(data);
        dispatch(setUserData(response));
        dispatch(showSnackbar({ content: 'User info was updated successfully', type: SnakeTypes.success }));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: 'Something went wrong', type: SnakeTypes.error }));
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const changeUserPassword =
    (/*oldPassword: string, newPassword: string, */ onSuccessCallback?: () => void) => async (dispatch: Dispatch<any>) => {
        try {
            // dispatch(startUserAction());
            // const response = await userAPI.changePassword({ old_password: oldPassword, new_password: newPassword });
            // dispatch(setUserData(response));
            // dispatch(showSnackbar({ content: 'Password has been successfully changed!', type: SnakeTypes.success, ms: 4000 }));
            // if (onSuccessCallback) {
            //     onSuccessCallback();
            // }
            dispatch(startUserAction());
            const response = await userAPI.changeFirebasePassword();
            dispatch(showSnackbar({ content: 'A link to change your password has been send to your email', type: SnakeTypes.success, ms: 5000 }));
            if (onSuccessCallback) {
                onSuccessCallback();
            }
        } catch (_e) {
            const e = _e as AxiosError;
            dispatch(errorUserAction(e.response?.data.message));
        }
    };

export const updateUserEmailSessionCreate = (newEmail: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.changeEmailSessionCreate(newEmail);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const updateUserEmailSessionActivate = (id: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        await userAPI.getChangeEmailStatus(id);
        const response = await userAPI.changeEmailActivate(id);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const updateUserPhoneSessionCreate = (newPhone: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.changePhoneSessionCreate(newPhone);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorUserAction(e.response?.data.message));
    }
};

export const updateUserPhoneSessionActivate = (id: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        await userAPI.getChangePhoneStatus(id);
        const response = await userAPI.changePhoneActivate(id);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorUserAction(e.response?.data.message));
    }
};
