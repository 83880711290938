import api from "../../api";
import {IMetalPrices} from "../../api/metal/metalTypes";

export const metalApi = {
    async getMetalPrices(currency?: string){
      return (await api.get<IMetalPrices[]>(`/metal/prices`, {
        params: {
          currency
        }
      })).data
    },
    async getMetalApiStatus(){
        return (await api.get('/metal/status')).data
    }
}