import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextFields from '../../ui/TextFields';
import { Heading2 } from '../../ui/HeadingTags';
import ChangePassword from '../../ui/components/modals/ChangePassword';
import Modal from '../../ui/components/modals/Modal';
import { FIELD_TYPE, validateField } from '../../helpers/settingsValidation';
import { FIELD_HEIGHT } from '../auth/SignUp';
import StyledButton from '../../ui/StyledButton';
import { useTypedSelector } from '../../hooks/useSelector';
import { useUserActions } from '../../hooks/useActions';
import { formChangedNaturalObject, formChangedNonNaturalObject } from '../../helpers/formChangedObject';
import CheckYourEmail from '../../ui/components/modals/CheckYourEmail';

/*

interface IUserData extends Omit<IErrorState, 'isAgreeWithPolicy'> {
    currentPersonType: PERSON_TYPES
    isAgreeWithPolicy: boolean
}
enum PERSON_TYPES {
    naturalPerson = 'Natural person',
    juristicPerson = 'Juristic person',
    wholesaler = 'Wholesaler'
}
*/

/*interface IUserInfoFields {
    placeholder: string,
    value: string,
    type: string
}*/

interface IUserState {
    firstName: string;
    secondName: string;
    email: string;
    phone: string;
    companyName: string;
    website: string;
}

interface IErrorState {
    firstName: string;
    secondName: string;
    email: string;
    phone: string;
    companyName: string;
    website: string;
}

{
    /*const [userData, setUserData] = useState<IUserData>({
    currentPersonType: PERSON_TYPES.naturalPerson,
    firstName: '',
    lastName: '',
    abnAcn: '',
    companyName: '',
    email: '',
    phone: '',
})
const profileUser = () => {
    const isNaturalPerson = userData.currentPersonType === PERSON_TYPES.naturalPerson
    let obj: IErrorState = {
        firstName: isNaturalPerson ? validateField(userData.firstName, FIELD_TYPE.firstName) : '',
        lastName: isNaturalPerson ? validateField(userData.lastName, FIELD_TYPE.lastName) : '',
        abnAcn: !isNaturalPerson ? validateField(userData.abnAcn, FIELD_TYPE.abnAcn) : '',
        companyName: !isNaturalPerson ? validateField(userData.companyName, FIELD_TYPE.companyName) : '',
        email: validateField(userData.email, FIELD_TYPE.email),
        phone: validateField(userData.phone, FIELD_TYPE.phone),
    }
*/
}
const SettingsFieldsComponent = () => {
    const { user, message } = useTypedSelector((state) => state.userReducer);

    const { updateUserInfo, updateUserEmailSessionCreate, updateUserPhoneSessionCreate, clearMessage } = useUserActions();

    const [userData, setUserData] = useState<IUserState>({
        firstName: user?.first_name ? user.first_name : '',
        secondName: user?.surname ? user.surname : '',
        email: user?.email ? user.email : '',
        phone: user?.phone ? user.phone : '',
        companyName: user?.company_name ? user.company_name : '',
        website: user?.website ? user.website : '',
    });
    const [errorState, setErrorState] = useState<IErrorState>({
        firstName: '',
        secondName: '',
        email: '',
        phone: '',
        companyName: '',
        website: '',
    });
    const changePassword = () => {
        setOpenModalChangePassword(true);
    };
    const [isShown, setIsShown] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
    const [openModalCheckEmail, setOpenModalCheckEmail] = useState(false);
    const [modalDescription, setModalDescription] = useState<string | undefined>(undefined);

    useEffect(() => {
        setHasError(errorState.phone !== '' || errorState.email !== '' || errorState.firstName !== '' || errorState.secondName !== '');
    }, [errorState]);

    const onSaveHandler = () => {
        let sendData;
        if (!user) return;
        if (user?.role === 'natural') {
            sendData = formChangedNaturalObject({
                oldData: {
                    first_name: user.first_name,
                    surname: user.surname,
                },
                newData: {
                    first_name: userData.firstName,
                    surname: userData.secondName,
                },
            });
        } else {
            sendData = formChangedNonNaturalObject({
                oldData: {
                    company_name: user.company_name,
                    website: user.website,
                },
                newData: {
                    company_name: userData.companyName,
                    website: userData.website,
                },
            });
        }

        if (sendData) {
            updateUserInfo(sendData);
        }
        if (user.email !== userData.email || user.phone !== userData.phone) {
            if (user.email !== userData.email) {
                updateUserEmailSessionCreate(userData.email);
                setModalDescription(
                    'The data has been successfully changed.\n' +
                        'A message has been sent to your entered email. To confirm the email change, follow the link in the email.'
                );
            }
            if (user.phone !== userData.phone) {
                updateUserPhoneSessionCreate(userData.phone);
                setModalDescription(
                    'The data has been successfully changed.\n' +
                        'A message has been sent to your entered email. To confirm the phone change, follow the link in the email.'
                );
            }
            if (user.email !== userData.email && user.phone !== userData.phone) {
                setModalDescription(
                    'The data has been successfully changed.\n' +
                        'A message has been sent to your entered email. To confirm the phone and email changes, follow the link in the email.'
                );
            }
        }
    };

    useEffect(() => {
        if (message) {
            setOpenModalCheckEmail(true);
        }
        return () => {
            clearMessage();
        };
    }, [message]);

    return (
        <div>
            <Modal ModalElement={ChangePassword} show={openModalChangePassword} setShow={setOpenModalChangePassword} />
            <Modal
                ModalElement={() => CheckYourEmail({ description: modalDescription })}
                show={openModalCheckEmail}
                setShow={setOpenModalCheckEmail}
            />
            <FieldDiv>
                <Heading2>Personal information</Heading2>
                <TextFieldStyles>
                    {!(user?.role === 'natural') ? (
                        <>
                            <TextFieldSty>
                                <TextFields
                                    value={userData.companyName}
                                    onChange={(e) => {
                                        const companyName = e.target.value;
                                        setUserData((ud) => ({ ...ud, companyName }));
                                        setErrorState((e) => ({
                                            ...e,
                                            companyName: validateField(companyName, FIELD_TYPE.companyName),
                                        }));
                                    }}
                                    height={FIELD_HEIGHT}
                                    type={'text'}
                                    placeholder={'Company name'}
                                    textError={errorState.companyName}
                                />
                            </TextFieldSty>
                            <TextFieldSty>
                                <TextFields
                                    value={userData.website}
                                    onChange={(e) => {
                                        const website = e.target.value;
                                        setUserData((ud) => ({ ...ud, website }));
                                        setErrorState((e) => ({
                                            ...e,
                                            website: validateField(website, FIELD_TYPE.website),
                                        }));
                                    }}
                                    height={FIELD_HEIGHT}
                                    type={'website'}
                                    placeholder={'Website'}
                                    textError={errorState.website}
                                />
                            </TextFieldSty>
                        </>
                    ) : (
                        <>
                            {' '}
                            <TextFieldSty>
                                <TextFields
                                    value={userData.firstName}
                                    onChange={(e) => {
                                        const firstName = e.target.value;
                                        setUserData((ud) => ({ ...ud, firstName }));
                                        setErrorState((e) => ({
                                            ...e,
                                            firstName: validateField(firstName, FIELD_TYPE.firstName),
                                        }));
                                    }}
                                    height={FIELD_HEIGHT}
                                    type={'text'}
                                    placeholder={'First name'}
                                    textError={errorState.firstName}
                                    required={true}
                                />
                            </TextFieldSty>
                            <TextFieldSty>
                                <TextFields
                                    value={userData.secondName}
                                    onChange={(e) => {
                                        const secondName = e.target.value;
                                        setUserData((ud) => ({ ...ud, secondName }));
                                        setErrorState((e) => ({
                                            ...e,
                                            secondName: validateField(secondName, FIELD_TYPE.lastName),
                                        }));
                                    }}
                                    height={FIELD_HEIGHT}
                                    type={'text'}
                                    placeholder={'Last Name'}
                                    textError={errorState.secondName}
                                />
                            </TextFieldSty>
                        </>
                    )}

                    <TextFieldSty>
                        <TextFields
                            value={userData.email}
                            onChange={(e) => {
                                // const email = e.target.value
                                // setUserData(ud => ({...ud, email}))
                                // setErrorState(e => ({...e, email: validateField(email, FIELD_TYPE.email)}))
                            }}
                            height={FIELD_HEIGHT}
                            type={'email'}
                            placeholder={'Email'}
                            textError={errorState.email}
                            disabled={true}
                        />
                    </TextFieldSty>
                    <TextFieldSty>
                        <TextFields
                            value={userData.phone}
                            onChange={(e) => {
                                const phone = e.target.value;
                                setUserData((ud) => ({ ...ud, phone }));
                                setErrorState((e) => ({ ...e, phone: validateField(phone, FIELD_TYPE.phone) }));
                            }}
                            height={FIELD_HEIGHT}
                            type={'tel'}
                            placeholder={'Phone'}
                            textError={errorState.phone}
                            disabled={true}
                        />
                    </TextFieldSty>
                </TextFieldStyles>

                {isShown && <Confirm>The data has been successfully changed!</Confirm>}
                <Modal ModalElement={ChangePassword} show={openModalChangePassword} setShow={setOpenModalChangePassword} />
                <ChangePass onClick={() => changePassword()}>Change password</ChangePass>
                <ButtonWrapp>
                    <StyledButton onClick={onSaveHandler} disabled={hasError} height={'50px'}>
                        {' '}
                        Save{' '}
                    </StyledButton>
                </ButtonWrapp>
            </FieldDiv>
        </div>
    );
};
const TextFieldStyles = styled.div`
    max-width: 520px;
    width: 100%;
    margin-bottom: 22px;
    @media (max-width: 900px) {
        max-width: calc(55vw);
    }
    @media (max-width: 600px) {
        max-width: calc(90vw);
    }
`;
const FieldDiv = styled.div`
    margin-left: 100px;
    width: 400px;

    margin-bottom: 25px;
    @media (max-width: 600px) {
        margin-left: 0;
        width: 90vw;
    }
`;
const ButtonWrapp = styled.div`
    width: 180px;
    margin-top: 27px;
`;
const Confirm = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 520px;
    height: 100px;
    background-color: white;
    font-family: Inter, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    text-align: left;
    position: fixed;
    overflow: visible;
    top: 0;
    left: 0;
    z-index: 5;
    transform: translate(calc(450px + 0.5 * (100vw - 1290px)), 500px);

    flex-direction: column;
    box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.22);
    @media (max-width: 1290px) {
        transform: translate(calc(100vw - 285px), 80px);
    }
`;
const TextFieldSty = styled.div`
    margin-bottom: 30px;
`;
const ChangePass = styled.a`
    color: #5b73cf;
    font-family: Inter, serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
`;
export default SettingsFieldsComponent;
