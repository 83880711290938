import {
    IClearSearchItems,
    IErrorSearchAction,
    IGetSearchData,
    IStartSearchAction,
    searchActions
} from "../../store/types/searchTypes";
import {SearchActionsTypes} from "../../store/constants/searchConstants";
import {IErrors} from "../../api/user/userTypes";
import {IPaginationData, IShopData} from "../../api/shop/shopTypes";
import {Dispatch} from "redux";
import {shopApi} from "../../api/shop/shopApi";
import {AxiosError} from "axios";

const startAction = ():IStartSearchAction => ({
    type: SearchActionsTypes.START_SEARCH_ACTION
})

const errorAction = (payload: IErrors[]):IErrorSearchAction => ({
    type: SearchActionsTypes.ERROR_SEARCH_ACTION,
    payload
})

const _getSearchProducts = (payload: IShopData):IGetSearchData => ({
    type: SearchActionsTypes.GET_SEARCH_DATA,
    payload
})

export const getSearchProducts = ({title, pageLimit}:IPaginationData) => async (dispatch: Dispatch<searchActions>) => {
    try{
        dispatch(startAction())
        const response = await shopApi.getShopInfo({title, pageLimit})
        dispatch(_getSearchProducts(response))
    }catch (_e){
        const  e = _e as AxiosError
        dispatch(errorAction(e.response?.data.message))
    }
}

export const clearSearch = ():IClearSearchItems => ({
    type: SearchActionsTypes.CLEAR_SEARCH_DATA
})