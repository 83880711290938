import React from 'react';
import { Heading2, Heading3 } from '../../ui/HeadingTags';
import styled from 'styled-components';
import { ReactComponent as CallIcon } from '../../assets/svgs/footer_call_icon.svg';
import { ReactComponent as MailIcon } from '../../assets/svgs/footer_message_icon.svg';
import { Container } from '../../ui/container';
import { ReactComponent as LocationIcon } from '../../assets/svgs/footer_location_icon.svg';
import MapboxOffice from '../mapbox/MapboxOffice';

interface IWeHereToHelp {
    statics: any;
}

const WeHereToHelp = (props: IWeHereToHelp) => {
    const { statics } = props;

    const INFO = [
        {
            icon: <CallStyle />,
            text: '03.8618.6941',
            func: 'tel:03.8618.6941',
        },
        {
            icon: <MailStyle />,
            text: 'sales@bullionnetwork.com.au',
            func: 'mailto:sales@bullionnetwork.com.au',
        },
    ];
    const HOURS = {
        textOne: 'Monday-Friday 10am — 5pm',
        textTwo: 'Saturday 10am — 2pm (Excluding Public Holidays)',
    };
    const ADDRESS = {
        icon: <LocationStyle />,
        text: 'Suite 517 level 5 220 Collins St Manchester Unity Building Melbourne 3004',
    };

    return (
        <Wrapper>
            <Container>
                <Containers>
                    <TextBlock>
                        <Header>We're Here To Help</Header>
                        <>
                            {/* {INFO.map((item, index) => {
                                return (
                                    <LinkBlock key={`links-index-${index}`}>
                                        <LinksLogoBox>{item.icon}</LinksLogoBox>
                                        <LinksInfo href={item.func}>{item.text}</LinksInfo>
                                    </LinkBlock>
                                );
                            })} */}
                            {statics.Phone && (
                                <LinkBlock>
                                    <LinksLogoBox>{INFO[0].icon}</LinksLogoBox>
                                    <LinksInfo href={INFO[0].func} dangerouslySetInnerHTML={{ __html: statics.Phone.data }}></LinksInfo>
                                </LinkBlock>
                            )}

                            {statics.Email && (
                                <LinkBlock>
                                    <LinksLogoBox>{INFO[1].icon}</LinksLogoBox>
                                    <LinksInfo href={INFO[1].func} dangerouslySetInnerHTML={{ __html: statics.Email.data }}></LinksInfo>
                                </LinkBlock>
                            )}

                            <h3>Trading Hours</h3>
                            <LogoBox>
                                {statics.WeekendHours && (
                                    <li>
                                        {/* dangerouslySetInnerHTML={{__html: statics.Text.data}} */}
                                        <Links dangerouslySetInnerHTML={{ __html: statics.WeekHours.data }} />
                                    </li>
                                )}
                                {statics.WeekendHours && (
                                    <li>
                                        {/* <Links>{statics.WeekendHours.data || ''}</Links> */}
                                        <Links dangerouslySetInnerHTML={{ __html: statics.WeekendHours.data }} />
                                    </li>
                                )}
                            </LogoBox>
                            <h3>Our Address</h3>
                            <LogoBoxAddress>
                                <LinksLogoBox>{ADDRESS.icon}</LinksLogoBox>
                                {statics.Address && <Links dangerouslySetInnerHTML={{ __html: statics.Address.data }} />}
                            </LogoBoxAddress>
                        </>
                    </TextBlock>

                    {/*<BigCapture src={Map}/>*/}
                    <BigCapture>
                        <MapboxOffice width={'100%'} height={'100%'} />
                    </BigCapture>
                </Containers>
            </Container>
        </Wrapper>
    );
};
const LinksInfo = styled.a`
    margin-left: 8px;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #0b1126;
    max-width: 298px;
    text-decoration: none;
    cursor: pointer;
`;
const Links = styled.span`
    margin-left: 8px;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #0b1126;
    max-width: 298px;
`;
const BigCapture = styled.div`
    height: 380px;
    width: 55vw;
    @media (max-width: 1290px) {
        width: 55vw;
    }
    @media (max-width: 900px) {
        width: 46vw;
    }
    @media (max-width: 600px) {
        width: 95vw;
    }
`;
const LinkBlock = styled.div`
    display: flex;
    align-items: start;
    margin-bottom: 10px;
`;

const LinksLogoBox = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Wrapper = styled.div<{ bg?: string }>`
    background: ${(props) => (props.bg ? `no-repeat right top/40% url(${window.location.origin}${props.bg})` : 'none')};
    @media (max-width: 1290px) {
        max-width: 95vw;
        padding: 0 10px;
    }
    @media (max-width: 1150px) {
        background-image: none;
    }
`;
const LogoBox = styled(LinkBlock)`
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
`;
const LogoBoxAddress = styled(LogoBox)`
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
`;
const TextBlock = styled.div``;
const Header = styled(Heading2)`
    margin-top: 0;
    @media (max-width: 900px) {
        font-size: 36px;
    }
    @media (max-width: 600px) {
        font-size: 24px;
    }
`;
const Containers = styled.div`
    display: flex;
    padding-bottom: 120px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: start;
    }
`;
const CallStyle = styled(CallIcon)`
    & * {
        fill: #200e32;
    }
`;
const MailStyle = styled(MailIcon)`
    & * {
        fill: #200e32;
    }
`;
const LocationStyle = styled(LocationIcon)`
    & * {
        fill: #200e32;
    }
`;
export default WeHereToHelp;
