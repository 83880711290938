import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Container } from '../ui/container';
import styled from 'styled-components';
import StyledButton from '../ui/StyledButton';
import { checkPassword, FIELD_TYPE, validateField } from '../helpers/registerValidation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserActions } from '../hooks/useActions';
import PasswordField from '../ui/PasswordField';
import { useTypedSelector } from '../hooks/useSelector';
import FirebaseService from '../services/firebase-service';
import { showSnackbar } from '../store/actions/snackbarActions';
import { SnakeTypes } from '../ui/components/modals/SnakeBar';
import { useDispatch } from 'react-redux';

interface IPasswordData {
    password: string;
    confirmPassword: string;
}

const CreateNewPassword = () => {
    const location = useLocation();
    const pathname = location.pathname.split('/');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let oobCode = '';

    const { error, loading } = useTypedSelector((state) => state.userReducer);
    const { restorePasswordActivate, restorePasswordGetStatus, clearUserError, clearMessage } = useUserActions();

    const [resetError, setResetError] = useState<string | undefined>(undefined);
    const [userData, setUserData] = useState<IPasswordData>({
        password: '',
        confirmPassword: '',
    });
    const [errorState, setErrorState] = useState<IPasswordData>({
        password: '',
        confirmPassword: '',
    });

    const id = pathname[3];

    const createNewPassword = async () => {
        const errorInPassword = validateField(userData.confirmPassword, FIELD_TYPE.confirmPassword);
        let obj: IPasswordData = {
            password: validateField(userData.password, FIELD_TYPE.password),
            confirmPassword: errorInPassword.length > 0 ? errorInPassword : checkPassword(userData.password, userData.confirmPassword),
        };
        if (Object.values(obj).some((x) => x !== '')) {
            setErrorState(obj);
        } else {
            const result = await FirebaseService.auth.email.resetPassword(oobCode, userData.password);
            if (result === true) {
                dispatch(showSnackbar({ type: SnakeTypes.success, content: 'Password successfully changed', ms: 4000 }));
                navigate('/');
            } else {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Session is inactive. Try again', ms: 4000 }));
                // navigate('/');
            }
        }
    };

    useEffect(() => {
        clearUserError();
        return () => {
            clearMessage();
        };
    }, []);

    useEffect(() => {
        if (error && error !== 'Unauthorized') {
            setResetError(error as any as string);
        }
    }, [error]);

    try {
        oobCode = location.search.split('&')[1].split('oobCode=')[1];
    } catch (error) {
        navigate('/');
    }

    return (
        <Layout>
            <Container>
                <Content>
                    <PasswordBlock>
                        <Tittle>Create New Password</Tittle>
                        <div>
                            <PasswordField
                                placeholder={'Password*'}
                                setPasswordData={(value) => {
                                    const password = value;
                                    setUserData((u) => ({ ...u, password }));
                                    setResetError(undefined);
                                    if (value.length > 0) {
                                        setErrorState((e) => ({
                                            ...e,
                                            password: validateField(password, FIELD_TYPE.password),
                                        }));
                                    } else {
                                        setErrorState((e) => ({
                                            ...e,
                                            password: '',
                                        }));
                                    }
                                }}
                                password={userData.password}
                                errorPassword={errorState.password}
                            />
                        </div>
                        <div>
                            <PasswordField
                                placeholder={'Confirm password*'}
                                setPasswordData={(value) => {
                                    const confirmPassword = value;
                                    setUserData((u) => ({ ...u, confirmPassword }));
                                    setResetError(undefined);
                                    const errorInPassword = validateField(confirmPassword, FIELD_TYPE.confirmPassword);
                                    if (value.length > 0) {
                                        setErrorState((e) => ({
                                            ...e,
                                            confirmPassword:
                                                errorInPassword.length > 0 ? errorInPassword : checkPassword(userData.password, confirmPassword),
                                        }));
                                    } else {
                                        setErrorState((e) => ({
                                            ...e,
                                            confirmPassword: '',
                                        }));
                                    }
                                }}
                                password={userData.confirmPassword}
                                errorPassword={errorState.confirmPassword}
                            />
                        </div>
                        {resetError && <ErrorText>{resetError}</ErrorText>}

                        <ButtonContainer>
                            <StyledButton disabled={!!resetError || loading} onClick={createNewPassword} height={'50px'}>
                                Reset
                            </StyledButton>
                        </ButtonContainer>
                    </PasswordBlock>
                </Content>
            </Container>
        </Layout>
    );
};

const Content = styled.div`
    padding-top: 50px;
    height: 600px;
`;

const PasswordBlock = styled.div`
    padding-left: 5px;
    max-width: 582px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    @media (max-width: 761px) {
        max-width: 360px;
    }
    @media (max-width: 420px) {
        max-width: 280px;
    }
`;

const ButtonContainer = styled.div`
    margin-top: 10px;
    max-width: 180px;
`;

const Tittle = styled.div`
    /* font-family: 'Playfair Display'; */
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 53px;
    text-transform: capitalize;
`;

const ErrorText = styled.div`
    color: #d92e23;
    font-size: 14px;
    padding-left: 10px;
    padding-top: 2px;
`;

export default CreateNewPassword;
