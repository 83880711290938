import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HiddenCard from './HiddenCard';
import Loading from '../../components/loading';
import axios from 'axios';
import config from '../../config';

interface IField {
    url?: string;
    data?: string;
    type: string;
}

interface IDtoStatic {
    [key: string]: IField;
}
const FAQs = () => {
    const [staticContent, setStaticContent] = useState<IDtoStatic[]>([]);
    useEffect(() => {
        axios.get(`${config.REACT_APP_API_BASE_URL || ''}/page/FAQ`).then((response) => {
            setStaticContent(response.data.content);
        });
        window.scrollTo(0, 0);
    }, []);

    const staticsMap = Object.entries(staticContent);

    if (!staticContent) {
        return <Loading />;
    }

    return (
        <div>
            <WholePage>
                <Wrapper>
                    <SmallWrapper>
                        <Title>FAQ</Title>
                        <FAQBlocks>
                            <div>
                                {staticsMap.slice(0, Math.ceil(staticsMap.length / 2)).map((item, index) => {
                                    return (
                                        <React.Fragment key={`card-index-${index}`}>
                                            <HiddenCard item={item} />
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                            <div>
                                {staticsMap.slice(-(staticsMap.length / 2)).map((item, index) => {
                                    return (
                                        <React.Fragment key={`card-index-${index}`}>
                                            <HiddenCard item={item} />
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </FAQBlocks>
                    </SmallWrapper>
                </Wrapper>
            </WholePage>
        </div>
    );
};

const Wrapper = styled.div`
    background-color: #ffffff;
    width: 100%;
    height: auto;
    min-height: 450px;
    margin-bottom: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1400px) {
        margin-bottom: 90px;
    }
    @media (max-width: 767px) {
        margin-bottom: 40px;
    }
`;
const SmallWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const WholePage = styled.div`
    display: flex;
    flex-direction: column;
`;
const FAQBlocks = styled.div`
    display: flex;

    @media (max-width: 900px) {
        display: block;
    }
`;

const Title = styled.h1`
    /* font-family: 'Playfair Display', serif; */
    font-style: normal;
    font-size: 40px;
    font-weight: 700;
    line-height: 53.32px;
    color: #0b1126;
    margin: 36px 10px;
    @media (max-width: 767px) {
        font-size: 36px;
    }
`;
export default FAQs;
