import React, { useEffect, useState } from 'react';
import { ReactComponent as Arrow } from '../../assets/svgs/settings_backArrow_icon.svg';
import CounterComponent from '../../ui/CounterComponent';
import StyledButton from '../../ui/StyledButton';
import Refresh from '../../assets/svgs/product_refresh_icon.svg';
import DeliveryIMG from '../../assets/svgs/delivery-icon.svg';
import Card from '../../components/catalog/cards/Card';
import PlaceholderIMG from '../../assets/svgs/cart_placeholder_img.svg';
import styled from 'styled-components';
import { IShopProduct } from '../../api/cart/cartTypes';
import { Link, useNavigate } from 'react-router-dom';
import { useCartActions } from '../../hooks/useActions';
import { numberWithCommas } from '../..//helpers/numberFormatter';
import { useTypedSelector } from '../../hooks/useSelector';
import { roundToTwoDecimals } from '../../helpers/round';
import { replaceSpacesAndSlashesWithHyphen } from '../../helpers/stringFormat';
import { InlineShareButtons } from 'sharethis-reactjs';

interface ICurrentImage {
    index: number;
    image: string | undefined;
}

interface IProductPage {
    recommendedProducts: IShopProduct[];
    currentProduct: IShopProduct | undefined;
    status: boolean;
}

const ProductPage: React.FC<IProductPage> = ({ recommendedProducts, currentProduct, status }) => {
    const navigate = useNavigate();

    const { addProductToCart } = useCartActions();

    const user = useTypedSelector((state) => state.userReducer.user);

    const [currentCurrency, setCurrentCurrency] = useState('$');

    const [value, setValue] = useState(1);
    const [isDesc, setIsDesc] = useState(true);

    const [carouselIndex, setCarouselIndex] = useState(3);

    const [currentImage, setCurrentImage] = useState<ICurrentImage>({
        image: currentProduct?.medias[0],
        index: 0,
    });

    const [slideItems, setSlideItems] = useState(currentProduct?.medias);
    const [swipeStartPos, setSwipeStartPos] = useState(0);

    const addToCart = (id: string) => {
        addProductToCart({ product_id: id, quantity: 1, user: user });
    };

    const onClickUp = () => {
        if (!currentImage) return;
        if (!slideItems) return;
        if (carouselIndex + 1 > slideItems.length) return;
        /*        setCurrentImage((prev) => ({
                    ...prev,
                    index: prev.index + 1,
                    //image: currentProduct?.medias[prev.index + 1],
                }))*/
        setCarouselIndex(carouselIndex + 1);
    };

    const onClickDown = () => {
        if (!currentImage) return;
        if (!slideItems) return;
        if (carouselIndex - 1 < 3) return;
        /*        setCurrentImage((prev) => ({
                    ...prev,
                    index: prev.index - 1,
                    /!*            image: currentProduct?.medias[prev.index - 1],*!/
                }))*/
        setCarouselIndex(carouselIndex - 1);
    };

    const swipeCarousel = (newPos: number) => {
        if (swipeStartPos - newPos < 0) {
            setCurrentImage(
                currentImage.index - 1 >= 0
                    ? (prev) => ({
                          index: prev.index - 1,
                          image: currentProduct?.medias[prev.index - 1],
                      })
                    : () => ({
                          index: 0,
                          image: currentProduct?.medias[0],
                      })
            );
        } else {
            if (!slideItems) return;
            setCurrentImage(
                currentImage.index + 1 <= slideItems.length - 1
                    ? (prev) => ({
                          index: prev.index + 1,
                          image: currentProduct?.medias[prev.index + 1],
                      })
                    : () => ({
                          index: slideItems.length - 1,
                          image: currentProduct?.medias[slideItems.length - 1],
                      })
            );
        }
    };

    useEffect(() => {
        setCurrentImage((prev) => ({
            ...prev,
            image: currentProduct?.medias[prev.index],
        }));
        setSlideItems(currentProduct?.medias);
    }, [currentProduct]);

    return (
        <Content>
            <Path>
                <span
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <PathLink to="/">Home</PathLink>
                </span>
                <div>&nbsp;{'/'}&nbsp;</div>
                <span
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <PathLink to="/shop">Shop</PathLink>
                </span>
                {currentProduct?.category && (
                    <div>
                        &nbsp;/&nbsp;
                        <span
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <PathLink to={`/shop/${replaceSpacesAndSlashesWithHyphen(currentProduct.category)}/${currentProduct?.category_id}`}>
                                {currentProduct?.category}
                            </PathLink>
                        </span>
                    </div>
                )}
                &nbsp;{'/'}&nbsp;
                <span
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <PathLink to={`/shop/product/${replaceSpacesAndSlashesWithHyphen(currentProduct?.name || 'name')}/${currentProduct?.id}`}>
                        {currentProduct?.name}
                    </PathLink>
                </span>
            </Path>
            <ProductBlock>
                <TitleProductTablet>{currentProduct?.name}</TitleProductTablet>
                <PriceMob>{`${currentCurrency}${numberWithCommas(currentProduct?.price ? roundToTwoDecimals(currentProduct.price) : '')}`}</PriceMob>
                <ViewBlock>
                    <ParentSlider>
                        <SliderBlock>
                            <Carousel>
                                <InvisibleButton onClick={onClickDown}>
                                    <ArrowStyleUp isEnd={carouselIndex === 3} />
                                </InvisibleButton>
                            </Carousel>
                            <ProductMediaCarousel>
                                {slideItems?.map((item, index) => {
                                    return (
                                        <Carousels
                                            key={`CarouselItem${index}`}
                                            isVisible={index >= carouselIndex - 3 && index < carouselIndex}
                                            isSelected={currentImage.index === index}
                                            draggable={false}
                                            onClick={() => {
                                                setCurrentImage({
                                                    ...currentImage,
                                                    image: item,
                                                    index,
                                                });
                                            }}
                                            alt="image"
                                            src={item}
                                        />
                                    );
                                })}
                            </ProductMediaCarousel>
                            <Carousel>
                                <InvisibleButton onClick={onClickUp}>
                                    <ArrowStyle isEnd={carouselIndex === Number(slideItems?.length)} />
                                </InvisibleButton>
                            </Carousel>
                        </SliderBlock>
                        <MainImageContainer>
                            <CapPlace
                                onTouchStart={(e) => {
                                    setSwipeStartPos(e.changedTouches[0].pageX);
                                }}
                                onTouchMove={(e) => {}}
                                onTouchEnd={(e) => {
                                    if (window.innerWidth < 870) {
                                        swipeCarousel(e.changedTouches[0].pageX);
                                    }
                                }}
                                onMouseDown={(e) => {
                                    setSwipeStartPos(e.pageX);
                                }}
                                onMouseUp={(e) => {
                                    if (window.innerWidth < 870) {
                                        swipeCarousel(e.pageX);
                                    }
                                }}
                                draggable={false}
                                alt="placeholder"
                                src={currentImage.image ? currentImage.image : PlaceholderIMG}
                            />
                            <MobileMediaPagination>
                                {slideItems?.map((item, index) => {
                                    return (
                                        <DotButton
                                            key={`DotButton${index}`}
                                            value={index === currentImage.index}
                                            onClick={() => {
                                                setCurrentImage({
                                                    image: item,
                                                    index,
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </MobileMediaPagination>
                        </MainImageContainer>
                    </ParentSlider>
                    <NameBlock>
                        <TitleProduct>{currentProduct?.name}</TitleProduct>
                        <Price>{`${currentCurrency}${numberWithCommas(
                            currentProduct?.price ? roundToTwoDecimals(currentProduct.price) : ''
                        )}`}</Price>
                        <AddButtonContainer>
                            <CounterBox>
                                <CounterComponent
                                    onClickPlus={() => {
                                        if (!currentProduct?.amount_all) return;
                                        if (!(Number(value) + 1 <= currentProduct.amount_all)) return;
                                        setValue(Number(value) + 1);
                                    }}
                                    onClickMinus={() => {
                                        if (Number(value) - 1 < 0) return;
                                        setValue(Number(value) - 1);
                                    }}
                                    children={value + ''}
                                />
                            </CounterBox>
                            <ButtonStyle>
                                <StyledButton
                                    height={'50px'}
                                    disabled={currentProduct?.is_out_of_stock || status}
                                    onClick={() => {
                                        if (!currentProduct?.id) return;
                                        addProductToCart({
                                            product_id: currentProduct?.id,
                                            quantity: value,
                                            user: user,
                                        });
                                    }}
                                >
                                    {currentProduct?.is_out_of_stock ? 'Out of Stock' : 'Add to cart'}
                                </StyledButton>
                            </ButtonStyle>
                        </AddButtonContainer>
                        <QuantityBlock>{`Available stock: ${currentProduct?.amount_now ?? 0}`}</QuantityBlock>
                        <Update>
                            <UpdateImg src={Refresh} alt="refresh" />
                            <p>
                                The price of the product is locked in for 10 minutes, After that, you need to refresh the cart to see the updated
                                price.
                            </p>
                        </Update>
                        <Delivery>
                            <DeliveryIcon src={DeliveryIMG} alt="delivery" />
                            <p>Australia wide postage from $15 with signature and tracking</p>
                        </Delivery>
                        <ShareThisBlock>
                            <h4>Share this</h4>
                            <InlineShareButtons
                                config={{
                                    alignment: 'left', // alignment of buttons (left, center, right)
                                    color: 'social', // set the color of buttons (social, white)
                                    enabled: true, // show/hide buttons (true, false)
                                    font_size: 16, // font size for the buttons
                                    labels: 'cta', // button labels (cta, counts, null)
                                    language: 'en', // which language to use (see LANGUAGES)
                                    networks: [
                                        // which networks to include (see SHARING NETWORKS)
                                        'facebook',
                                        'twitter',
                                        'pinterest',
                                        'email',
                                    ],
                                    padding: 12, // padding within buttons (INTEGER)
                                    radius: 4, // the corner radius on each button (INTEGER)
                                    show_total: true,
                                    size: 40, // the size of each button (INTEGER)

                                    // OPTIONAL PARAMETERS
                                    // image: 'https://bit.ly/2CMhCMC', // (defaults to og:image or twitter:image)
                                    description: 'Bullion Network is selling this', // (defaults to og:description or twitter:description)
                                    title: 'Bullion Network Product', // (defaults to og:title or twitter:title)
                                    message: undefined, // (only for email sharing)
                                    subject: 'Bullion Network Product', // (only for email sharing)
                                    username: '', // (only for twitter sharing)
                                }}
                            />
                        </ShareThisBlock>
                    </NameBlock>
                </ViewBlock>
                <DescriptionBlock>
                    <DescSliderBox>
                        <DescriptionSlider
                            open={isDesc}
                            onClick={() => {
                                setIsDesc(true);
                            }}
                        >
                            Description
                        </DescriptionSlider>
                        <div style={{ borderBottom: ' 4px solid #9FABD9', width: '28px' }} />
                        <DescriptionSliderAdd
                            open={isDesc}
                            onClick={() => {
                                setIsDesc(false);
                            }}
                        >
                            Additional information
                        </DescriptionSliderAdd>
                    </DescSliderBox>
                    {isDesc ? (
                        <DescriptionText dangerouslySetInnerHTML={{ __html: currentProduct?.description ? currentProduct?.description : '' }}>
                            {/*                        {
                                currentProduct?.description
                            }*/}
                        </DescriptionText>
                    ) : (
                        <DescriptionText dangerouslySetInnerHTML={{ __html: currentProduct?.additional_info ? currentProduct?.additional_info : '' }}>
                            {/*                            {
                                currentProduct?.additional_info
                            }*/}
                        </DescriptionText>
                    )}
                </DescriptionBlock>
                <AlsoLike>
                    <AlsoTitle>You may also like</AlsoTitle>
                    <AlsoLikeMap>
                        {recommendedProducts.map((item, index) => {
                            return (
                                <React.Fragment key={`card-index-${index}`}>
                                    <Card
                                        text={item.name}
                                        image={item.medias[0] ? item.medias[0] : PlaceholderIMG}
                                        quantity={item.amount_now}
                                        price={currentCurrency + numberWithCommas(+roundToTwoDecimals(item.price))}
                                        onClick={() => addToCart(item.id)}
                                        is_out_of_stock={item.is_out_of_stock}
                                        link={`/shop/product/${replaceSpacesAndSlashesWithHyphen(item.name)}/${item.id}`}
                                        redirectOnProductPage={() => {
                                            navigate(`/shop/product/${replaceSpacesAndSlashesWithHyphen(item.name)}/${item.id}`, { replace: true });
                                            scrollTo(0, 0);
                                        }}
                                        isApiOff={status}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </AlsoLikeMap>
                </AlsoLike>
            </ProductBlock>
        </Content>
    );
};

const QuantityBlock = styled.div`
    padding-bottom: 10px;
    margin-top: -20px;
    font-weight: 500;
    @media screen and (max-width: 600px) {
        margin-top: 20px;
    }
`;

const InvisibleButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
`;

const AddButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
    @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const MainImageContainer = styled.div`
    display: flex;
    align-items: center;
    height: 630px;
    width: 520px;
    @media (max-width: 1180px) {
        //max-width: 90vw;
        height: 293px;
        width: 293px;
        justify-content: center;
    }
    @media (max-width: 870px) {
        flex-direction: column;
        height: 374px;
        width: 374px;
    }
    @media (max-width: 600px) {
        //height: 100%;
        /*    width: 351px;*/
    }
`;

const DotButton = styled.div<{ value: boolean }>`
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => (props.value ? '#5B73CF' : '#9FABD9')};
    display: flex;
    align-items: center;
`;

const MobileMediaPagination = styled.div`
    display: none;
    @media (max-width: 700px) {
        display: flex;
        gap: 10px;
        margin-top: auto;
    }
`;

const ProductMediaCarousel = styled.div`
    max-height: 530px;
    overflow: hidden;
    /*  overflow-y: auto;*/
`;

const AlsoLike = styled.div``;
const CounterBox = styled.div`
    width: 155px;
    @media (max-width: 600px) {
        //display: none;
    }
`;
const UpdateImg = styled.img`
    width: 50px;
    margin-right: 15px;
    @media (max-width: 900px) {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }
`;
const ParentSlider = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 700px;
    width: 100%;
    gap: 20px;
    @media (max-width: 1180px) {
        //max-width: 90vw;
        max-width: 45vw;
        justify-content: center;
    }
    @media (max-width: 870px) {
        margin-bottom: 10px;
    }
    @media (max-width: 600px) {
        max-width: 100%;
    }
`;
const Carousel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    @media (max-width: 1180px) {
        max-width: 120px;
    }
`;

interface ICarousels {
    isVisible?: boolean;
    isSelected?: boolean;
}

const Carousels = styled.img<ICarousels>`
    width: 190px;
    height: ${(props) => (props?.isVisible ? '160px' : 0)};
    object-fit: cover;
    //display: ${(props) => (props?.isVisible ? 'flex' : 'none')};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${(props) => (props?.isVisible ? '15px 1px' : 0)};
    border: ${(props) => (props.isSelected ? '1px solid #394985' : 'none')};
    cursor: pointer;
    transition: height 0.2s;

    @media (max-width: 1180px) {
        max-width: 106px;
    }

    &:hover {
        box-shadow: 0 0 5px 1px lightgray;
    }

    /*  ::selection{
        background-color: transparent;
    }*/
`;
const NameBlock = styled.div`
    height: 100%;
    max-height: 710px;
    margin-top: 45px;
    @media (max-width: 1180px) {
        max-width: 46vw;
    }
    @media (max-width: 600px) {
        max-width: 90vw;
        margin-top: 0;
    }
`;
const SliderBlock = styled.div`
    @media (max-width: 700px) {
        display: none;
    }
`;
const ButtonStyle = styled.div`
    width: 300px;
    @media (max-width: 1180px) {
        width: 169px;
    }
    @media (max-width: 600px) {
        width: 100%;
    }
`;

interface ArrowParam {
    isEnd?: boolean;
}

const ArrowStyle = styled(Arrow)<ArrowParam>`
    transform: rotate(270deg);
    height: auto;
    width: 20px;
    cursor: pointer;

    & path {
        fill: ${(props) => (props.isEnd ? '#B6B6B6' : '')};
    }
`;
const ArrowStyleUp = styled(ArrowStyle)`
    transform: rotate(90deg);

    & path {
        fill: ${(props) => (props.isEnd ? '#B6B6B6' : '')};
    }
`;
const DescSliderBox = styled.div`
    display: flex;
    max-width: 285px;
`;

const Update = styled.div`
    display: flex;
    background: #e2e6f4;
    border-radius: 3px;
    color: #0b1126;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    max-width: 490px;
    width: 100%;
    min-height: 56px;
    padding: 17px 15px;
    @media (max-width: 1290px) {
        max-width: 420px;
        width: 100%;
    }
    @media (max-width: 1180px) {
        max-width: 320px;
        width: 100%;
        padding: 10px 18px;
    }
    @media (max-width: 600px) {
        width: 83vw;
        margin-top: 20px;
    }
`;

const Delivery = styled.div`
    display: flex;
    border-radius: 3px;
    color: #0b1126;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    max-width: 490px;
    width: 100%;
    min-height: 56px;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 1290px) {
        max-width: 420px;
        width: 100%;
    }
    @media (max-width: 1180px) {
        max-width: 320px;
        width: 100%;
    }
    @media (max-width: 600px) {
        width: 83vw;
        margin-bottom: 20px;
    }
`;

const DeliveryIcon = styled.img`
    width: 75px;
    margin-right: 15px;
    @media (max-width: 900px) {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }
`;

const Price = styled.p`
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    padding-top: 15px;
    @media (max-width: 600px) {
        display: none;
    }
`;
const PriceMob = styled(Price)`
    display: none;
    @media (max-width: 600px) {
        display: block;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }
`;
const TitleProduct = styled.h1`
    /* font-family: Playfair Display, serif; */
    padding: 15px 0;
    font-weight: 900;
    font-size: 40px;
    line-height: 140%;
    border-bottom: 1px solid #9fabd9;
    max-width: 520px;
    width: 100%;

    @media (max-width: 1180px) {
        display: none;
    }
`;
const TitleProductTablet = styled(TitleProduct)`
    display: none;
    @media (max-width: 1180px) {
        display: block;
    }
    @media (max-width: 600px) {
        font-size: 24px;
    }
`;
const CapPlace = styled.img`
    width: 100%;
    object-fit: cover;
    margin: auto;
    max-height: 630px;
    @media (max-width: 870px) {
        width: 230px;
        height: 280px;
        object-fit: contain;
    }
    @media (max-width: 600px) {
        width: 350px;
        height: 350px;
    }
    /*  @media (max-width: 1180px) {
      max-width: 245px;
    }*/
`;
const AlsoLikeMap = styled.div`
    display: flex;
    margin-top: 20px;
    overflow-x: auto;
    gap: 30px;
`;
const AlsoTitle = styled.h2`
    font-family: Inter, serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #0f0927;
    width: 300px;
    border-bottom: 1px solid #9fabd9;
`;
const DescriptionSlider = styled.div<{ open: boolean }>`
    border-bottom: 4px solid ${(props) => (props.open ? '#0F0927' : '#9FABD9')};
    cursor: pointer;
`;
const DescriptionSliderAdd = styled(DescriptionSlider)<{ open: boolean }>`
    border-bottom: 4px solid ${(props) => (props.open ? '#9FABD9' : '#0F0927')};
`;
const DescriptionText = styled.div`
    width: 1240px;
    height: auto;
    background: #e2e6f4;
    color: #0f0927;
    padding: 24px;
    font-family: Inter, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    @media (max-width: 1290px) {
        width: calc(90vw - 7px);
    }
    @media (max-width: 600px) {
        width: 80vw;
    }
`;

const DescriptionBlock = styled.div`
    margin-bottom: 50px;
`;

const ViewBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    @media (max-width: 1180px) {
        max-width: 84vw;
    }
    @media (max-width: 946px) {
        max-width: 100vw;
    }
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        max-width: unset;
    }
`;

const ProductBlock = styled.div`
    height: 100%;
    margin-top: 30px;
`;

const Path = styled.div`
    font-family: Inter, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    flex-direction: row;
    color: #0f0927;
    @media (max-width: 600px) {
        display: none;
    }
`;

const Content = styled.div`
    min-height: 80vh;
    margin-top: 50px;
    height: auto;
    margin-bottom: 170px;
    @media (max-width: 1290px) {
        max-width: calc(100vw - 32px);
        width: 100%;
        padding: 0 15px;
    }
`;

const PathLink = styled(Link)`
    text-decoration: none;
    color: black;
`;

const ShareThisBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 600px) {
        padding-bottom: 30px;
    }
`;

export default ProductPage;
