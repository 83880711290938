import React, {useState} from 'react';
import EyesHandler from "./EyesComponent";
import TextFields from "./TextFields";
import {FIELD_HEIGHT} from "../components/auth/SignUp";
import styled from "styled-components";

interface IPasswordFieldData {
    placeholder: string
    setPasswordData: (value: string) => void
    password: string
    errorPassword: string
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

// Test

const PasswordField: React.FC<IPasswordFieldData> = ({placeholder, password, setPasswordData, errorPassword, onKeyDown}) => {
    const [isShowPassword, setIsShowPassword] = useState(false)
    return (
        <PasswordContainer>
            <EyePasswordContainer>
                <EyesHandler
                    value={isShowPassword}
                    width={"22px"}
                    height={"22px"}
                    onClick={() => setIsShowPassword(!isShowPassword)}
                />
            </EyePasswordContainer>
            <TextFields
                value={password}
                type={isShowPassword ? "text" : "password"}
                onChange={(e => {
                    const password = e.target.value
                    setPasswordData(password)
                })}
                height={FIELD_HEIGHT}
                placeholder={placeholder}
                textError={errorPassword}
                onKeyDown={onKeyDown}
            />
        </PasswordContainer>
    );
};

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const EyePasswordContainer = styled.div`
  position: relative;
  margin-bottom: -20px;
  display: flex;
  align-items: center;
  justify-content: right;
  top: 20px;
  padding-right: 10px;
`

export default PasswordField;