import React, { useEffect } from 'react';
import styled from 'styled-components';
import StyledButton from '../../StyledButton';

interface ISignUpNotification {
    text?: string;
    closeModal?: () => void;
    buttonText?: string;
}

const SignUpNotification: React.FC<ISignUpNotification> = ({
    text = 'An email was sent to the specified email address. Follow the link in the email to complete the\n' + '                registration.',
    closeModal,
    buttonText = 'Open Email',
}) => {
    const goToMail = () => {
        window.location.href = 'mailto:';
        if (closeModal) {
            closeModal();
        }
    };

    useEffect(() => {
        return () => {
            if (closeModal) {
                closeModal();
            }
        };
    }, []);

    return (
        <>
            <ModalTitle>Sign Up</ModalTitle>
            <Description>{text}</Description>
            <ButtonContainer>
                <StyledButton onClick={goToMail} height={'50px'}>
                    {buttonText}
                </StyledButton>
            </ButtonContainer>
        </>
    );
};

const ModalTitle = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    text-align: center;

    color: #0b1126;
    margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
    margin-top: 20px;
`;

const Description = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    min-height: 100px;
    color: #828282;
`;

export default SignUpNotification;
