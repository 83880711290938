import React, { useEffect, useState } from 'react';
import { Heading1, Heading4, Heading5 } from '../../ui/HeadingTags';
import styled from 'styled-components';
import { ReactComponent as LocationIcon } from '../../assets/svgs/footer_location_icon.svg';
import { ReactComponent as CallIcon } from '../../assets/svgs/footer_call_icon.svg';
import { ReactComponent as MailIcon } from '../../assets/svgs/footer_message_icon.svg';
import Loading from '../../components/loading';
import MapboxOffice from '../../components/mapbox/MapboxOffice';
import TextFields from '../../ui/TextFields';
import MultilineInput from '../../ui/components/MultilineInput';
import { Button } from '../../ui/StyledButton';
import { useDispatch } from 'react-redux';
import { contactUs } from '../../store/actions/userActions';
import { showSnackbar } from '../../store/actions/snackbarActions';
import { SnakeTypes } from '../../ui/components/modals/SnakeBar';
import contactUsBannerIMG from '../../assets/contact-us-banner.png';
import { Link } from 'react-router-dom';
import Youtube from '../../assets/svgs/contact_us_youtube.svg';
import Twitter from '../../assets/svgs/contact_us_twitter.svg';
import Facebook from '../../assets/svgs/contact_us_facebook.svg';

interface IContactUs {
    statics: any;
    isLoading: boolean;
}

interface IFormState {
    message: string;
    email: string;
    name: string;
    company?: string;
    phone: string;
}

interface IFormErrorState {
    message: string;
    email: string;
    name: string;
    company: string;
    phone: string;
}

const mediaInfo = [
    {
        icon: Youtube,
        link: '/',
    },
    {
        icon: Twitter,
        link: '/',
    },
    {
        icon: Facebook,
        link: 'https://www.facebook.com/people/Bullion-Network/100083234923655/',
    },
];

const ContactUsComponent: React.FC<IContactUs> = ({ statics, isLoading }): JSX.Element => {
    const dispatch = useDispatch();

    const [formState, setFormState] = useState<IFormState>({
        message: '',
        name: '',
        email: '',
        company: '',
        phone: '',
    });

    const [formErrorState, setFormErrorState] = useState<IFormErrorState>({
        message: '',
        name: '',
        email: '',
        company: '',
        phone: '',
    });

    const submitHandler = () => {
        if (formState.name && formState.email && formState.message && formState.phone) {
            dispatch(
                contactUs({
                    name: formState.name,
                    email: formState.email,
                    message: formState.message,
                    company: formState.company,
                    phone: formState.phone,
                })
            );
            setFormState({
                message: '',
                name: '',
                email: '',
                company: '',
                phone: '',
            });
        } else {
            dispatch(showSnackbar({ content: 'Fill all the fields, please!', type: SnakeTypes.warning, ms: 4000 }));
        }
    };
    const footerInfo = [
        {
            icon: <LocationStyle />,
            text: 'Suite 517 level 5 220 Collins St Manchester Unity Building Melbourne 3004',
        },
        {
            icon: <CallStyle />,
            text: '03.8618.6941',
        },
        {
            icon: <MailStyle />,
            text: 'sales@bullionnetwork.com.au',
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Wrapper>
            {statics.Header && (
                <div>
                    <Heading1>{statics.Header.data}</Heading1>
                    <Container>
                        <TextBlock>
                            {/* <h2>{statics.SmallHeader.data} </h2> */}
                            <Heading5 dangerouslySetInnerHTML={{ __html: statics.Text.data }} />
                            <>
                                {footerInfo.map((item, index) => {
                                    return (
                                        <LinkBlock key={`links-index-${index}`}>
                                            <LinksLogoBox>{item.icon}</LinksLogoBox>
                                            <LinksInfo>{item.text}</LinksInfo>
                                        </LinkBlock>
                                    );
                                })}
                            </>
                            <ContactForm.Wrapper>
                                <ContactForm.Title>Write us a message</ContactForm.Title>
                                <ContactForm.FormWrapper>
                                    <ContactForm.NameField
                                        type="text"
                                        height="60px"
                                        placeholder="Name *"
                                        required={true}
                                        value={formState.name}
                                        textError={formErrorState.name}
                                        onChange={(e) => setFormState({ ...formState, name: e.target.value })}
                                    />
                                    <ContactForm.NameField
                                        type="phone"
                                        height="60px"
                                        placeholder="Phone *"
                                        required={true}
                                        value={formState.phone}
                                        textError={formErrorState.phone}
                                        onChange={(e) => setFormState({ ...formState, phone: e.target.value })}
                                    />
                                    <ContactForm.EmailField
                                        type="email"
                                        height="60px"
                                        placeholder="Email Address *"
                                        required={true}
                                        value={formState.email}
                                        textError={formErrorState.email}
                                        onChange={(e) => setFormState({ ...formState, email: e.target.value })}
                                    />
                                    <ContactForm.NameField
                                        type="text"
                                        height="60px"
                                        placeholder="Company"
                                        value={formState.company}
                                        textError={formErrorState.company}
                                        onChange={(e) => setFormState({ ...formState, company: e.target.value })}
                                    />

                                    <ContactForm.MessageField
                                        rows={15}
                                        height="245px"
                                        placeholder="Message *"
                                        required={true}
                                        value={formState.message}
                                        textError={formErrorState.message}
                                        onChange={(e) =>
                                            setFormState({
                                                ...formState,
                                                message: e.target.value,
                                            })
                                        }
                                    />
                                    <Button style={{ height: '60px' }} onClick={submitHandler}>
                                        Send
                                    </Button>
                                </ContactForm.FormWrapper>
                            </ContactForm.Wrapper>
                        </TextBlock>
                        <BigCapture>
                            <MapboxOffice width={'100%'} height={'600px'} />
                            <BannerWrapper>
                                <BannerLink to={'/subscribe_to_newsletter'}>
                                    <Banner src={contactUsBannerIMG} alt="banner"/>
                                </BannerLink>
                            </BannerWrapper>
                            <Media>
                                {mediaInfo.map((item, index) => {
                                    return (
                                        <MediaLinks key={`links-index-${index}`} href={`${item.link}`} target="_blank">
                                            <MediaIcon src={item.icon} alt="social-network"/>
                                        </MediaLinks>
                                    );
                                })}
                            </Media>
                            <p style={{fontSize: "18px"}}>
                                <span>Have questions? You may find answers on our</span>
                                <LinkFAQ to={'/faq'}>
                                    FAQ page
                                </LinkFAQ>
                            </p>
                        </BigCapture>
                    </Container>
                </div>
            )}
        </Wrapper>
    );
};
const LinksInfo = styled.div`
    margin-left: 8px;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #0b1126;
    max-width: 298px;
`;

const BigCapture = styled.div`
    /* height: 420px; */
    width: 40vw;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
    @media (max-width: 1290px) {
        width: 55vw;
    }
    @media (max-width: 900px) {
        width: 95vw;
    }
`;

const LinkBlock = styled.div`
    display: flex;
    align-items: start;
    margin-bottom: 12px;
`;

const LinksLogoBox = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Wrapper = styled.div`
    min-height: 200px;
    @media (max-width: 1290px) {
        max-width: 95vw;
        width: 100%;
        padding: 0 10px;
    }
`;
const TextBlock = styled.div`
    margin-right: 20px;
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 900px) {
        width: 95vw;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 900px) {
        flex-direction: column;
    }
`;
const LocationStyle = styled(LocationIcon)`
    & * {
        fill: #f8c882;
    }
`;
const CallStyle = styled(CallIcon)`
    & * {
        fill: #f8c882;
    }
`;
const MailStyle = styled(MailIcon)`
    & * {
        fill: #f8c882;
    }
`;

const ContactForm = {
    Wrapper: styled.div`
        width: 100%;
        display: flex;
        /* align-items: center;
        justify-content: center; */
        margin-top: 40px;
        flex-direction: column;
        @media (max-width: 900px) {
            margin-bottom: 40px;
        }
    `,
    FormWrapper: styled.div`
        max-width: 520px;
        width: 100%;
        display: flex;
        gap: 30px;
        flex-direction: column;
    `,
    Title: styled.h2`
        font-weight: bold;
    `,
    NameField: TextFields,
    EmailField: TextFields,
    MessageField: MultilineInput,
};

const BannerWrapper = styled.div``;

const Banner = styled.img`
    max-width: 100%;
`;

const BannerLink = styled(Link)`
    text-decoration: none;
`;

const Media = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 20px;

    @media (max-width: 900px) {
        display: none;
    }
`;

const MediaLinks = styled.a`
    /* margin-right: 21px; */
`;

const MediaIcon = styled.img`
    width: 48px;
    height: 48px;
`;
const LinkFAQ = styled(Link)`
    font-size: 18px;
    line-height: 28px;

    text-decoration: none;
    color: #9fabd9;

    padding-left: 3px;

    @media (max-width: 460px) {
        line-height: 21px;
    }

    @media (max-width: 460px) {
        line-height: 20px;
        font-size: 16px;
    }
`;
export default ContactUsComponent;
