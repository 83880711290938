import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import StyledCloseButton from '../../StyledCloseButton';
import { Transition, TransitionStatus } from 'react-transition-group';
import { useUserActions } from '../../../hooks/useActions';
import { useDispatch } from 'react-redux';

const modalRootElement = document.getElementById('modal');

export interface IModalElement {
    closeModal?: () => void;
}

interface IModalProps {
    show: boolean;
    setShow: (param: boolean) => void;
    ModalElement: React.FC<IModalElement>;
    marginTop?: string;
    isSearch?: boolean;
    onClose?: () => void;
}

const Modal: React.FC<IModalProps> = ({ ModalElement, show, setShow, marginTop, isSearch, onClose }) => {
    const element = useMemo(() => document.createElement('div'), []);
    const dispatch = useDispatch();
    const closeModal = () => {
        setShow(false);
        clearUserError();
    };
    const { clearUserError } = useUserActions();

    useEffect(() => {
        if (show) {
            modalRootElement?.appendChild(element);
            const html = document.querySelector('html');
            if (html) html.style.overflow = 'hidden';

            return () => {
                if (html) html.style.overflow = '';
                modalRootElement?.removeChild(element);
                if (onClose) {
                    onClose();
                }
            };
        }
    }, [show]);

    if (show) {
        return (
            <Transition in={show} timeout={100} appear>
                {(state: TransitionStatus) => {
                    return (
                        <React.Fragment>
                            {createPortal(
                                <ModalWindow>
                                    <ModalShadow onClick={closeModal} />
                                    {!isSearch ? (
                                        <ModalContent state={state} marginTop={marginTop}>
                                            <CloseButtonContainer>
                                                <StyledCloseButton onClick={closeModal} />
                                            </CloseButtonContainer>
                                            <ModalElement closeModal={closeModal} />
                                        </ModalContent>
                                    ) : (
                                        <ModalContentSearch state={state}>
                                            <ModalElement closeModal={closeModal} />
                                        </ModalContentSearch>
                                    )}

                                    <div style={{ height: 60 }}></div>
                                </ModalWindow>,
                                element
                            )}
                        </React.Fragment>
                    );
                }}
            </Transition>
        );
    } else return null;
};

const ModalWindow = styled.div`
    font-family: Inter;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    max-height: 100%;
    overflow-y: auto;
    z-index: 6000;
`;

const ModalShadow = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.35);
    padding: 60px 15px;
`;

interface IModalContentProps {
    marginTop?: string;
    state: TransitionStatus;
}

const ModalContentSearch = styled.div<IModalContentProps>`
    transition: opacity 0.2s linear;
    opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
    margin: ${(props) => (props.marginTop ? props.marginTop : '80px')} auto auto;
    z-index: 1001;
`;

const ModalContent = styled.div<IModalContentProps>`
    transition: opacity 0.2s linear;
    opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
    margin: ${(props) => (props.marginTop ? props.marginTop : '80px')} auto auto;
    max-width: 520px;
    padding: 0 25px 25px;
    max-height: 1300px;
    border-radius: 6px;
    background-color: #ffffff;
    z-index: 1001;
`;

const CloseButtonContainer = styled.div`
    display: flex;
    justify-content: right;
    position: relative;
    right: 0;
    top: 30px;
    /*  height: 18px;
    width: 18px;
  
  
  
    border: none;
  
  */
    /*  background: none;
    cursor: pointer;
    transition: .3s;
    outline: none;*/

    /*  &:hover {
      transition: .3s;
      transform: rotate(90deg);
    }*/
`;

export default Modal;
