import React from 'react';
import styled from 'styled-components';
import StyledButton from '../../ui/StyledButton';
import { Heading2, Heading3 } from '../../ui/HeadingTags';
import { ILocalCart } from '../../api/cart/cartTypes';
import loadingImg from '../../assets/loading.svg';
import Loading from '../loading';
// import {useTypedSelector} from "../../hooks/useSelector";
import { roundToTwoDecimals } from '../../helpers/round';

interface IOrder {
    currentCartData: ILocalCart | undefined;
    onClickCreateOrder: () => void;
    isPassCaptcha: boolean;
    isAgree: boolean;
    haveProductsInCart: boolean;
    loading: boolean;
}

const YourOrder: React.FC<IOrder> = ({ isAgree, isPassCaptcha, currentCartData, onClickCreateOrder, haveProductsInCart, loading }) => {
    //const {loading} = useTypedSelector(state => state.orderReducer)

    if (!currentCartData) {
        return <Loading />;
    }

    return (
        <Wrapper>
            <HeadingOrder>Your order</HeadingOrder>
            <Table>
                <tbody>
                    {currentCartData?.products.map((item, index) =>
                        item.quantity > 1 ? (
                            <Tr key={`fullPrice ${index}`}>
                                <TdLeft>
                                    {item.name}*{item.quantity}
                                </TdLeft>
                                <TdRight>
                                    ${roundToTwoDecimals(item.price)}*{item.quantity}
                                </TdRight>
                            </Tr>
                        ) : (
                            <Tr key={`price ${index}`}>
                                <TdLeft>{item.name}</TdLeft>
                                <TdRight>${roundToTwoDecimals(item.price)}</TdRight>
                            </Tr>
                        )
                    )}
                    <Tr>
                        <TdLeft>Subtotal</TdLeft>
                        <TdRight>${roundToTwoDecimals(currentCartData?.sub_total)}</TdRight>
                    </Tr>
                    <Tr>
                        <TdLeft>Tax</TdLeft>
                        <TdRight>${roundToTwoDecimals(currentCartData?.tax)}</TdRight>
                    </Tr>
                    <Tr>
                        <TdLeft>
                            <Heading>Total</Heading>
                        </TdLeft>
                        <TdRight>
                            <Heading>
                                ${roundToTwoDecimals(+roundToTwoDecimals(currentCartData.sub_total) + +roundToTwoDecimals(currentCartData.tax))}
                            </Heading>
                        </TdRight>
                    </Tr>
                </tbody>
            </Table>

            <ButtonWrap>
                <StyledButton
                    disabled={!isPassCaptcha || !isAgree || !haveProductsInCart || loading}
                    onClick={() => {
                        onClickCreateOrder();
                    }}
                    backgroundColor={loading ? 'rgb(91, 115, 207)' : ''}
                    height={'50px'}
                >
                    {loading ? <img src={loadingImg} width="48" height="48" alt="loading"/> : 'Place order '}
                </StyledButton>
            </ButtonWrap>
        </Wrapper>
    );
};

export default YourOrder;

const Wrapper = styled.div`
    width: 410px;
    height: 100%;
    box-shadow: 0px 3px 14px -2px rgba(91, 115, 207, 0.24);
    text-align: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 18px 25px 15px 25px;

    @media (max-width: 768px) {
        width: 100%;
        text-align: start;
        margin-bottom: 63px;
        padding: 12px;
    }
    @media (max-width: 375px) {
        text-align: center;
        margin-bottom: 50px;
        padding: 11px 7px;
    }
`;
const HeadingOrder = styled(Heading2)`
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
    }
    @media (max-width: 375px) {
        font-size: 18px;
        line-height: 21px;
    }
`;
const ButtonWrap = styled.div`
    width: 360px;
    margin: 0 auto;
    margin-right: auto;
    @media (max-width: 768px) {
        width: 324px;
        margin: 0;
    }
    @media (max-width: 375px) {
        width: 100%;
    }
`;
const Table = styled.table`
    // margin-top: 31px;
    width: 100%;
    margin-bottom: 16px;
    align-self: center;
    border-collapse: collapse;
`;
const Tr = styled.tr`
    height: 36px;
    vertical-align: middle;
    border-width: 0 0 1px 0;
    border-color: #9fabd9;
    border-style: solid;

    &:last-child {
        border: none;
    }

    @media (max-width: 375px) {
        border: none;
    }
`;
const TdLeft = styled.td`
    text-align: start;
    width: 100%;
`;
const TdRight = styled.td`
    text-align: end;
`;
const Heading = styled(Heading3)`
    font-weight: 700;
`;
