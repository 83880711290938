import React, { useEffect, useState } from 'react';
import { Heading1, Heading4, Heading5 } from '../../ui/HeadingTags';
import styled from 'styled-components';
import Loading from '../../components/loading';

interface IPrivacy {
    statics: any;
    isLoading: boolean;
}

const TermsAndConditionsComponent: React.FC<IPrivacy> = ({ statics, isLoading }): JSX.Element => {
    const [loading, setLoading] = useState(isLoading);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    if (loading) {
        return <Loading />;
    }

    return (
        <Wrapper>
            {statics.Header && (
                <div>
                    <Heading1>{statics.Header.data}</Heading1>
                    <Container>
                        <div>{statics?.Text && <Heading5 dangerouslySetInnerHTML={{ __html: statics.Text.data }} />}</div>
                    </Container>
                </div>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    min-height: 200px;
    @media (max-width: 1290px) {
        max-width: 95vw;
        width: 100%;
        padding: 0 10px;
    }
`;

const Container = styled.div`
    display: flex;
`;
export default TermsAndConditionsComponent;
