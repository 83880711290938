import * as UserAction from '../store/actions/userActions'
import * as MetalAction from '../store/actions/metalsActions'
import * as CartAction from '../store/actions/cartActions'
import * as ShopAction from '../store/actions/shopActions'
import * as SearchAction from '../store/actions/searchActions'
import * as OrderAction from '../store/actions/orderActions'
import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";

export const useUserActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(UserAction, dispatch)
}

export const useMetalActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(MetalAction, dispatch)
}

export const useCartActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(CartAction, dispatch)
}

export const useShopActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(ShopAction, dispatch)
}

export const useOrderActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(OrderAction, dispatch)
}

export const useSearchActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(SearchAction, dispatch)
}