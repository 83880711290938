import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container } from '../ui/container';
import Layout from '../components/Layout';
import { Heading1, Heading2, Heading4, Heading5 } from '../ui/HeadingTags';
import BackArrow from 'assets/svgs/ArrowBack.svg';
import DangerIcon from '../assets/svgs/checkout_warning_icon.svg';
import CheckoutForm from '../components/checkout/CheckoutComponentCheckoutForm';
import YourOrder from '../components/checkout/CheckoutComponentYourOrder';
import { Link, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../hooks/useSelector';
import { ILocalCart } from '../api/cart/cartTypes';
import { useOrderActions } from '../hooks/useActions';
import { checkIsAgreeWithPolicy, FIELD_TYPE, validateField } from '../helpers/registerValidation';
import { formCompanyOrderObject, formNaturalOrderObject } from '..//helpers/formCreateOrderObject';

export interface IOrderUserData {
    first_name?: string;
    last_name?: string;
    abn?: string;
    company_name?: string;
    address: string;
    phone: string;
    email: string;
    note?: string;
    isAgreeWithPolicy: boolean;
}

export interface IErrorOrderData {
    first_name: string;
    last_name: string;
    abn: string;
    company_name: string;
    address: string;
    phone: string;
    email: string;
    isAgreeWithPolicy: string;
}

const Checkout = () => {
    const navigate = useNavigate();

    const { user } = useTypedSelector((state) => state.userReducer);
    const { orderInfo, loading } = useTypedSelector((state) => state.orderReducer);
    const { createOrder } = useOrderActions();

    const [currentCartData, setCurrentCartData] = useState<ILocalCart | undefined>(undefined);
    const [isPassCaptcha, setIsPassCaptcha] = useState(false);

    const [userData, setUserData] = useState<IOrderUserData>({
        first_name: user?.first_name ? user?.first_name : '',
        last_name: user?.surname ? user?.surname : '',
        abn: user?.company_name ? user?.company_code : '',
        company_name: user?.company_name ? user?.company_name : '',
        address: user?.delivery_address ? user?.delivery_address : '',
        phone: user?.phone ? user?.phone : '',
        email: user?.email ? user?.email : '',
        note: '',
        isAgreeWithPolicy: false,
    });

    const [errorState, setErrorState] = useState<IErrorOrderData>({
        first_name: '',
        last_name: '',
        abn: '',
        company_name: '',
        address: '',
        phone: '',
        email: '',
        isAgreeWithPolicy: '',
    });

    useEffect(() => {
        const currentData = localStorage.getItem('cart');
        if (currentData) {
            const filteredCurrentData = JSON.parse(currentData);
            filteredCurrentData.products = filteredCurrentData.products.filter((pr: { quantity: number }) => pr.quantity > 0);
            setCurrentCartData(filteredCurrentData);
        }

        return () => {
            localStorage.removeItem('cart');
        };
    }, []);

    useEffect(() => {
        if (orderInfo) {
            navigate('/after_payment');
        }
    }, [orderInfo]);

    const onClickCreateOrder = () => {
        if (!currentCartData || loading === true) return;
        const isNaturalPerson = user?.role === 'natural';

        let obj: IErrorOrderData = {
            first_name: '',
            last_name: '',
            abn: '',
            company_name: '',
            address: validateField(userData.address, FIELD_TYPE.deliveryAddress),
            phone: validateField(userData.phone, FIELD_TYPE.phone),
            email: validateField(userData.email, FIELD_TYPE.email),
            isAgreeWithPolicy: checkIsAgreeWithPolicy(userData.isAgreeWithPolicy),
        };
        if (isNaturalPerson) {
            if (!userData.first_name || !userData.last_name) {
                return;
            }
            obj = {
                ...obj,
                first_name: validateField(userData.first_name, FIELD_TYPE.firstName),
                last_name: validateField(userData.last_name, FIELD_TYPE.lastName),
            };
        } else {
            if (!userData.company_name || !userData.abn) {
                return;
            }
            obj = {
                ...obj,
                company_name: validateField(userData.company_name, FIELD_TYPE.companyName),
                abn: validateField(userData.abn, FIELD_TYPE.abnAcn),
            };
        }
        if (Object.values(obj).some((x) => x !== '')) {
            setErrorState(obj);
        } else {
            const products = currentCartData.products.map((item) => ({
                product_id: item.id,
                quantity: item.quantity,
            }));
            let formedObject;
            if (isNaturalPerson) {
                formedObject = formNaturalOrderObject(userData, products);
                if (formedObject) {
                    createOrder(formedObject);
                } else {
                    setErrorState({
                        ...errorState,
                        first_name: 'Please check this field',
                        last_name: 'Please check this field',
                    });
                }
            } else {
                formedObject = formCompanyOrderObject(userData, products);
                if (formedObject) {
                    createOrder(formedObject);
                    navigate('/after_payment');
                } else {
                    setErrorState({
                        ...errorState,
                        company_name: 'Please check this field',
                        //abn: 'Please check this field',
                    });
                }
            }
        }
    };

    return (
        <Layout>
            <Container>
                <Content>
                    <BackBox to={'/cart'}>
                        <img alt="back" src={BackArrow} onClick={() => {}} /> Back to Cart
                    </BackBox>
                    <HeadingCheckout>Checkout</HeadingCheckout>
                    <Wrapper>
                        <Left>
                            <Warning>
                                <WarningIcon src={DangerIcon} alt="DangerIcon" />
                                <WarningText>
                                    You have 48 hours to pay for your order. A receipt will be sent to your email, which will contain the data for the
                                    payment of the order.
                                </WarningText>
                            </Warning>
                            {/*<Addresses />*/}
                            <CheckoutForm
                                errorState={errorState}
                                user={user}
                                userData={userData}
                                setUserData={(value, key) => {
                                    setUserData({ ...userData, [key]: value });
                                }}
                                setIsPassCaptcha={setIsPassCaptcha}
                            />
                            {/*<HeadingPayment>Payment method</HeadingPayment>
                            <PaymentMethod>
                                <CheckboxHandler
                                    value={true}
                                    width={'24px'}
                                    height={'24px'}/>
                                <PaymentMethodText>
                                    <Title>Direct bank transfer</Title>
                                    <Description>Make your payment directly into our bank account. Please use your Order
                                        ID as the payment reference. Your order will not be shipped until the funds have
                                        cleared in our account.</Description>
                                </PaymentMethodText>
                            </PaymentMethod>*/}
                            <PrivacyPolicyText>
                                Your personal data will be used to process your order, support your experience throughout this website, and for other
                                purposes described in our privacy policy.
                                {/* <PrivacyPolicyLink href="/"> privacy policy.</PrivacyPolicyLink> */}
                            </PrivacyPolicyText>
                        </Left>
                        <YourOrder
                            isAgree={userData.isAgreeWithPolicy}
                            isPassCaptcha={isPassCaptcha}
                            currentCartData={currentCartData}
                            onClickCreateOrder={onClickCreateOrder}
                            haveProductsInCart={currentCartData !== undefined}
                            loading={loading}
                        />
                    </Wrapper>
                </Content>
            </Container>
        </Layout>
    );
};

export default Checkout;

const Content = styled.div`
    @media (max-width: 1290px) {
        padding: 0 16px;
    }
    @media (max-width: 600px) {
        padding: 0 12px;
    }
`;
const BackBox = styled(Link)`
    text-decoration: none;
    margin: 51px 0;
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #0f0927;
    display: flex;
    align-items: center;
    gap: 13.5px;
`;
const HeadingCheckout = styled(Heading1)`
    @media (max-width: 768px) {
        font-size: 36px;
        line-height: 50px;
    }
`;
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
const Left = styled.div`
    width: 100%;
    margin-right: 30px;
    margin-bottom: 102px;

    @media (max-width: 768px) {
        margin-right: 0px;
        margin-bottom: 39px;
    }
`;
const Warning = styled.div`
    display: flex;
    align-items: center;
    background-color: #e2e6f4;
    box-sizing: border-box;
    margin-bottom: 17px;
    border-radius: 3px;
    padding: 10px 29px 10px 8px;

    @media (max-width: 768px) {
        padding: 2px 50px 20px 8px;
        margin-bottom: 26px;
    }
    @media (max-width: 375px) {
        padding: 15px 10px 14px 8px;
        margin-bottom: 21px;
    }
`;
const WarningIcon = styled.img`
    width: 60px;
    height: 60px;
    background-color: #e2e6f4;
    margin-right: 12px;

    @media (max-width: 375px) {
        width: 44px;
        height: 44px;
    }
`;
const WarningText = styled(Heading4)`
    font-weight: 600;
    color: #eb5757;
`;
const HeadingPayment = styled(Heading2)`
    @media (max-width: 375px) {
        font-size: 18px;
        line-height: 21px;
    }
`;
const PaymentMethod = styled.div`
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    background-color: #e2e6f4;
    border-radius: 6px;
    padding: 16px 65px 25px 16px;
`;
const PaymentMethodText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-left: 10px;
`;
const Title = styled(Heading5)`
    line-height: 24px;
    margin: 0;
`;
const Description = styled(Heading5)`
    font-size: 14px;
    color: #525563;
    line-height: 17px;
    margin: 0;
`;
const PrivacyPolicyText = styled(Heading5)`
    line-height: 24px;
    margin-top: 27px;
`;
const PrivacyPolicyLink = styled.a`
    font-weight: 600;
    color: #f8c882;
`;
