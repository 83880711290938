import React from 'react';
import styled from 'styled-components';
import StyledButton from '../../StyledButton';
import { useDispatch } from 'react-redux';

export interface ICheckParams {
    description?: string;
}

const CheckYourEmail: React.FC<ICheckParams> = ({ description }) => {
    const dispatch = useDispatch()
    const goToMail = () => {
        window.location.href = 'mailto:';
    };
    return (
        <>
            <ModalTitle>Check Your Email</ModalTitle>
            <Description>
                {description
                    ? description
                    : `
                        The data has been successfully changed. A message has been sent to your entered email. To confirm the email change, follow the link in the email.
                        `}
            </Description>
            <ButtonContainer>
                <StyledButton onClick={goToMail} height={'50px'}>
                    Open email
                </StyledButton>
            </ButtonContainer>
        </>
    );
};

const ModalTitle = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    text-align: center;

    color: #0b1126;
    margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
    margin-top: 20px;
`;

const Description = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: #828282;
`;

export default CheckYourEmail;
