import React from 'react';
import styled from 'styled-components';
import Close from '../assets/svgs/button_close_icon.svg';

type StyledCloseButtonProps = {
    onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
};
const StyledCloseButton = (props: StyledCloseButtonProps) => {
    return (
        <Wrapper>
            <Button src={Close} alt="close" onClick={props.onClick} />
        </Wrapper>
    );
};

export default StyledCloseButton;
export const Wrapper = styled.div``;
export const Button = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;
