import React, { useState } from 'react';
import TextFields from '../../TextFields';
import { FIELD_HEIGHT } from '../../../components/auth/SignUp';
import StyledButton from '../../StyledButton';
import styled from 'styled-components';
import { FIELD_TYPE, validateField } from '../../../helpers/registerValidation';

interface ICreateProps {
    createAction: (newValue: string) => void;
}

const CreateActualAddress: React.FC<ICreateProps> = ({ createAction }) => {
    const [currentValue, setCurrentValue] = useState('');
    const [errorText, setErrorText] = useState('');

    const editAddress = () => {
        let check = validateField(currentValue, FIELD_TYPE.actualAddress);
        if (check === '') {
            createAction(currentValue);
        } else {
            setErrorText(check);
        }
    };

    return (
        <>
            <ModalTitle>Create Actual Address</ModalTitle>
            <Description>Address format: house number, street name, district name, state, zip code, town</Description>
            <FieldContainer>
                <TextFields
                    value={currentValue}
                    type={'text'}
                    height={FIELD_HEIGHT}
                    placeholder={'Actual Address'}
                    onChange={(e) => {
                        const currentValue = e.target.value;
                        setCurrentValue(currentValue);
                        setErrorText(validateField(currentValue, FIELD_TYPE.actualAddress));
                    }}
                    textError={errorText}
                />
            </FieldContainer>
            <ButtonContainer>
                {errorText === '' ? (
                    <StyledButton height={'50px'} onClick={editAddress}>
                        Save
                    </StyledButton>
                ) : (
                    <StyledButton height={'50px'} onClick={editAddress} disabled={true}>
                        Save
                    </StyledButton>
                )}
            </ButtonContainer>
        </>
    );
};

const ModalTitle = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    text-align: center;

    color: #0b1126;

    margin-bottom: 5px;
`;

const FieldContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 10px;
`;

const ButtonContainer = styled.div`
    margin-top: 20px;
`;

const Description = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #828282;
`;

export default CreateActualAddress;
