import React, {useEffect, useState} from 'react';
import chbTrue from "../assets/svgs/checkbox_true_icon.svg";
import chbFalse from "../assets/svgs/checkbox_false_icon.svg";
import styled from "styled-components";

type CheckboxProps = {
    value?: boolean,
    width: string,
    height: string
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void,
}
const CheckboxHandler = (props: CheckboxProps) => {
    const [iconBox, setIconBox] = useState(chbFalse)
    useEffect(() => {
        if (props.value) {
            setIconBox(chbTrue);
        } else {
            setIconBox(chbFalse);
        }
    }, [props.value])

    return (

        <Wrapper  width={props.width} height={props.height} onClick={(e) => {

            if (props.onClick) {
                props.onClick(e);
            }
        }}>
            <Check alt="check" src={iconBox}/>
        </Wrapper>
    );
};
const Wrapper = styled.div<{ width: string, height: string }>`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  & > img {
    width: ${props => props.width};
    height: ${props => props.height};
    line-height: 0;
  }
 
`
const Check = styled.img`
  cursor: pointer;
  border: 0;

`

export default CheckboxHandler;
