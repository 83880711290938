import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import logo from '../../assets/svgs/footerHeader_logo_img.svg';
import '../../index.css';
import SearchIcon from '../../assets/svgs/header_search_icon.svg';
import CartIcon from '../../assets/svgs/header_cart_icon.svg';
import UserImage from '../../assets/header_userImage_icon.png';
import Hamburger from '../../assets/svgs/header_hamburger_icon.svg';
import LogoutLogo from '../../assets/svgs/header_logout_icon.svg';
import LogIn from '../auth/LogIn';
import Modal from '../../ui/components/modals/Modal';
import SignUp from '../auth/SignUp';
import { useCartActions, useUserActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useSelector';
import { Link } from 'react-router-dom';
import { useClickAway } from '../../hooks/useClickAway';
import SearchInput from '../../ui/components/SearchInput';
import ModalSearch from '../../ui/components/modals/ModalSearch';
import ChartsDropdown from './ChartsDropdown';
import FirebaseService from '../../services/firebase-service';
import ChartIcon from '../../assets/svgs/chart.svg';
import { getMetalPrices } from '../../store/actions/metalsActions';
import useSocketDispatchUpdatePrice from '../../hooks/useSocketDispatch';
import { useDispatch } from 'react-redux';
import { setResponseMessage } from '../../store/actions/userActions';

interface IShow {
    height: string;
    width: string;
    display: string;
}

interface IHeaderProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    show: IShow;
    setShow: (show: IShow) => void;
    openModalSignIn: boolean;
    openModalLogIn: boolean;
    setOpenModalSignIn: (open: boolean) => void;
    setOpenModalLogIn: (open: boolean) => void;
}

const Header: React.FC<IHeaderProps> = ({ openModalSignIn, openModalLogIn, setOpenModalSignIn, setOpenModalLogIn, open, setOpen, show, setShow }) => {
    const { user, message } = useTypedSelector((state) => state.userReducer);
    const { cart } = useTypedSelector((state) => state.cartReducer);

    const { closeSecondStepLogIn } = useUserActions();

    const { logout } = useUserActions();
    const { getCart } = useCartActions();

    const dispatch = useDispatch();

    const [auth, setAuth] = useState(!!user);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);

    const [isShown, setIsShown] = useState(false);
    const [isChartOpen, setIsChartOpen] = useState(false);

    const refElement = useRef<HTMLDivElement>(null);
    const triggerElement = useRef<HTMLDivElement>(null);

    const firebaseLogout = async () => {
        const behalf = localStorage.getItem('behalf');
        if (behalf) {
            localStorage.removeItem('behalf');
            window.location.reload();
        } else {
            return await FirebaseService.auth.signOut();
        }
    };

    useClickAway(
        refElement,
        () => {
            setIsShown(false);
        },
        triggerElement
    );

    useEffect(() => {
        setAuth(!!user);
        if (!(user?.role === 'admin' || user?.role === 'super-admin')) {
            getCart();
        } else {
            setIsAdmin(true);
        }
    }, [user]);

    let authLinks = [
        {
            title: 'Profile',
            link: '/profile',
        },
        {
            title: 'Orders',
            link: '/orders',
        },
    ];

    if (isAdmin)
        authLinks = [
            {
                title: 'Home page',
                link: '/',
            },
        ];

    const headerLinks = [
        {
            title: 'Home',
            url: '/',
        },
        {
            title: 'About Us',
            url: '/about_us',
        },
        {
            title: 'Shop',
            url: '/shop',
        },
        {
            title: 'FAQ',
            url: '/faq',
        },
        {
            title: 'Contacts',
            url: '/contact_us',
        },
    ];
    const userInfo = {
        items: 1,
        userIcon: UserImage,
        userName:
            (!(user?.role === 'juristic' || user?.role === 'wholesaler') ? user?.first_name : user?.company_name) +
            ' ' +
            (user?.role === 'juristic' || user?.role === 'wholesaler' ? ' ' : user?.surname.charAt(0) + '.'),
    };

    useEffect(() => {
        if (message === 'open_sign_in_modal') {
            setOpenModalLogIn(true);
            dispatch(setResponseMessage({ message: '' }));
        }
    }, [message]);

    return (
        <>
            <Modal ModalElement={SignUp} show={openModalSignIn} setShow={setOpenModalSignIn} />
            <Modal ModalElement={LogIn} show={openModalLogIn} setShow={setOpenModalLogIn} onClose={() => closeSecondStepLogIn()} />
            <Modal ModalElement={ModalSearch} show={showSearchModal} setShow={setShowSearchModal} isSearch />
            <Wrapper>
                <Container>
                    <LogoSearch>
                        <LogoBlock to={'/'}>
                            <Logo src={logo} alt="logo" onClick={() => {}} />
                        </LogoBlock>
                        <SearchInput />
                    </LogoSearch>
                    <LinksBlock>
                        {headerLinks.map((item, index) => {
                            if (item.url.includes('/')) {
                                return (
                                    <Links key={`links-index-${index}`} to={`${item.url}`}>
                                        {item.title}{' '}
                                    </Links>
                                );
                            } else {
                                return (
                                    <Links key={`links-index-${index}`} to={`/${item.url}`}>
                                        {item.title}{' '}
                                    </Links>
                                );
                            }
                        })}
                    </LinksBlock>

                    <AuthBlock ref={triggerElement}>
                        {!auth ? (
                            <IconContainer>
                                <MobileSearchIconBlock onClick={() => setShowSearchModal(true)} bg={SearchIcon} />

                                <ChartContainer>
                                    <ChartIc alt="chart-icon" src={ChartIcon} onClick={() => setIsChartOpen((prev) => !prev)} />
                                    <ChartsDropdown isOpen={isChartOpen} setIsOpen={setIsChartOpen} />
                                </ChartContainer>

                                {/* {process.env.REACT_APP_ENV !== 'prod' && ( */}
                                <LogBox style={{ marginLeft: 25 }}>
                                    <LogInBox onClick={() => setOpenModalLogIn(true)}> Log In &nbsp;</LogInBox> |{' '}
                                    <LogInBox onClick={() => setOpenModalSignIn(true)}> &nbsp; Sign Up</LogInBox>
                                </LogBox>
                                {/* )} */}
                            </IconContainer>
                        ) : (
                            <AuthorizedBlock>
                                <MobileSearchIconBlock onClick={() => setShowSearchModal(true)} bg={SearchIcon} />
                                {!(user?.role === 'admin' || user?.role === 'super-admin') && (
                                    <CartIconBox to={'/cart'}>
                                        <Cart bg={CartIcon} />
                                        <Circle>{cart?.products.length}</Circle>
                                    </CartIconBox>
                                )}

                                <ChartContainer>
                                    <ChartIc alt="chart-icon" src={ChartIcon} onClick={() => setIsChartOpen((prev) => !prev)} />
                                    <ChartsDropdown isOpen={isChartOpen} setIsOpen={setIsChartOpen} />
                                </ChartContainer>

                                <UsernameBlock
                                    onClick={() => {
                                        setIsShown(!isShown);
                                    }}
                                >
                                    <ImgUser alt="user-icon" src={userInfo.userIcon} />
                                    <UserName>{userInfo.userName}</UserName>
                                </UsernameBlock>
                            </AuthorizedBlock>
                        )}
                        <LinksBlockTablet
                            src={Hamburger}
                            alt="hamburger-menu"
                            onClick={() => {
                                setOpen(!open);
                            }}
                        />
                        {isShown && auth && (
                            <AccountMenuBlock ref={refElement}>
                                {authLinks.map((item, index) => {
                                    return (
                                        <AccountMenuLinks key={`links-index-${index}`} to={`${item.link}`}>
                                            {item.title}{' '}
                                        </AccountMenuLinks>
                                    );
                                })}
                                <Logout
                                    onClick={() => {
                                        setIsShown(false);
                                        // logout();
                                        firebaseLogout();
                                    }}
                                >
                                    {' '}
                                    <LogoutIcon bg={LogoutLogo} />
                                    Log out
                                </Logout>
                            </AccountMenuBlock>
                        )}
                    </AuthBlock>
                </Container>
            </Wrapper>
        </>
    );
};

const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    background-color: #394985;
    justify-content: center;
    align-items: center;
    font-family: Inter, serif;
`;

const ChartIc = styled.img`
    width: 28px;
`;

const ChartContainer = styled.div`
    position: relative;
`;

const LogBox = styled.div`
    @media (max-width: 600px) {
        display: none;
        margin: 0;
    }
`;
const LogoutIcon = styled.div<{ bg: string }>`
    margin-right: 11px;
    width: 24px;
    height: 24px;
    background: url(${window.location.origin}${(props) => props.bg});
`;
const Logout = styled.button`
    border: 0;
    background: white;
    display: flex;
    align-items: center;
    padding: 13px 0 13px 16px;
    font-family: Inter, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    color: #202020;
    border-top: 1px grey solid;
    border-radius: 0 0 4px 4px;
`;
const LogInBox = styled.a`
    &:hover {
        color: #f8c882;
    }
`;
const AccountMenuBlock = styled.div`
    border-radius: 4px;
    background: white;
    position: absolute;
    overflow: visible;
    top: 0;
    left: 0;
    z-index: 5;
    transform: translate(calc(1000px + 0.5 * (100vw - 1290px)), 95px);
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.22);
    @media (max-width: 1290px) {
        transform: translate(calc(100vw - 285px), 80px);
    }
    @media (max-width: 900px) {
        transform: translate(calc(100vw - 285px), 55px);
    }
    @media (max-width: 600px) {
        display: none;
    }
`;
const AccountMenuLinks = styled(Link)`
    text-decoration: none;
    width: 255px;
    padding: 13px 0 13px 16px;
    color: #202020;
`;
const LogoSearch = styled.div`
    display: flex;
`;
const LinksBlockTablet = styled.img`
    transition: transform 1s;
    width: 64px;
    height: 64px;
    display: none;
    z-index: 2;

    @media (max-width: 600px) {
        display: block;
        width: 24px;
        height: 24px;
    }
`;
const ImgUser = styled.img`
    width: 32px;

    @media (max-width: 900px) {
        width: 24px;
    }
    @media (max-width: 600px) {
        display: none;
        margin: 0;
    }
`;
const CartIconBox = styled(Link)`
    position: relative;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const UserName = styled.div`
    display: flex;
    margin-left: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 110px;
    @media (max-width: 900px) {
        display: none;
        margin: 0;
    }
`;
const UsernameBlock = styled.div`
    display: flex;
    margin: 0 14px;
    align-items: center;
    position: relative;
    justify-content: center;
    @media (max-width: 900px) {
        margin: 0 5px;
    }
`;
const Container = styled.div`
    margin: 0;
    padding: 26px 0;
    max-width: 1290px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1290px) {
        padding: 16px;
    }
    @media (max-width: 600px) {
        padding: 11px;
    }
`;
const Logo = styled.img`
    max-width: 206px;
    width: 100%;
    @media (max-width: 900px) {
        width: 90px;
    }
    @media (max-width: 600px) {
        width: 70px;
    }
`;
const AuthorizedBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MobileSearchIconBlock = styled.div<{ bg: string }>`
    width: 24px;
    height: 24px;
    left: 10px;
    display: none;
    background: no-repeat url(${window.location.origin}${(props) => props.bg});
    @media (max-width: 1100px) {
        display: block;
        margin-right: 20px;
    }
    @media (max-width: 680px) {
        width: 40px;
    }
    @media (max-width: 600px) {
        width: 24px;
    }
`;
const Cart = styled.div<{ bg: string }>`
    background: no-repeat url(${window.location.origin}${(props) => props.bg});
    width: 25px;
    height: 25px;
`;
const Circle = styled.div`
    color: white;
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #eb5757;
    color: white;
    top: -7px;
    left: 12px;
    font-family: Inter, serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LogoBlock = styled(Link)`
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 2;
    margin-right: 18px;
`;

const LinksBlock = styled.div`
    display: flex;
    width: 400px;
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    margin: 0 6px;
    @media (max-width: 900px) {
        max-width: 277px;
    }
    @media (max-width: 600px) {
        display: none;
    }
`;
const AuthBlock = styled.div`
    display: flex;
    z-index: 2;

    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    gap: 20px;
    color: #ffffff;
    cursor: pointer;
    @media (max-width: 1300px) {
    }
`;
const Links = styled(Link)`
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    font-weight: 400;
    transition: color 0.2s;
    @media (max-width: 1000px) {
        font-size: 14px;
    }

    &:hover {
        color: #f8c882;
    }
`;
export default Header;
