import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import { Container } from '../ui/container';
import ProductPage from '../components/productPage';
import { useTypedSelector } from '../hooks/useSelector';
import { useLocation } from 'react-router-dom';
import { useShopActions } from '../hooks/useActions';
import useSocketDispatch from '../hooks/useSocketDispatch';
import { getCurrentProduct } from '../store/actions/shopActions';
import { Helmet } from 'react-helmet';

const getTextFromHtmlString = (html: string) => {
    const el = document.createElement('div');
    el.innerHTML = html;
    const text = el.textContent || '';
    el.remove();
    return text;
};

const getHtmlStringToTextOfFixedLength = (html: string, length: number) => {
    const text = getTextFromHtmlString(html);
    return text.slice(0, length);
};

const Product = () => {
    const { recommendedProducts, currentProduct } = useTypedSelector((state) => state.shopReducer);
    const { apiStatus } = useTypedSelector((state) => state.metalReducer);
    const { getRecommendedProducts } = useShopActions();
    const location = useLocation();

    const socketDispatch = useSocketDispatch();
    /*    const [currentProducts, setCurrentProducts] = useState<ILocalProduct[]>([])*/

    useEffect(() => {
        getRecommendedProducts();
        socketDispatch({
            first: () => getCurrentProduct(location.pathname.split('/')[4]),
        });
    }, [location]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (currentProduct?.name) {
            document.title = `${currentProduct?.name} Bullion Network`;
        }
        getTextFromHtmlString(currentProduct?.description || '');
    }, [currentProduct]);

    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={`${currentProduct?.name} Bullion Network`} />
                <meta
                    property="og:description"
                    content={`${
                        currentProduct?.description
                            ? `${getHtmlStringToTextOfFixedLength(currentProduct.description, 140)}...`
                            : 'Australia wide Shipping from $15 with signature & tracking.'
                    }`}
                ></meta>
                <meta
                    property="description"
                    content={`${
                        currentProduct?.description
                            ? `${getHtmlStringToTextOfFixedLength(currentProduct.description, 140)}...`
                            : 'Australia wide Shipping from $15 with signature & tracking.'
                    }`}
                />
                <meta
                    property="og:image"
                    content={
                        currentProduct?.medias && currentProduct?.medias[0]
                            ? currentProduct.medias[0]
                            : 'https://prod.storage.bullionnetwork.com.au/e838caa4dce41dd8ef9c63a31b769c5947f581a0ef1b31340166139dc0b42168.png'
                    }
                ></meta>
                <meta property="og:site_name" content="Bullion Network"></meta>
            </Helmet>
            <Container>
                <ProductPage status={!apiStatus} currentProduct={currentProduct} recommendedProducts={recommendedProducts} />
            </Container>
        </Layout>
    );
};

export default Product;
