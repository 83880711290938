import {
    IErrorShopAction,
    IGetCategories,
    IGetCurrentShopData,
    IGetCurrentShopProduct,
    IGetLivePriceInfo,
    IGetRecommendedProducts,
    IStartShopAction,
    shopTypes,
} from '../../store/types/shopTypes';
import { ShopActionsTypes } from '../../store/constants/shopConstants';
import { IErrors } from '../../api/user/userTypes';
import { ICategoriesData, ILivePriceMainInfo, IPaginationData, IShopData } from '../../api/shop/shopTypes';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { shopApi } from '../../api/shop/shopApi';
import { IShopProduct } from '../../api/cart/cartTypes';

export const startAction = (): IStartShopAction => ({
    type: ShopActionsTypes.START_SHOP_ACTION,
});

const errorAction = (payload: IErrors[]): IErrorShopAction => ({
    type: ShopActionsTypes.ERROR_SHOP_ACTION,
    payload,
});

export const getShopData_ = (payload: IShopData): IGetCurrentShopData => ({
    type: ShopActionsTypes.GET_SHOP_DATA,
    payload,
});

export const getShopData = (data?: IPaginationData) => async (dispatch: Dispatch<shopTypes>) => {
    try {
        dispatch(startAction());
        const response = await shopApi.getShopInfo(data);
        dispatch(getShopData_(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorAction(e.response?.data.message));
    }
};

const getCategories_ = (payload: ICategoriesData[]): IGetCategories => ({
    type: ShopActionsTypes.GET_CATEGORIES_PRODUCTS,
    payload,
});

export const getCategories = () => async (dispatch: Dispatch<shopTypes>) => {
    try {
        dispatch(startAction());
        const response = await shopApi.getCategories();
        dispatch(getCategories_(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorAction(e.response?.data.message));
    }
};

const getLiveInfo_ = (payload: ILivePriceMainInfo[]): IGetLivePriceInfo => ({
    type: ShopActionsTypes.GET_LIVE_PRICE_DATA,
    payload,
});

export const getLiveInfo = (currency?: string) => async (dispatch: Dispatch<shopTypes>) => {
    try {
        dispatch(startAction());
        const response = await shopApi.getLpl(currency);
        if (response) {
            dispatch(getLiveInfo_(response));
        }
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorAction(e.response?.data.message));
    }
};

const setCurrentProduct = (payload: IShopProduct): IGetCurrentShopProduct => ({
    type: ShopActionsTypes.GET_SHOP_PRODUCT,
    payload,
});

export const getCurrentProduct = (id: string) => async (dispatch: Dispatch<shopTypes>) => {
    try {
        dispatch(startAction());
        const response = await shopApi.getCurrentProductInfo(id);
        dispatch(setCurrentProduct(response));
    } catch (_e) {
        const e = _e as AxiosError;
    }
};

const addRecommendedProducts = (payload: IShopProduct[]): IGetRecommendedProducts => ({
    type: ShopActionsTypes.GET_RECOMMENDED_PRODUCTS,
    payload,
});

export const getRecommendedProducts = () => async (dispatch: Dispatch<shopTypes>) => {
    try {
        dispatch(startAction());
        const response = await shopApi.getRecommendedProducts();
        dispatch(addRecommendedProducts(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorAction(e.response?.data.message));
    }
};
