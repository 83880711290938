import { IErrors, IUserData } from '../../api/user/userTypes';
import { userAction } from '../../store/types/userTypes';
import { UserActionTypes } from '../../store/constants/userConstants';
import { MultiFactorError } from 'firebase/auth';

interface IUserState {
    isInitialize: boolean;
    user: IUserData | undefined;
    // error: IErrors[] | undefined
    error: any;
    loading: boolean;
    message: string | undefined;
    isSecondStepLogIn: boolean;
    request: string | undefined;
    isVerified: {
        mail: boolean;
        phone: boolean;
    };
    multiFactorError: MultiFactorError | undefined;
}

const initialState: IUserState = {
    isInitialize: false,
    error: undefined,
    loading: false,
    user: undefined,
    message: undefined,
    isSecondStepLogIn: false,
    request: undefined,
    isVerified: {
        mail: false,
        phone: false,
    },
    multiFactorError: undefined,
};

const startLoading = (state: IUserState) => {
    return { ...state, loading: true, error: undefined };
};
const stopLoading = (state: IUserState) => {
    return { ...state, loading: false, error: undefined };
};

const stopErrorLoading = (state: IUserState, error: IErrors[]) => {
    return { ...state, loading: false, error: error };
};

const userReducer = (state = initialState, action: userAction): IUserState => {
    switch (action.type) {
        case UserActionTypes.INITIALIZED:
            return { ...state, isInitialize: true };

        case UserActionTypes.CLEAR_USER_ERROR: {
            return { ...state, error: undefined };
        }
        case UserActionTypes.CLEAR_USER_MESSAGE: {
            return {
                ...state,
                message: undefined,
                loading: false,
            };
        }

        case UserActionTypes.CLEAR_STATE_USER:
            return {
                ...state,
                user: undefined,
                loading: false,
                message: undefined,
                error: undefined,
                request: undefined,
                isSecondStepLogIn: false,
            };

        case UserActionTypes.START_USER_ACTION:
            return startLoading(state);

        case UserActionTypes.FINISH_USER_ACTION:
            return stopLoading(state);

        case UserActionTypes.ERROR_USER_ACTION:
            return stopErrorLoading(state, action.payload);

        case UserActionTypes.REGISTRATION_SUCCESS:
            return { ...state, loading: false, request: action.payload.request };

        case UserActionTypes.LOGIN_FIRST_STEP_SUCCESS:
            return { ...state, loading: false, isSecondStepLogIn: true, multiFactorError: action.payload.MFE };
        case UserActionTypes.LOGIN_SECOND_STEP_FAIL:
            return { ...state, loading: false, isSecondStepLogIn: false };

        case UserActionTypes.LOGIN_SECOND_STEP_SUCCESS:
            return { ...state, loading: false, user: action.payload, isSecondStepLogIn: false };

        case UserActionTypes.GET_RESPONSE_MESSAGE:
            return {
                ...state,
                message: action.payload.message,
                loading: false,
            };

        case UserActionTypes.CLEAR_USER_REQUEST:
            return {
                ...state,
                request: undefined,
                loading: false,
            };

        case UserActionTypes.SET_USER_VERIFIED:
            return {
                ...state,
                isVerified: {
                    mail: true,
                    phone: true,
                },
            };

        case UserActionTypes.SET_USER_UNVERIFIED:
            return {
                ...state,
                isVerified: {
                    mail: action.payload.mail,
                    phone: action.payload.phone,
                },
            };

        default:
            return state;
    }
};

export default userReducer;
