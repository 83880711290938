import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Container } from '../ui/container';
import styled from 'styled-components';
import TextFields from '../ui/TextFields';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../store/actions/snackbarActions';
import { SnakeTypes } from '../ui/components/modals/SnakeBar';
import { useMailChimpForm } from '../hooks/useMailchimpForm';
import { Helmet } from 'react-helmet';

const SubscribeToNewsletter = () => {
    const url = 'https://bullionnetwork.us10.list-manage.com/subscribe/post?u=3d3f8675b500c80a29bf307d5&amp;id=fa9ca26daa&amp;f_id=005acce5f0';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const CustomForm = () => {
        const dispatch = useDispatch();
        const [formData, setFormData] = useState({
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
        });

        const { loading, error, success, message, handleSubmit, reset } = useMailChimpForm(url);

        const [errorState, setErrorState] = useState({
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
        });

        const handleStatusChange = ({ status, message }: { status: string; message: string }) => {
            if (status === 'success') {
                dispatch(showSnackbar({ type: SnakeTypes.success, content: message.length > 0 ? message : 'Thank you for subscribing!', ms: 4000 }));
            }
            if (status === 'error') {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: message, ms: 4000 }));
            }
        };

        const submit = async () => {
            reset();
            if (!formData.email) {
                setErrorState((prev) => ({ ...prev, email: 'Email is required' }));
            }
            if (!formData.firstName) {
                setErrorState((prev) => ({ ...prev, firstName: 'First name is required' }));
            }
            if (!formData.lastName) {
                setErrorState((prev) => ({ ...prev, lastName: 'Last name is required' }));
            }
            if (formData.email && formData.firstName && formData.lastName && formData.email.indexOf('@') > -1) {
                const result = await handleSubmit({
                    EMAIL: formData.email,
                    FNAME: formData.firstName,
                    LNAME: formData.lastName,
                    PHONE: formData.phoneNumber,
                });
                handleStatusChange(result);
            }
        };

        const changeFormData = (data: typeof formData) => {
            setErrorState({
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
            });
            return setFormData(data);
        };

        // useEffect(() => {
        // if (status === 'success') {
        //     dispatch(showSnackbar({ type: SnakeTypes.success, content: message, ms: 4000 }));
        // }
        // if (status === 'error') {
        //     dispatch(showSnackbar({ type: SnakeTypes.error, content: message, ms: 4000 }));
        // }
        //     handleStatusChange();
        // }, [status]);

        useEffect(() => {
            document.title = 'Newsletter | Bullion Network Melbourne';
        }, []);

        return (
            <SubscribeForm>
                <Helmet>
                    <meta property="og:site_name" content="Bullion Network" />
                    <meta property="og:title" content=" Subscribe to newsletter | Bullion Network Melbourne" />
                    <meta property="og:description" content="Bullion Network Melbourne | Subscribe to newsletter" />
                    <meta property="description" content="Bullion Network Melbourne | Subscribe to newsletter" />
                </Helmet>
                <TextFields
                    value={formData.email}
                    placeholder="Email Address *"
                    required={true}
                    onChange={(e) => changeFormData({ ...formData, email: e.target.value })}
                    textError={errorState.email}
                    type="email"
                    height="40px"
                />
                <TextFields
                    value={formData.firstName}
                    placeholder="First Name *"
                    required={true}
                    onChange={(e) => changeFormData({ ...formData, firstName: e.target.value })}
                    textError={errorState.firstName}
                    type="text"
                    height="40px"
                />
                <TextFields
                    value={formData.lastName}
                    placeholder="Last Name *"
                    required={true}
                    onChange={(e) => changeFormData({ ...formData, lastName: e.target.value })}
                    textError={errorState.lastName}
                    type="text"
                    height="40px"
                />
                <TextFields
                    value={formData.phoneNumber}
                    placeholder="Phone Number"
                    onChange={(e) => changeFormData({ ...formData, phoneNumber: e.target.value })}
                    textError={errorState.phoneNumber}
                    type="phone"
                    height="40px"
                />
                <div>
                    <PrivacyPolicyLink to="/privacy_policy">Bullion Network Privacy Policy</PrivacyPolicyLink>
                </div>
                <SubscribeButton onClick={submit}>
                    {(() => {
                        if (loading) {
                            return 'Sending...';
                        }
                        // if (error) {
                        //     return message;
                        // }
                        // if (success) {
                        //     return message;
                        // }
                        return 'Subscribe';
                    })()}
                </SubscribeButton>
            </SubscribeForm>
        );
    };

    return (
        <Layout>
            <Container>
                <Content>
                    <Tittle>Sign up to Bullion Network's Newsletter</Tittle>
                    <MainInfo>
                        {/* <MailchimpSubscribe
                            url={url}
                            render={({ subscribe, status, message }) => (
                                <CustomForm
                                    status={status}
                                    message={message}
                                    onValidated={(formData: any) => {
                                        subscribe(formData);
                                        return { status, message };
                                    }}
                                />
                            )}
                        /> */}
                        <CustomForm />
                    </MainInfo>
                </Content>
            </Container>
        </Layout>
    );
};

const Content = styled.div`
    padding-top: 50px;
    height: 900px;
`;

const Tittle = styled.h1`
    /* font-family: 'Playfair Display'; */
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 53px;
    text-transform: capitalize;
    padding-bottom: 50px;
`;

const MainInfo = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    width: 250px;
    padding-top: 40px;
`;

const ImgContainer = styled.img`
    @media (max-width: 768px) {
        height: 278px;
        width: 278px;
    }
`;

const Description = styled.div`
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    align-items: center;
    text-align: center;
`;

const EmailBlock = styled.div`
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 1px 2px 1px 10px;
    border: #b6b6b6 solid 1px;
    border-radius: 4px;
    font-weight: 400;
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 5px;
    box-sizing: border-box;
    background: #fbfbfb;
`;

const SubscribeButton = styled.button`
    clear: both;
    background-color: #aaa;
    border: 0 none;
    border-radius: 4px;
    transition: all 0.23s ease-in-out 0s;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    height: 32px;
    line-height: 32px;
    margin: 0 5px 10px 0;
    padding: 0 22px;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap;
    width: fit-content;
    width: -moz-fit-content;
`;

const SubscribeForm = styled.div`
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

const PrivacyPolicyLink = styled(Link)`
    color: 'lighblue';
`;

export default SubscribeToNewsletter;
