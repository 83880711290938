import {IOrderUserData} from "@/pages/checkout";
import {ICreateOrderData, ICreateOrderProduct} from "@api/order/orderTypes";

export const formNaturalOrderObject = (data: IOrderUserData, products: ICreateOrderProduct[]):ICreateOrderData | undefined => {
    if (!data.first_name || !data.last_name) return undefined
    return ({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        address: data.address,
        products
    })
}

export const formCompanyOrderObject = (data: IOrderUserData, products: ICreateOrderProduct[]):ICreateOrderData | undefined => {
    if (!data.company_name || !data.abn) return undefined
    return ({
        company_name: data.company_name,
        //abn: data.abn
        email: data.email,
        phone: data.phone,
        address: data.address,
        products
    })
}