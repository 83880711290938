import api from '../../api';
import { ICreateOrderData, IResponseOrderData, IMyOrdersData, ICheckAvailabilityData } from '../../api/order/orderTypes';
import { IPaginationData } from '../../api/shop/shopTypes';

export const orderApi = {
    async createOrder(data: ICreateOrderData) {
        return (await api.post<IResponseOrderData>('order', data)).data;
    },
    async getOrders(data: Omit<IPaginationData, 'sort' | 'category_id'>) {
        return (
            await api.get<IMyOrdersData>('order', {
                params: {
                    page: data.page,
                    page_limit: data.pageLimit,
                },
            })
        ).data;
    },
    async checkAvailability(data: ICheckAvailabilityData) {
        return (await api.post('/order/availability', data)).data;
    },
};
