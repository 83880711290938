import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextFields from '../../ui/TextFields';
import ReCAPTCHA from 'react-google-recaptcha';
import MultilineInput from '../../ui/components/MultilineInput';
import { IErrorOrderData, IOrderUserData } from '../../pages/checkout';
import { IUserData } from '../../api/user/userTypes';
import CheckboxComponent from '../../ui/CheckboxComponent';
import config from '../../config';
import { Link } from 'react-router-dom';

interface ICheckoutForm {
    user: IUserData | undefined;
    userData: IOrderUserData;
    setUserData: (value: string | boolean, key: string) => void;
    setIsPassCaptcha: (value: boolean) => void;
    errorState: IErrorOrderData;
}

const CheckoutForm: React.FC<ICheckoutForm> = ({ userData, user, setUserData, setIsPassCaptcha, errorState }) => {
    function onChange(value: any) {
        setIsPassCaptcha(true);
    }

    function onError() {
        setIsPassCaptcha(false);
    }

    const [error, setError] = useState(errorState);

    useEffect(() => {
        setError(errorState);
    }, [errorState]);

    useEffect(() => {
        if (process.env.REACT_APP_ENV !== 'prod') {
            setIsPassCaptcha(true);
        }
    }, []);

    return (
        <FormContainer>
            <Column>
                <TextFields
                    value={user?.role === 'natural' ? userData.first_name : userData.abn}
                    onChange={(e) => {
                        const value = e.target.value;
                        setUserData(value, user?.role === 'natural' ? 'first_name' : 'abn');
                        setError({ ...error, [user?.role === 'natural' ? 'first_name' : 'abn']: '' });
                    }}
                    placeholder={user?.role === 'natural' ? 'First name*' : 'ABN or ACN*'}
                    type={'text'}
                    textError={user?.role === 'natural' ? error.first_name : error.abn}
                    height={'56px'}
                />
                <TextFields
                    value={user?.role === 'natural' ? userData.last_name : userData.company_name}
                    onChange={(e) => {
                        const value = e.target.value;
                        setUserData(value, user?.role === 'natural' ? 'last_name' : 'company_name');
                        setError({ ...error, [user?.role === 'natural' ? 'last_name' : 'company_name']: '' });
                    }}
                    placeholder={user?.role === 'natural' ? 'Last name*' : 'Company name*'}
                    type={'text'}
                    textError={user?.role === 'natural' ? error.last_name : error.company_name}
                    height={'56px'}
                />
            </Column>
            {/*            <Column>
                <TextFields
                    value={userData.apartment}
                    onChange={(e) => {
                        const apartment = e.target.value
                        setUserData(ud => ({...ud, apartment}))
                    }}
                    placeholder={'Apartment, suite, unit, etc.'}
                    type={'text'}
                    textError={''}
                    height={'56px'}
                />
                <TextFields
                    value={userData.houseStreet}
                    onChange={(e) => {
                        const houseStreet = e.target.value
                        setUserData(ud => ({...ud, houseStreet}))
                    }}
                    placeholder={'House number and street name*'}
                    type={'text'}
                    textError={''}
                    height={'56px'}
                />
            </Column>
            <Column>
                <TextFields
                    value={userData.state}
                    onChange={(e) => {
                        const state = e.target.value
                        setUserData(ud => ({...ud, state}))
                    }}
                    placeholder={'State*'}
                    type={'text'}
                    textError={''}
                    height={'56px'}
                />
                <TextFields
                    value={userData.postcode}
                    onChange={(e) => {
                        const postcode = e.target.value
                        setUserData(ud => ({...ud, postcode}))
                    }}
                    placeholder={'Postcode/ZIP*'}
                    type={'text'}
                    textError={''}
                    height={'56px'}
                />
            </Column>*/}
            <TextFields
                value={userData.address}
                onChange={(e) => {
                    const value = e.target.value;
                    setUserData(value, 'address');
                    setError({ ...error, address: '' });
                }}
                placeholder={'DeliveryAddress*'}
                type={'text'}
                textError={error.address}
                height={'56px'}
            />
            <Column>
                <TextFields
                    value={userData.phone}
                    onChange={(e) => {
                        const value = e.target.value;
                        setUserData(value, 'phone');
                        setError({ ...error, phone: '' });
                    }}
                    placeholder={'Phone*'}
                    type={'text'}
                    textError={error.phone}
                    height={'56px'}
                />
                <TextFields
                    value={userData.email}
                    onChange={(e) => {
                        const value = e.target.value;
                        setUserData(value, 'email');
                        setError({ ...error, email: '' });
                    }}
                    placeholder={'Email*'}
                    type={'text'}
                    textError={error.email}
                    height={'56px'}
                />
            </Column>
            <MultilineInput
                value={userData.note}
                onChange={(e) => {
                    const value = e.target.value;
                    setUserData(value, 'note');
                }}
                placeholder={'Order notes (for example: special notes for delivery)'}
                rows={11}
                height={'40px'}
            />
            {/*            <TextFields
                value={userData.orderNotes}
                onChange={(e) => {
                    const orderNotes = e.target.value
                    setUserData(ud => ({...ud, orderNotes}))
                }}
                placeholder={'Order notes (for example: special notes for delivery)'}
                type={'text'}
                textError={''}
                height={'211px'}
            />*/}
            <div>
                <CheckBoxField>
                    <CheckboxComponent
                        height={'18px'}
                        width={'18px'}
                        value={userData.isAgreeWithPolicy}
                        onClick={(e) => {
                            setUserData(!userData.isAgreeWithPolicy, 'isAgreeWithPolicy');
                            setError({ ...error, isAgreeWithPolicy: '' });
                        }}
                    />
                    <TextNearCheckBox>
                        <span
                            onClick={(e) => {
                                setUserData(!userData.isAgreeWithPolicy, 'isAgreeWithPolicy');
                                setError({ ...error, isAgreeWithPolicy: '' });
                            }}
                        >
                            I agree with{' '}
                        </span>
                        <LinkPolicy to={'/privacy_policy'}>Privacy Policy, Terms & Conditions</LinkPolicy>
                    </TextNearCheckBox>
                </CheckBoxField>
                {error.isAgreeWithPolicy !== '' && <ErrorText>{error.isAgreeWithPolicy}</ErrorText>}
            </div>
            <ReCAPTCHA
                sitekey={`${config.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '6LeQdf8kAAAAAGA09jE7GhWgA2J2bLuuLK8bHIsm'}`}
                onChange={onChange}
                onExpired={onError}
            />
        </FormContainer>
    );
};

export default CheckoutForm;

const ErrorText = styled.div`
    color: #d92e23;
    font-size: 14px;
    padding-left: 10px;
    padding-top: 2px;
`;

const CheckBoxField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    gap: 6px;
`;

const TextNearCheckBox = styled.div`
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    color: #0b1126;
    @media (max-width: 460px) {
        line-height: 17px;
    }
    @media (max-width: 460px) {
        line-height: 14px;
        font-size: 11px;
    }
`;

const LinkPolicy = styled(Link)`
    font-size: 14px;
    line-height: 24px;

    text-decoration: none;
    color: #9fabd9;

    padding-left: 3px;

    @media (max-width: 460px) {
        line-height: 17px;
    }

    @media (max-width: 460px) {
        line-height: 14px;
        font-size: 11px;
    }
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    margin-bottom: 25px;
`;
const Column = styled.div`
    display: flex;
    width: 100%;
    gap: 30px;

    @media (max-width: 768px) {
        gap: 16px;
    }
    @media (max-width: 375px) {
        flex-direction: column;
    }
`;
