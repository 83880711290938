import styled from "styled-components"
import "../index.css"

export const Heading1 = styled.h1`
  /* font-family: Playfair Display, serif; */
  color: #0B1126;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 53px;
`
export const Heading2 = styled.h2`
  color: #0B1126;
  font-family: Inter, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
`
export const Heading3 = styled.p`
  color: #0B1126;
  font-family: Inter,serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;;

`
export const Heading4 = styled.p`
  color: #0B1126;
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;

`
export const Heading5 = styled.p`
  color: #0B1126;
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;


`
