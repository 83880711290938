import { IAddQueue, IRemoveFromQueue, IShowSnackbar, ISnackbarData, ISnakeItem, snackbarAction } from '../../store/types/snackbarTypes';
import { SnackbarActionTypes } from '../../store/constants/snackbarConstants';
import { Dispatch } from 'redux';

const setSnackbar = (payload: ISnackbarData): IShowSnackbar => ({
    type: SnackbarActionTypes.SET_SNACKBAR,
    payload,
});

export const showSnackbar = (data: ISnackbarData) => async (dispatch: Dispatch<snackbarAction>) => {
    dispatch(addToQueue(data));
    //dispatch(setSnackbar(data))
};

export const hideSnackbar = () => async (dispatch: Dispatch<snackbarAction>) => {
    dispatch(
        setSnackbar({
            type: undefined,
            content: undefined,
        })
    );
};

export const addToQueue = (payload: ISnackbarData): IAddQueue => ({
    type: SnackbarActionTypes.ADD_TO_QUEUE,
    payload,
});

export const removeFromQueue = (clearData?: () => void) => async (dispatch: Dispatch<snackbarAction>) => {
    dispatch({
        type: SnackbarActionTypes.REMOVE_FROM_QUEUE,
    });
    if (clearData) {
        clearData();
    }
};
