import React from 'react';
import styled from 'styled-components';
import Check from '../../assets/svgs/check.svg';
import { IOrderProducts } from '../../api/order/orderTypes';
import PlaceholderIMG from '../../assets/svgs/cart_placeholder_img.svg';
import { Link } from 'react-router-dom';
import { roundToTwoDecimals } from '../../helpers/round';
import { replaceSpacesAndSlashesWithHyphen } from '../../helpers/stringFormat';

interface IProps {
    orderNumber: string;
    date: string;
    status: string;
    items: IOrderProducts[];
    totalPrice: string;
}

const OrderCard: React.FC<IProps> = ({ orderNumber, totalPrice, items, status, date }) => {
    return (
        <Wrapper>
            <HeaderWrapper>
                <OrderHeadInfo>
                    <GoodsName> Order ID: {orderNumber}</GoodsName>
                    <span style={{ fontWeight: '400' }}> Date: {date}</span>
                </OrderHeadInfo>
                <Completed status={status}>
                    {status} <CheckImg alt="check" src={Check} />
                </Completed>
            </HeaderWrapper>
            <OrderBlock>
                <Blocks>
                    {items.map((item, index) => {
                        return (
                            <BlockInfo key={`index-items-${index}`}>
                                <PicAndName>
                                    <Link to={`/shop/product/${replaceSpacesAndSlashesWithHyphen(item.product.name)}/${item.product.id}`}>
                                        <GoodsImg
                                            style={{ marginRight: '10px' }}
                                            width={100}
                                            alt="product-image"
                                            // src={!item.product.medias[0].url ? PlaceholderIMG : item.product.medias[0].url}
                                            src={item.product.medias[0] && item.product.medias[0].url ? item.product.medias[0].url : PlaceholderIMG}
                                        />
                                    </Link>
                                    <GoodsName>{item.product.name}</GoodsName>
                                </PicAndName>
                                <PriceBlock>
                                    <span>${roundToTwoDecimals(item.price)}</span>
                                    <span style={{ color: '#8A8A8A', fontSize: '14px' }}>{item.amount}</span>
                                </PriceBlock>
                            </BlockInfo>
                        );
                    })}
                </Blocks>
                <div>
                    <TotalBlock>
                        <TotalsSpan>Total</TotalsSpan>
                        <span>${roundToTwoDecimals(+totalPrice)}</span>
                    </TotalBlock>
                    {/*                    <div style={{width: "180px"}}>
                        <StyledButton height={"50px"}>Repeat order</StyledButton>
                    </div>*/}
                </div>
            </OrderBlock>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    max-width: 960px;
    width: 100%;
    @media (max-width: 900px) {
        max-width: 70vw;
    }
    @media (max-width: 600px) {
        box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.22);
        max-width: 100vw;
    }
`;
const GoodsName = styled.span`
    margin-bottom: 10px;
    @media (max-width: 600px) {
        display: none;
    }
`;
const GoodsImg = styled.img`
    @media (max-width: 600px) {
        max-width: 42px;
    }
`;
const CheckImg = styled.img`
    margin-left: 14px;
    @media (max-width: 600px) {
        width: 10px;
        height: 10px;
        margin-left: 5px;
    }
`;
const TotalsSpan = styled.span`
    @media (max-width: 600px) {
        display: none;
    }
`;
const Blocks = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 600px) {
        flex-direction: row;
    }
`;
const TotalBlock = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #9fabd9;
    margin-bottom: 15px;
    height: 40px;
    font-family: Inter, serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    @media (max-width: 600px) {
        border-bottom: none;
        font-size: 16px;
        line-height: 19px;
        margin: 18px 0;
        height: auto;
    }
`;
const PriceBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    font-family: Inter, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #0b1126;
    @media (max-width: 600px) {
        display: none;
    }
`;
const PicAndName = styled.div`
    display: flex;
    align-items: center;
    color: #0b1126;
`;
const Completed = styled.div<{ status: string }>`
    color: white;
    width: auto;
    height: 22px;
    background: ${(props) => (props.status === 'completed' ? '#27AE60' : '#a4a4a4')};
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 9px;
    @media (max-width: 600px) {
        padding: 2.5px 8.5px;
        width: 65px;
        height: 15px;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        margin-top: 5px;
        margin-left: 14px;
    }
`;
const BlockInfo = styled.div`
    text-decoration: none;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    @media (max-width: 900px) {
        flex-direction: row;
        height: auto;
    }
`;
const OrderBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    min-height: 246px;
    padding: 17px 20px;
    height: auto;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    border-radius: 0 0 6px 6px;
    @media (max-width: 600px) {
        box-shadow: none;
        padding: 8px;
        min-height: auto;
    }
`;
const HeaderWrapper = styled.div`
    background: #9fabd9;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    border-radius: 6px 6px 0 0;
    color: white;
    height: 50px;
    padding: 17px 20px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 600px) {
        background: white;
        color: #0b1126;
        box-shadow: none;
        height: auto;
        padding: 8px;
        align-items: center;
        justify-content: start;
    }
`;
const OrderHeadInfo = styled.div`
    font-family: Inter, serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    flex-direction: column;
`;
export default OrderCard;
