import React, { useEffect, useMemo, useRef, useState } from 'react';
import StyledCloseButton from '../../StyledCloseButton';
import ChangeDataSuccess from '../../components/modals/ChangeDataSuccess';
import styled from 'styled-components';
import { SnakeTypes } from '../../components/modals/SnakeBar';
import { Transition, TransitionStatus } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import { hideSnackbar, removeFromQueue } from '../../../store/actions/snackbarActions';
import { createPortal } from 'react-dom';

interface ISnakeElem {
    content: string | undefined;
    type: SnakeTypes | undefined;
    transitionMS: number;
    timeMS: number;
    isArrayElement: boolean;
    queueLength: number;
    root: HTMLDivElement;
    isLast?: boolean;
    id?: number;
}

const SnakeBarElement: React.FC<ISnakeElem> = ({ content, type, timeMS, transitionMS, isArrayElement, queueLength, root, isLast, id }) => {
    const [show, setShow] = useState(true);
    const timeoutRef = useRef<any>(null);
    const dispatch = useDispatch();

    const closeModal = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
            setShow(false);
            setTimeout(() => {
                if (isArrayElement) {
                    dispatch(removeFromQueue());
                } else {
                    dispatch(hideSnackbar());
                }
            }, transitionMS);
        } else {
            if (isArrayElement) {
                dispatch(removeFromQueue());
            } else {
                dispatch(hideSnackbar());
            }
        }
    };

    // useEffect(() => {
    //     if (!isArrayElement) {
    //         if (content) {
    //             if (timeoutRef.current) {
    //                 clearTimeout(timeoutRef.current)
    //                 timeoutRef.current = null
    //             }
    //             setShow(true)
    //         } else {
    //             setShow(false)
    //         }
    //     }
    // }, [content])

    useEffect(() => {
        if (isArrayElement) {
            setShow(true);
            //root?.appendChild(element);
        }
    }, []);

    if (timeoutRef.current === null) {
        timeoutRef.current = setTimeout(() => {
            setShow(false);
        }, timeMS);
    }

    if (queueLength === 0) return null;

    if (root) {
        return (
            <Transition in={show} timeout={transitionMS} mountOnEnter appear unmountOnExit>
                {(state: TransitionStatus) => {
                    if (!show && state === 'exited') setTimeout(closeModal, transitionMS);

                    return (
                        <React.Fragment>
                            <SnakeContent isShow={state === 'entered'}>
                                <CloseButtonContainer>
                                    <StyledCloseButton onClick={closeModal} />
                                </CloseButtonContainer>
                                <ChangeDataSuccess content={content} type={type} />
                            </SnakeContent>
                        </React.Fragment>
                    );
                }}
            </Transition>
        );
    } else {
        return null;
    }
};

const CloseButtonContainer = styled.div`
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;
`;

const SnakeContent = styled.div<{ isShow: boolean }>`
    transition: transform 0.5s;
    transform: scale(${({ isShow }) => (isShow ? 1 : 0)});
    /*  position: fixed;*/
    display: flex;
    height: 88px;
    /*    bottom: 20px;
      left: 20px;*/
    margin-top: 10px;
    max-width: 520px;
    min-width: 320px;
    max-height: 1300px;
    border-radius: 4px;
    background-color: #ffffff;
    z-index: 1001;
`;

export default SnakeBarElement;
