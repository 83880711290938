import React, {useState} from 'react';
import eyeShow from "../assets/svgs/eye_show_icon.svg";
import eyeHide from "../assets/svgs/eye_hide_icon.svg";
import styled from "styled-components";

type EyesProps = {
    value: boolean,
    width: string,
    height: string,
    onClick?: (param: boolean) => void,
}


const EyesHandler = (props: EyesProps) => {
    const [eyes, setEyes] = useState(props.value)
    return (
            <Wrapper >
            <Check width={props.width} height={props.height} alt="eyes" src={eyes ? eyeShow : eyeHide} onClick={(e) => {
                setEyes(!eyes)
                if (props.onClick) {
                   return  props.onClick(eyes);
                }

            }}/>
            </Wrapper>
    );
};


const Wrapper = styled.div`
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Check = styled.img<{ width: string, height:string }>`
  width: ${props => props.width};
  height: ${props => props.height};
  cursor: pointer;
 
`

export default EyesHandler;
