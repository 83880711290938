import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TextFields from '../../ui/TextFields';
import SignUp, { FIELD_HEIGHT } from './SignUp';
import StyledButton from '../../ui/StyledButton';
import { FIELD_TYPE, validateField } from '../../helpers/registerValidation';
import { useUserActions } from '../../hooks/useActions';
import EnterCode from './EnterCode';
import { useTypedSelector } from '../../hooks/useSelector';
import { IModalElement } from '../../ui/components/modals/Modal';
import PasswordField from '../../ui/PasswordField';
import ResetPassword from '../../ui/components/modals/ResetPassword';
import { IErrors } from '../../api/user/userTypes';
interface IUserState {
    email: string;
    password: string;
}

enum currentStateEnum {
    logIn,
    forgetPassword,
    signUp,
}

const LogIn: React.FC<IModalElement> = ({ closeModal }) => {
    const { logInFirstStep, logInDev, clearUserError, clearMessage } = useUserActions();
    const { loading, isSecondStepLogIn, error, user } = useTypedSelector((state) => state.userReducer);

    const [currentState, setCurrentState] = useState<currentStateEnum>(currentStateEnum.logIn);
    const [currentErrors, setCurrentErrors] = useState<IErrors[] | undefined>(error);
    const [errorState, setErrorState] = useState<IUserState>({
        email: '',
        password: '',
    });
    const [userData, setUserData] = useState<IUserState>({
        email: '',
        password: '',
    });

    useEffect(() => {
        clearUserError();
        return () => {
            clearMessage();
        };
    }, []);

    useEffect(() => {
        if (user) {
            if (closeModal) {
                closeModal();
            }
        }
    }, [user]);

    useEffect(() => {
        setCurrentErrors(error);
        if (error && error.length > 0) {
            if (error[0].property === '(auth/user-not-found).') {
                setErrorState((prev) => ({
                    ...prev,
                    email: '  ',
                }));
            }
            if (error[0].property === '(auth/wrong-password).') {
                setErrorState((prev) => ({
                    ...prev,
                    password: '  ',
                }));
            }
            if (error[0].property === '(auth/user-not-found).') {
                setErrorState((prev) => ({
                    ...prev,
                    email: '  ',
                }));
            }
        }
    }, [error]);

    const logIn = () => {
        let obj: IUserState = {
            email: validateField(userData.email, FIELD_TYPE.email),
            password: validateField(userData.password, FIELD_TYPE.password),
        };
        if (Object.values(obj).some((x) => x !== '')) {
            setErrorState(obj);
        } else {
            logInFirstStep({ ...userData });
        }
    };

    const firstStep = () => {
        return (
            <>
                <ModalTitle>Log In</ModalTitle>
                <FieldContainer>
                    <TextFields
                        value={userData.email}
                        onChange={(e) => {
                            const email = e.target.value;
                            setUserData((ud) => ({ ...ud, email }));
                            if (email !== '') {
                                setErrorState((e) => ({ ...e, email: validateField(email, FIELD_TYPE.email) }));
                            } else {
                                setErrorState((e) => ({ ...e, email: '' }));
                            }
                            setCurrentErrors(undefined);
                        }}
                        height={FIELD_HEIGHT}
                        type={'email'}
                        placeholder={'Email'}
                        textError={errorState.email}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                logIn();
                            }
                        }}
                    />
                    <PasswordField
                        placeholder={'Password'}
                        setPasswordData={(value) => {
                            const password = value;
                            setUserData((ud) => ({ ...ud, password }));
                            if (value !== '') {
                                setErrorState((e) => ({
                                    ...e,
                                    password: validateField(password, FIELD_TYPE.password),
                                }));
                            } else {
                                setErrorState((e) => ({ ...e, password: '' }));
                            }
                            setCurrentErrors(undefined);
                        }}
                        password={userData.password}
                        errorPassword={errorState.password}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                logIn();
                            }
                        }}
                    />
                </FieldContainer>
                <ForgotPassword
                    onClick={() => {
                        setCurrentState(currentStateEnum.forgetPassword);
                        clearUserError();
                    }}
                >
                    Forgot password?{' '}
                </ForgotPassword>

                <ButtonContainer>
                    <StyledButton disabled={loading || errorState.email !== '' || errorState.password !== ''} onClick={logIn} height={'50px'}>
                        Log In
                    </StyledButton>
                </ButtonContainer>
                <ErrorWrap>
                    {currentErrors && (
                        <div
                            style={{
                                color: '#D92E23',
                                fontSize: 14,
                                paddingBottom: 10,
                                marginTop: -15,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            {!currentErrors[0].code ? (currentErrors as any) : currentErrors[0].code}
                        </div>
                    )}
                </ErrorWrap>
                <NotHaveAccount>
                    Don’t have account?
                    <LinkLogIn
                        onClick={() => {
                            setCurrentState(currentStateEnum.signUp);
                            clearUserError();
                        }}
                    >
                        Create account
                    </LinkLogIn>
                </NotHaveAccount>
            </>
        );
    };
    return (
        <>
            {isSecondStepLogIn ? (
                <EnterCode email={userData.email} password={userData.password} />
            ) : (
                <>
                    {currentState === currentStateEnum.logIn && firstStep()}
                    {currentState === currentStateEnum.forgetPassword && <ResetPassword closeModal={closeModal} />}
                    {currentState === currentStateEnum.signUp && <SignUp />}
                </>
            )}
        </>
    );
};

const ModalTitle = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    text-align: center;

    color: #0b1126;
`;

const FieldContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-top: 10px;
    min-width: 400px;
    width: 100%;
    padding-bottom: 15px;
    @media (max-width: 600px) {
        min-width: auto;
    }
`;

const ButtonContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 20px;
    cursor: pointer;
`;
const ErrorWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;
const ForgotPassword = styled.a`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #5b73cf;
    text-align: left;
    text-decoration-line: underline;
    cursor: pointer;
`;

const NotHaveAccount = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-right: 12px;
    color: #0b1126;
    cursor: pointer;
`;

const LinkLogIn = styled.a`
    color: #f8c882;
    text-decoration: none;
    margin-left: 5px;
`;

const EyePasswordContainer = styled.div`
    position: relative;
    margin-bottom: -20px;
    display: flex;
    align-items: center;
    justify-content: right;
    top: 30px;
`;

export default LogIn;
