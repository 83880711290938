import React, { useEffect } from 'react';
import SearchInput from '../../components/SearchInput';
import styled from 'styled-components';
import { IModalElement } from '../../components/modals/Modal';

const ModalSearch: React.FC<IModalElement> = ({ closeModal }) => {
    const detectSize = () => {
        if (document.documentElement.clientWidth > 1100) {
            if (closeModal) {
                closeModal();
            }
        }
    };

    /*
    useEffect(() => {
        window.addEventListener('resize', detectSize)

        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [closModal])
*/

    return (
        <SearchContainer>
            <SearchInput isModalElement={true} closeFunc={closeModal} />
        </SearchContainer>
    );
};

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 100px;
    z-index: 1001;
`;

export default ModalSearch;
