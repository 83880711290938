import { useDispatch } from 'react-redux';

interface IHookUpdateEveryMinute {
    [key: string]: Function;
}

const useSocketDispatchUpdatePrice = () => {
    const dispatch = useDispatch();
    return (cb: IHookUpdateEveryMinute) => {
        //if (typeof cb !== "function") return;
        const call = () => {
            Object.keys(cb).forEach((key) => {
                dispatch(cb[key]());
            });
        };
        //@ts-ignore
        window.lastProductCB = call;
        call();
    };
};

export default useSocketDispatchUpdatePrice;
