import React, { useState } from 'react';
import styled from 'styled-components';
import { ILivePriceMainInfo } from '@api/shop/shopTypes';
import { roundToTwoDecimals } from '../../../helpers/round';

interface IMobileTable {
    currentCurrency: string;
    item: ILivePriceMainInfo;
}

const Index: React.FC<IMobileTable> = ({ currentCurrency, item }) => {
    const [currentCategoryPage, setCurrentCategoryPage] = useState(0);
    const [swipeStartPos, setSwipeStartPos] = useState(0);

    const swipeTable = (newPos: number) => {
        if (swipeStartPos - newPos < 0) {
            setCurrentCategoryPage(currentCategoryPage - 1 >= 0 ? currentCategoryPage - 1 : 0);
        } else {
            setCurrentCategoryPage(currentCategoryPage + 1 <= item.categories.length - 1 ? currentCategoryPage + 1 : item.categories.length - 1);
        }
    };

    return (
        <Mobile>
            <MobBlockName>{item.name.replace(/^\w/, (c) => c.toUpperCase())}</MobBlockName>
            <MobileTableName>{item.categories[currentCategoryPage]?.name}</MobileTableName>
            <Block>
                <Table>
                    <HeaderTr>
                        <FirstTh>Product</FirstTh>
                        <TableTh>Buy</TableTh>
                        <TableTh>Sell</TableTh>
                    </HeaderTr>
                    {item.categories[currentCategoryPage]?.products.map((product, ind) => {
                        return (
                            <TableTr
                                onTouchStart={(e) => {
                                    setSwipeStartPos(e.changedTouches[0].pageX);
                                }}
                                onTouchMove={(e) => {}}
                                onTouchEnd={(e) => {
                                    swipeTable(e.changedTouches[0].pageX);
                                }}
                                onMouseDown={(e) => {
                                    setSwipeStartPos(e.pageX);
                                }}
                                onMouseUp={(e) => {
                                    swipeTable(e.pageX);
                                }}
                                key={`links-index-${ind}-${ind}-${ind}`}
                            >
                                <FirstTd>{product.name}</FirstTd>
                                <TableTd>
                                    {product.buy_price && currentCurrency}
                                    {product.buy_price ? roundToTwoDecimals(product.buy_price) : '-'}
                                </TableTd>
                                <TableTd>
                                    {product.sell_price && currentCurrency}
                                    {product.sell_price ? roundToTwoDecimals(product.sell_price) : '-'}
                                </TableTd>
                            </TableTr>
                        );
                    })}
                </Table>
            </Block>
            <MobilePrice>
                {item.categories.map((product, index) => {
                    return (
                        <DotButton
                            key={`dotButton${index}`}
                            value={index === currentCategoryPage}
                            onClick={() => {
                                setCurrentCategoryPage(index);
                            }}
                        />
                    );
                })}
            </MobilePrice>
        </Mobile>
    );
};

const DotButton = styled.div<{ value: boolean; metal?: string }>`
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => (props.value ? '#5B73CF' : '#9FABD9')};
    display: flex;
    align-items: center;
`;

const TableTh = styled.th`
    width: 95px;
    font-family: Inter, serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    max-width: 364px;
    display: flex;
    justify-content: start;
`;
const FirstTh = styled.th`
    font-family: Inter, serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    max-width: 205px;
    width: 100%;
    display: flex;
    justify-content: start;
    @media (max-width: 600px) {
        max-width: 142px;
    }
`;

const HeaderTr = styled.tr`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 14px;
    max-width: 396px;
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    @media (max-width: 600px) {
        width: 100%;
        max-width: 90vw;
    }
`;

const Table = styled.div`
    margin-right: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 410px;
    width: 100%;
    @media (max-width: 600px) {
        margin-right: 0;
        width: 100%;
        max-width: 95vw;
        align-items: center;
    }
`;

const Block = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    @media (max-width: 600px) {
        width: 100%;
        max-width: 95vw;
    }
`;

const MobileTableName = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
`;

const BlockName = styled.div`
    color: #5b73cf;
    font-family: Inter, serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    @media (max-width: 600px) {
        display: none;
    }
`;

const MobBlockName = styled(BlockName)`
    margin-top: 30px;
    //margin-right: auto;
    text-align: center;
    @media (max-width: 600px) {
        display: block;
    }
`;

const MobilePrice = styled.div`
    line-height: 10px;
    max-width: 68px;
    display: flex;
    width: 100%;
    justify-content: center;
    /*  justify-content: space-between;*/
    gap: 10px;
    margin: 10px 0;
    font-size: 70px;
    color: #5b73cf;
    align-items: center;
`;
const Mobile = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FirstTd = styled.td`
    width: 220px;
    @media (max-width: 600px) {
        width: 157px;
    }
`;

const TableTr = styled.tr`
    //max-width: 410px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px #9fabd9 solid;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 14px;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    :nth-child(odd) {
        background-color: #f5f7fb;
    }

    :nth-child(even) {
        background-color: #fff;
    }
`;
const TableTd = styled.td`
    width: 95px;
    display: flex;
    justify-content: start;
`;

export default Index;
