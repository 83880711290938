import React from 'react';
import styled from 'styled-components';
import SuccessIMG from '../../../assets/svgs/modal_success_icon.svg';
import WarningIMG from '../../../assets/svgs/modal_warning_icon.svg';
import ErrorIMG from '../../../assets/svgs/modal_error_icon.svg';
import { SnakeTypes } from '../../components/modals/SnakeBar';

export interface IChangeData {
    type: SnakeTypes | undefined;
    content: string | undefined;
}

const ChangeDataSuccess: React.FC<IChangeData> = ({ content, type }) => {
    return (
        <Container>
            <LeftPart type={type}>
                {type === SnakeTypes.success && <img width={19} height={19} alt={'success'} src={SuccessIMG} />}
                {type === SnakeTypes.warning && <img width={19} height={19} alt={'warning'} src={WarningIMG} />}
                {type === SnakeTypes.error && <img width={19} height={19} alt={'error'} src={ErrorIMG} />}
            </LeftPart>
            <RightPart>{content}</RightPart>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

interface ILeftProps {
    type?: SnakeTypes;
}

const LeftPart = styled.div<ILeftProps>`
    background-color: ${(props) => {
        switch (props.type) {
            case SnakeTypes.success:
                return '#D1FFE4';
            case SnakeTypes.warning: {
                return '#ffdf95';
            }
            case SnakeTypes.error:
            default: {
                return '#FFC3C3';
            }
        }
    }};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
`;

const RightPart = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0b1126;
    display: flex;
    align-items: center;
    margin-right: 40px;
    padding-left: 10px;
`;

export default ChangeDataSuccess;
