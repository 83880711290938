import React from 'react';
import WarningIMG from '../../../assets/svgs/modal_warning_icon.svg';
import StyledButton from '../../StyledButton';
import styled from 'styled-components';

interface IWarningProps {
    deleteAction: () => void;
    closeModal: () => void;
    description?: string;
}

const WarningModal: React.FC<IWarningProps> = ({ deleteAction, closeModal, description }) => {
    return (
        <Wrapper>
            <ImageBlock>
                <img alt={'warning'} src={WarningIMG} />
            </ImageBlock>
            <Description>{description ? `${description}` : 'Do you want to delete actual address?'}</Description>
            <ButtonContainers>
                <CancelButton onClick={closeModal}>Cancel</CancelButton>
                <ButtonContainer>
                    <StyledButton height={'50px'} onClick={deleteAction}>
                        Yes
                    </StyledButton>
                </ButtonContainer>
            </ButtonContainers>
        </Wrapper>
    );
};

const ButtonContainer = styled.div`
    width: 150px;
`;
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
const Description = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
`;

const CancelButton = styled.div`
    display: flex;
    text-decoration-line: underline;
    width: 150px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;

const ButtonContainers = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
`;

const ImageBlock = styled.div`
    width: 293px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
`;

export default WarningModal;
