import {IUserDataUpdate} from "@api/user/userTypes";

interface naturalInfo{
    first_name: string
    surname: string
}

interface nonNaturalInfo{
    company_name: string
    website: string
}

interface naturalData{
    oldData: naturalInfo
    newData: naturalInfo
}

interface nonNaturalData{
    oldData: nonNaturalInfo
    newData: nonNaturalInfo
}

export const formChangedNaturalObject = ({oldData, newData}:naturalData) => {
    let obj:IUserDataUpdate = {}
    if (oldData.first_name !== newData.first_name){
        obj.first_name = newData.first_name
    }
    if(oldData.surname !== newData.surname){
        obj.surname = newData.surname
    }

    return Object.keys(obj).length === 0? undefined: obj
}

export const formChangedNonNaturalObject = ({oldData, newData}:nonNaturalData) => {
    let obj:IUserDataUpdate = {}
    if (oldData.company_name !== newData.company_name){
        obj.company_name = newData.company_name
    }
    if(oldData.website !== newData.website){
        obj.website = newData.website
    }

    return Object.keys(obj).length === 0? undefined: obj
}