import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import config from '../config';
import FirebaseService from '../services/firebase-service';
import { logout } from '../store/actions/userActions';

axios.defaults.baseURL = config.REACT_APP_API_BASE_URL || '';

const api: AxiosInstance = axios.create({});

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const behalf = localStorage.getItem('behalf');

    const token = await FirebaseService.auth.getAccessToken();
    config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    if (behalf) {
        config.headers = {
            ...config.headers,
            Behalf: behalf,
            'Content-Type': 'application/json',
        };
    }
    return config;
});

api.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response && response.data && response.data.message) {
            if (response.data.message === 'banned') {
                window.location.reload();
            }
        }
        return response;
    },
    (error: AxiosError & { status: number }) => {
        if (error.response?.data) {
            if (error.response.data.message === 'banned') {
                window.location.reload();
            }
        }
        throw error;
    }
);

export default api;
