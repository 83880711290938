import React, { useEffect, useState } from 'react';
import Card from './Card';
import PlaceholderIMG from '../../../assets/svgs/cart_placeholder_img.svg';
import styled from 'styled-components';
import Selector, { IList } from '../../../ui/Selector';
import CategoriesBar from '../categories/CategoriesBar';
import { useTypedSelector } from '../../../hooks/useSelector';
import { useCartActions, useShopActions } from '../../../hooks/useActions';
import { ICategoriesData, IPaginationData } from '../../../api/shop/shopTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { numberWithCommas } from '../../../helpers/numberFormatter';
import { roundToTwoDecimals } from '../../../helpers/round';
import { replaceSpacesAndSlashesWithHyphen } from '../../../helpers/stringFormat';

const filterTypesList = [
    {
        title: 'Recently added',
    },
    /*    {
            title: "Featured",
        },*/
    {
        title: 'Price: Low to High',
    },
    {
        title: 'Price: High to Low',
    },
    /*{
            title: "Newest Arrivals",
        },*/
];

enum SORT_TYPES {
    ascending = 'price.ASC',
    descending = 'price.DESC',
}

const SortCategoryFunc = (a: ICategoriesData, b: ICategoriesData) => {
    const a1 = a.name.toLocaleLowerCase();
    const b1 = b.name.toLocaleLowerCase();
    if (a1 > b1) {
        return 1;
    }
    if (b1 > a1) {
        return -1;
    }
    return 0;
};

interface ICatalog {
    currentPage: number;
    /*  setPage: () => void*/
    pageLimit: number;
    setCurrentPage: (val: number) => void;
}

const Catalog: React.FC<ICatalog> = ({ currentPage, pageLimit, setCurrentPage }) => {
    const location = useLocation();
    const path = location.pathname.split('/');

    const { shop, categories } = useTypedSelector((state) => state.shopReducer);
    const { apiStatus } = useTypedSelector((state) => state.metalReducer);
    const user = useTypedSelector((state) => state.userReducer.user);

    const { getShopData, getCategories } = useShopActions();
    const { addProductToCart } = useCartActions();
    const navigate = useNavigate();

    const [currentCurrency, setCurrentCurrency] = useState('$');
    //const [currentPage, setCurrentPage] = useState(1)

    const [selectorValue, setSelectorValue] = useState('Recently added');
    const [chosenCategory, setChosenCategory] = useState<IList | undefined>(undefined);

    const [categoriesList, setCategoriesList] = useState<IList[]>([
        {
            title: 'All categories',
        },
        ...categories.sort(SortCategoryFunc).map((item) => ({
            title: item.name,
        })),
    ]);

    const addToCart = (id: string) => {
        let getShopParams: IPaginationData = {};

        let sortType;
        switch (selectorValue) {
            case 'Price: Low to High': {
                sortType = SORT_TYPES.ascending;
                break;
            }
            case 'Price: High to Low': {
                sortType = SORT_TYPES.descending;
                break;
            }
            default: {
                sortType = undefined;
            }
        }

        if (path[3]) {
            if (chosenCategory?.id) {
                getShopParams = {
                    page: currentPage,
                    pageLimit: pageLimit,
                    sort: sortType,
                    category_id: chosenCategory.id,
                };
            }
        } else {
            getShopParams = {
                page: currentPage,
                pageLimit: pageLimit,
                sort: sortType,
            };
        }

        addProductToCart({ product_id: id, quantity: 1, user: user, getShopParams: getShopParams });
    };

    // useEffect(() => {
    //     setCurrentPage(1);
    // }, [selectorValue, chosenCategory]);

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        setCategoriesList([
            { title: 'All categories' },
            ...categories.sort(SortCategoryFunc).map((item) => ({
                title: item.name,
                id: item.id,
            })),
        ]);
        if (categories) {
            if (path[3]) {
                const title = categories.find((item) => item.id === path[3])?.name;
                if (title)
                    setChosenCategory({
                        id: path[3],
                        title,
                    });
            }
        }
    }, [categories, location]);

    useEffect(() => {
        setCurrentPage(1);

        let sortType;
        switch (selectorValue) {
            case 'Price: Low to High': {
                sortType = SORT_TYPES.ascending;
                break;
            }
            case 'Price: High to Low': {
                sortType = SORT_TYPES.descending;
                break;
            }
            default: {
                sortType = undefined;
            }
        }

        if (path[3]) {
            if (chosenCategory?.id) {
                getShopData({
                    page: 1,
                    pageLimit: pageLimit,
                    sort: sortType,
                    category_id: chosenCategory.id,
                });
                setCurrentPage(1);
            }
        } else {
            getShopData({
                page: 1,
                pageLimit: pageLimit,
                sort: sortType,
            });
        }

        window.scrollTo(0, 0);
    }, [chosenCategory, selectorValue, location]);

    useEffect(() => {
        let sortType;
        switch (selectorValue) {
            case 'Price: Low to High': {
                sortType = SORT_TYPES.ascending;
                break;
            }
            case 'Price: High to Low': {
                sortType = SORT_TYPES.descending;
                break;
            }
            default: {
                sortType = undefined;
            }
        }

        if (path[3]) {
            if (chosenCategory?.id) {
                getShopData({
                    page: currentPage,
                    pageLimit: pageLimit,
                    sort: sortType,
                    category_id: chosenCategory.id,
                });
                setCurrentPage(1);
            }
        } else {
            getShopData({
                page: currentPage,
                pageLimit: pageLimit,
                sort: sortType,
            });
        }

        window.scrollTo(0, 0);
        // setCurrentPage(1);
    }, [currentPage, pageLimit]);

    return (
        <Wrapper>
            <CategoriesWrapper>
                <CategoriesBar
                    setCurrentCategory={(value) => {
                        if (value.title === 'All categories') {
                            setChosenCategory(undefined);
                            navigate(`/shop`);
                        } else {
                            navigate(`/shop/${replaceSpacesAndSlashesWithHyphen(value.title)}/${value.id}`);
                            setChosenCategory(value);
                        }
                    }}
                    list={categoriesList}
                />
            </CategoriesWrapper>
            <Box>
                <SelectorWrapper>
                    <CategoryName>{chosenCategory?.title || ''}</CategoryName>

                    <MobileWrapp>
                        <MobileCategoryBar
                            selected={chosenCategory?.title ? chosenCategory.title : 'All categories'}
                            list={categoriesList}
                            setValue={(value) => {
                                if (value.title === 'All categories') {
                                    setChosenCategory(undefined);
                                    navigate(`/shop`);
                                } else {
                                    navigate(`/shop/${replaceSpacesAndSlashesWithHyphen(value.title)}/${value.id}`);
                                    setChosenCategory(value);
                                }
                            }}
                        />
                    </MobileWrapp>
                    <Selector
                        isShopFilter={true}
                        selected={selectorValue}
                        mobileShort={true}
                        setValue={(value) => {
                            if (value) {
                                setSelectorValue(value.title);
                            }
                        }}
                        list={filterTypesList}
                    />
                </SelectorWrapper>
                <SmallWrapper>
                    {shop.map((item, index) => (
                        <React.Fragment key={`card-index-${index}`}>
                            <Card
                                text={item.name}
                                image={item.medias[0] ? item.medias[0] : PlaceholderIMG}
                                price={`${currentCurrency}` + numberWithCommas(roundToTwoDecimals(item.price))}
                                // onClick={() => {
                                //     addProductToCart({ product_id: item.id, quantity: 1, user: user });
                                // }}
                                onClick={() => {
                                    addToCart(item.id);
                                }}
                                link={`/shop/product/${replaceSpacesAndSlashesWithHyphen(item.name)}/${item.id}`}
                                redirectOnProductPage={() => {
                                    navigate(`/shop/product/${replaceSpacesAndSlashesWithHyphen(item.name)}/${item.id}`);
                                }}
                                is_out_of_stock={item.is_out_of_stock}
                                isApiOff={!apiStatus}
                                quantity={item.amount_now}
                            />
                        </React.Fragment>
                    ))}
                </SmallWrapper>
            </Box>
            {/*<PagesPanel>
                <Image src={Arrow}/>
            </PagesPanel>*/}
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media (max-width: 900px) {
        flex-direction: column;
    }
`;
const MobileCategoryBar = styled(Selector)`
    display: none;
    width: 100%;
    @media (max-width: 900px) {
        display: flex;
    }
`;
const CategoriesWrapper = styled.div`
    @media (max-width: 1080px) {
        display: none;
    }
`;
const MobileWrapp = styled.div`
    display: none;
    @media (max-width: 1080px) {
        display: flex;
    }
`;
const SelectorWrapper = styled.div`
    margin-left: auto;
    box-sizing: border-box;
    padding-left: 50px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1080px) {
        display: flex;
        flex-direction: row;
        margin-left: unset;
        justify-content: space-between;
        padding-left: 0;
    }
    @media (max-width: 900px) {
        margin-left: 0;
        justify-content: space-between;
        padding-left: 0;
    }
`;
const Box = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    //min-width: 980px;
    min-height: 100vh;
    height: auto;
    @media (max-width: 1280px) {
        min-width: auto;
    }
`;
const Image = styled.img`
    width: 18px;
    margin: 10px;
`;
const PagesPanel = styled.div`
    display: flex;
    margin: 5px;
    color: #0b1126;
    justify-content: center;
`;
const SmallWrapper = styled.div`
    margin-top: 20px;
    //margin-left: 30px;
    display: flex;
    gap: 34px;
    flex-wrap: wrap;
    //max-width: 960px;
    width: 100%;
    justify-items: stretch;
    @media (max-width: 1380px) {
        gap: 15px;
    }
    @media (max-width: 1080px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (max-width: 900px) {
        margin-left: 0;
    }

    @media (max-width: 730px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 600px) {
        gap: 8px;
    }
`;

const CategoryName = styled.h2`
    @media (max-width: 1080px) {
        display: none;
    }
`;

export default Catalog;
