import React, {useEffect, useMemo, useState} from 'react';
import {Heading2} from "../../ui/HeadingTags";
import LocationIMG from '../../assets/svgs/settings_location_icon.svg';
import EditIMG from '../../assets/svgs/settings_edit_icon.svg';
import DeleteIMG from '../../assets/svgs/settings_delete_icon.svg';
import styled from "styled-components";
import Modal from "../../ui/components/modals/Modal";
import EditAddress from "../../ui/components/modals/EditAddress";
import {FIELD_TYPE} from "../..//helpers/registerValidation";
import StyledButton from "../../ui/StyledButton";
import CreateActualAddress from "../../ui/components/modals/CreateActualAddress";
import WarningModal from "../../ui/components/modals/WarningModal";
import {useTypedSelector} from "../../hooks/useSelector";
import {IUserDataUpdate} from "../../api/user/userTypes";
import {useUserActions} from "../../hooks/useActions";

export enum addressPlaceholderType {
    residentialAddress = 'Residential address',
    businessRegistrationAddress = 'Business registration address',
    deliveryAddress = 'Delivery address',
    actualAddress = 'Actual address',
}

export enum editType {
    residentialAddress = 'residential_address',
    deliveryAddress = 'delivery_address',
    actualAddress = 'actual_address',
}

export interface IAddressField {
    type: FIELD_TYPE
    value: string | undefined
    placeholder: addressPlaceholderType
    editType: editType
}

const SettingsAddressesComponent = () => {
    const {user} = useTypedSelector(state => state.userReducer)
    const {updateUserInfo} = useUserActions()

    const [openModalEditAddress, setOpenModalEditAddress] = useState(false);
    const [openModalCreateAddress, setOpenModalCreateAddress] = useState(false);
    const [openModalWarning, setOpenModalWarning] = useState(false);


    const [addresses, setAddresses] = useState<IAddressField[]>([
        {
            type: FIELD_TYPE.residentialAddress,
            placeholder: addressPlaceholderType.residentialAddress,
            value: user?.residential_address,
            editType: editType.residentialAddress
        },
        {
            type: FIELD_TYPE.deliveryAddress,
            placeholder: addressPlaceholderType.deliveryAddress,
            value: user?.delivery_address,
            editType: editType.deliveryAddress
        },
        {
            type: FIELD_TYPE.actualAddress,
            placeholder: addressPlaceholderType.actualAddress,
            value: user?.actual_address,
            editType: editType.actualAddress
        },
    ])
    const [currentAddress, setCurrentAddress] = useState<IAddressField>({
        type: FIELD_TYPE.residentialAddress,
        placeholder: addressPlaceholderType.residentialAddress,
        value: user?.actual_address,
        editType: editType.residentialAddress,
    })

    useEffect(() => {
        setAddresses([
            {
                type: FIELD_TYPE.residentialAddress,
                placeholder: addressPlaceholderType.residentialAddress,
                value: user?.residential_address,
                editType: editType.residentialAddress
            },
            {
                type: FIELD_TYPE.deliveryAddress,
                placeholder: addressPlaceholderType.deliveryAddress,
                value: user?.delivery_address,
                editType: editType.deliveryAddress
            },
            {
                type: FIELD_TYPE.actualAddress,
                placeholder: addressPlaceholderType.actualAddress,
                value: user?.actual_address,
                editType: editType.actualAddress
            },
        ])
    }, [user])

    const addButton = () => {
        setOpenModalCreateAddress(true)
    }

    const editButton = (value: IAddressField) => {
        setCurrentAddress(value)
        setOpenModalEditAddress(true)
    }

    const deleteButton = (value: IAddressField) => {
        setCurrentAddress(value)
        //modal for delete confirm open
        setOpenModalWarning(true)
    }

    const editAction = (newValue: string) => {
        updateUserInfo({[currentAddress.editType as keyof IUserDataUpdate]: newValue})
        setOpenModalEditAddress(false)
    }

    const deleteAction = () => {
        updateUserInfo({[currentAddress.editType]: null})
        setOpenModalWarning(false)
    }

    const closeModal = () => {
        setOpenModalWarning(false)
    }

    const createAction = (newValue: string) => {
        const actualAddress: IAddressField = {
            type: FIELD_TYPE.actualAddress,
            value: newValue,
            placeholder: addressPlaceholderType.actualAddress,
            editType: editType.actualAddress
        }
        updateUserInfo({[actualAddress.editType]: newValue})
        setOpenModalCreateAddress(false)
    }

    const renderAddresses = useMemo(() => addresses.map((currentAddress, index) => {
        return (
            currentAddress.value && (
                <div key={`address ${index}`} style={{marginTop: 30}}>
                    <AddressLabel>
                        {
                            currentAddress.type
                        }
                    </AddressLabel>
                    <AddressImg alt={'Address'} color={"#200E32"} src={LocationIMG}/>
                    <AddressBlock>
                        {
                            currentAddress.value
                        }
                        <ButtonsContainer>
                            <ButtonContainer onClick={() => editButton(currentAddress)}>
                                <p>Edit</p>
                                <img alt={'Edit'} src={EditIMG}/>
                            </ButtonContainer>
                            {
                                currentAddress.placeholder === addressPlaceholderType.actualAddress &&
                                <DeleteContainer onClick={() => deleteButton(currentAddress)}>
                                    <p>Delete</p>
                                    <img alt={'Delete'} src={DeleteIMG}/>
                                </DeleteContainer>
                            }
                        </ButtonsContainer>
                    </AddressBlock>
                </div>))
    }), [addresses])

    return (
        <div>
            <Modal ModalElement={() => EditAddress(
                {
                    address: currentAddress,
                    editAction
                })}
                   marginTop={"150px"}
                   show={openModalEditAddress}
                   setShow={setOpenModalEditAddress}
            />
            <Modal ModalElement={() => CreateActualAddress({createAction})}
                   marginTop={"150px"}
                   show={openModalCreateAddress}
                   setShow={setOpenModalCreateAddress}
            />
            <Modal ModalElement={() => WarningModal({deleteAction, closeModal})}
                   marginTop={"150px"}
                   show={openModalWarning}
                   setShow={setOpenModalWarning}
            />
            <SettingsData>
                <AddressesDiv>
                    <Heading2>Addresses</Heading2>
                    {
                        renderAddresses
                    }
                    {
                        !addresses[2].value &&
                        <ButtonCreateContainer>
                            <AddressLabel style={{marginBottom: 12}}>
                                Actual address
                            </AddressLabel>
                            <StyledButton onClick={addButton} height={"50px"}>
                                Add
                            </StyledButton>
                        </ButtonCreateContainer>
                    }
                </AddressesDiv>
            </SettingsData>
        </div>
    );
};
const SettingsData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;

`
const AddressesDiv = styled.div`
  margin-left: 100px;
  max-width: 1020px;
  margin-bottom: 25px;

`

const AddressLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

`

const AddressBlock = styled.div`
  background: #FFFFFF;
  display: flex;
  align-items: center;

  height: 60px;
  box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
  border-radius: 6px;
  padding-left: 30px;
  width: 100%;
  @media (max-width: 1200px) {
    height: 75px;
    width: 60vw;
  }
  @media (max-width: 470px) {
    padding-top: 10px;
    flex-direction: column;
    height: 90px;
    width: 80vw;
  }
  /*  @media (max-width: 1290px) {
      width: 60vw;
    }*/
`

const AddressImg = styled.img`
  position: relative;
  top: 45px;
  left: 5px;
  @media (max-width: 1200px) {
    top: 50px;
  }
  @media (max-width: 470px) {
    top: 45px;
  }
`

const ButtonContainer = styled.div`
  margin-left: auto;
  padding-right: 25px;
  color: #219653;
  display: flex;
  position: relative;
  cursor: pointer;
  gap: 10px;
  right: 0;
  @media (max-width: 1200px) {
    padding-right: 15px;
  }
  @media (max-width: 470px) {
    padding-right: 0;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  margin-left: auto;
`

const DeleteContainer = styled(ButtonContainer)`
  color: #EB5757;
`

const ButtonCreateContainer = styled.div`
  margin-top: 26px;
  width: 180px;
`

export default SettingsAddressesComponent;
