import React, { useEffect, useState } from 'react';

import Layout from '../components/Layout';
import { Container } from '../ui/container';

import styled from 'styled-components';
import axios from 'axios';
import config from '../config';
import PrivacyComponent from '../components/privacy';
import { Helmet } from 'react-helmet';
interface IField {
    url?: string;
    data?: string;
    type: string;
}

interface IDtoStatic {
    [key: string]: IField;
}

const PrivacyPolicy = () => {
    const [staticContent, setStaticContent] = useState<IDtoStatic[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = 'Privacy Policy | Bullion Network Melbourne';
        axios
            .get(`${config.REACT_APP_API_BASE_URL || ''}/page/Privacy%20policy`)
            .then((response) => {
                setStaticContent(response.data.content);
            })
            .finally(() => {
                setLoading(false);
            });
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <Helmet>
                <meta property="og:site_name" content="Bullion Network" />
                <meta property="og:title" content=" Privacy Policy | Bullion Network Melbourne" />
                <meta property="og:description" content="Bullion Network Melbourne | Privacy Policy" />
                <meta property="description" content="Bullion Network Melbourne | Privacy Policy" />
            </Helmet>
            <Container>
                <Component>
                    <PrivacyComponent statics={staticContent} isLoading={loading} />
                </Component>
            </Container>
        </Layout>
    );
};
const Component = styled.div`
    margin-top: 50px;
    margin-bottom: 300px;
    @media (max-width: 900px) {
        margin-top: 36px;
        margin-bottom: 110px;
    }
    @media (max-width: 600px) {
        margin-bottom: 50px;
    }
`;
export default PrivacyPolicy;
