import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Container } from '../ui/container';
import styled from 'styled-components';
import StyledButton from '../ui/StyledButton';
import { checkPassword, FIELD_TYPE, validateField } from '../helpers/registerValidation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserActions } from '../hooks/useActions';
import PasswordField from '../ui/PasswordField';
import { useTypedSelector } from '../hooks/useSelector';
import VerifiedIMG from '../assets/svgs/verified.svg';
import NotVerifiedIMG from '../assets/svgs/not_verified.svg';
import { Input } from '../ui/TextFields';
import FirebaseService from '../services/firebase-service';
import { useRecaptcha } from '../hooks/useRecaptcha';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../store/actions/snackbarActions';
import { SnakeTypes } from '../ui/components/modals/SnakeBar';
import Timer from '../ui/Timer';

interface IPasswordData {
    password: string;
    confirmPassword: string;
}

const VerifyAccount = () => {
    const [phoneSession, setPhoneSession] = useState({ active: false, id: '' });
    const [verificationCodeId, setVerificationCodeId] = useState<string>();
    const [codeValue, setCodeValue] = useState<string>();
    const [currentStage, setCurrentStage] = useState<'mail' | 'phone' | 'done'>();
    const recaptcha = useRecaptcha('verify-phone');
    const dispatch = useDispatch();
    const [verificationAmount, setVerificationAmount] = useState(0);
    const [isShowTimer, setIsShowTimer] = useState(true);
    const [isShowResendButton, setIsShowResendButton] = useState(false);
    const [timerTime, setTimerTime] = useState(120);

    const { user, error, isVerified, loading, message } = useTypedSelector((state) => state.userReducer);
    const {
        clearUserError,
        clearMessage,
        verifyPhoneNumberSessionCreate,
        verifyPhoneNumberActivate,
        sendVerificationLink,
        phoneChange,
        startUserAction,
        finishUserAction,
    } = useUserActions();

    const [userPhone, setUserPhone] = useState<string>();

    const inputPhoneNumber = (value: string, setPhone: (arg1: string) => void) => {
        let val = value.replace(/[ \D]/gm, '');

        let firstCode = val.substring(0, 2);
        let userPhone = value;

        userPhone = (() => {
            const env = process.env.REACT_APP_ENV;
            if (env === 'prod') {
                return `+61 ${val.substring(2, 3)} ${val.substring(3, 7)} ${val.substring(7, 11)}`;
            }
            if (env === 'qa') {
                return `+380 ${val.substring(3, 5)} ${val.substring(5, 8)} ${val.substring(8, 12)}`;
            }
            if (env === 'dev') {
                return `+421 ${val.substring(3, 5)} ${val.substring(5, 8)} ${val.substring(8, 12)}`;
            }
            if (env === 'uat') {
                return `+61 ${val.substring(2, 3)} ${val.substring(3, 7)} ${val.substring(7, 11)}`;
            }
            return `+421 ${val.substring(3, 5)} ${val.substring(5, 8)} ${val.substring(8, 12)}`;
        })();

        userPhone = userPhone.replace(/[a-zA-Zа-яА-Я]/, '');

        setPhone(userPhone.trim());
    };

    useEffect(() => {
        clearUserError();
        return () => {
            clearMessage();
        };
    }, []);

    async function sendSMSHandler() {
        if (!recaptcha || !user) {
            return;
        }

        inputPhoneNumber(user.phone, (phone) => {
            setUserPhone(phone);
        });

        const verificationId = await FirebaseService.auth.email.verifyPhoneNumber(user?.phone, recaptcha);
        if (verificationId === 'relogin') {
            dispatch(
                showSnackbar({
                    type: SnakeTypes.error,
                    content: 'Phone verification requires recent login. Please, sign in to your account again',
                    ms: 4000,
                })
            );
            return;
        } else if (verificationId === 'too-many-attempts') {
            dispatch(
                showSnackbar({
                    type: SnakeTypes.error,
                    content: 'You have performed too many verification attempts. Please, wait for a while and try again',
                    ms: 4000,
                })
            );
            return;
        } else if (verificationId === 'codeExpired') {
            dispatch(
                showSnackbar({
                    type: SnakeTypes.error,
                    content: 'Code expired. Please, try again',
                    ms: 4000,
                })
            );
            return;
        }

        if (!verificationId) {
            dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Something went wrong', ms: 4000 }));
        } else {
            setVerificationCodeId(verificationId);
            setPhoneSession({ active: true, id: verificationId });
            setVerificationAmount((prev) => prev + 1);
        }
    }

    async function resendSMSHandler() {
        if (!recaptcha || !user) {
            return;
        }

        if (verificationAmount >= 3) {
            dispatch(
                showSnackbar({
                    type: SnakeTypes.warning,
                    content: 'You have reached the limit of resend attempts',
                    ms: 4000,
                })
            );
            return;
        }

        inputPhoneNumber(user.phone, (phone) => {
            setUserPhone(phone);
        });

        setPhoneSession({ active: false, id: '' });

        const verificationId = await FirebaseService.auth.email.verifyPhoneNumber(user?.phone, recaptcha);

        if (verificationId === 'relogin') {
            dispatch(
                showSnackbar({
                    type: SnakeTypes.error,
                    content: 'Phone verification requires recent login. Please, sign in to your account again',
                    ms: 4000,
                })
            );
            return;
        }

        if (verificationId === 'codeExpired') {
            dispatch(
                showSnackbar({
                    type: SnakeTypes.error,
                    content: 'Code expired. Please, try again',
                    ms: 4000,
                })
            );
            return;
        }

        if (verificationId === 'too-many-attemps') {
            dispatch(
                showSnackbar({
                    type: SnakeTypes.error,
                    content: 'You have performed too many verification attempts. Please, wait for a while and try again',
                    ms: 4000,
                })
            );
            return;
        }

        if (!verificationId) {
            dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Something went wrong', ms: 4000 }));
        } else {
            setVerificationCodeId(verificationId);
            setPhoneSession({ active: true, id: verificationId });
            setVerificationAmount((prev) => prev + 1);
            setIsShowTimer(true);
            setIsShowResendButton(false);
        }
    }

    async function confirmSMSHandler() {
        if (!verificationCodeId || !codeValue) {
            return;
        }

        startUserAction();

        const response = await FirebaseService.auth.email.enrollMFA(codeValue, verificationCodeId);

        if (response === 'invalidCode') {
            dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Invalid code', ms: 4000 }));
            finishUserAction();

            return;
        } else if (response === 'missingCode') {
            dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Missing code', ms: 4000 }));
            finishUserAction();

            return;
        } else if (response === 'too-many-attempts') {
            dispatch(
                showSnackbar({
                    type: SnakeTypes.error,
                    content: 'You have performed too many verification attempts. Please, wait for a while and try again',
                    ms: 4000,
                })
            );
            finishUserAction();
            return;
        }

        if (response) {
            window.location.href = '/';
        }
    }

    const sendEmailVerification = () => {
        if (user) {
            sendVerificationLink(user.firebaseId);
        }
    };

    useEffect(() => {
        if (message && message.length > 10) {
            setPhoneSession({ active: true, id: message });
        } else {
            setPhoneSession({ active: false, id: '' });
        }
    }, [message]);

    useEffect(() => {
        if (isVerified.mail && isVerified.phone) {
            setCurrentStage('done');
        } else if (isVerified.mail && isVerified.phone === false) {
            setCurrentStage('phone');
        } else if (isVerified.mail === false && isVerified.phone) {
            setCurrentStage('mail');
        } else if (isVerified.mail === false && isVerified.phone === false) {
            setCurrentStage('mail');
        }
    }, [isVerified]);

    useEffect(() => {
        if (user && user.phone) {
            inputPhoneNumber(user.phone, (phone) => {
                setUserPhone(phone);
            });
        }
    }, [user]);

    const changePhone = async () => {
        if (userPhone) {
            phoneChange(userPhone.split(' ').join(''));
            setVerificationCodeId(undefined);
            setCodeValue(undefined);
            setPhoneSession({ active: false, id: '' });
            setVerificationAmount(0);
        }
    };

    return (
        <Layout>
            <Container>
                <Content>
                    <PasswordBlock>
                        <Tittle>Verify your account</Tittle>

                        <VerificationBlock>
                            <VerificationItemTitle verified={isVerified.mail}>
                                <div>Email</div>
                                <div>
                                    <img alt="verify" src={isVerified.mail ? VerifiedIMG : NotVerifiedIMG} width="48" height="48" />
                                </div>
                            </VerificationItemTitle>
                            <VerificationBlockContent>
                                {isVerified.mail && <div>Email is verified</div>}

                                {isVerified.mail === false && (
                                    <div>
                                        <div>An email should have been sent to your email earlier, press a button below to send it again!</div>
                                        <ButtonContainer>
                                            <StyledButton disabled={loading} onClick={sendEmailVerification} height={'50px'}>
                                                Send verification link
                                            </StyledButton>
                                        </ButtonContainer>
                                    </div>
                                )}
                            </VerificationBlockContent>
                        </VerificationBlock>
                        <VerificationBlock>
                            <VerificationItemTitle verified={isVerified.mail}>
                                <div>Phone</div>
                                <div>
                                    <img alt="verify" src={isVerified.phone ? VerifiedIMG : NotVerifiedIMG} width="48" height="48" />
                                </div>
                            </VerificationItemTitle>
                            <VerificationBlockContent>
                                {isVerified.phone && <div>Phone is verified</div>}

                                {isVerified.phone === false && (
                                    <div>
                                        <Input
                                            value={userPhone}
                                            onChange={(e) => {
                                                inputPhoneNumber(e.target.value, (phone) => {
                                                    setUserPhone(phone);
                                                });
                                            }}
                                            height="60px"
                                        />

                                        <ButtonContainer>
                                            <StyledButton
                                                disabled={
                                                    loading ||
                                                    // phoneSession.active ||
                                                    isVerified.mail === false ||
                                                    !user ||
                                                    userPhone?.split(' ').join('') === user.phone
                                                }
                                                onClick={changePhone}
                                                height={'50px'}
                                            >
                                                Save phone
                                            </StyledButton>
                                        </ButtonContainer>

                                        <div>
                                            {isVerified.mail === false
                                                ? 'Your email must be verified'
                                                : 'Click the button below to send a code to your phone'}
                                        </div>
                                        <ButtonContainer>
                                            <StyledButton
                                                disabled={loading || phoneSession.active || isVerified.mail === false}
                                                onClick={sendSMSHandler}
                                                height={'50px'}
                                            >
                                                Send SMS
                                            </StyledButton>
                                            {phoneSession.active && (
                                                <div style={{ marginTop: '15px' }}>
                                                    <Input value={codeValue} onChange={(e) => setCodeValue(e.target.value)} height="60px" />
                                                    <ButtonContainer style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                                                        <StyledButton disabled={loading} onClick={confirmSMSHandler} height={'50px'}>
                                                            Activate
                                                        </StyledButton>
                                                        {isShowResendButton && (
                                                            <StyledButton disabled={loading} onClick={resendSMSHandler} height={'50px'}>
                                                                Resend SMS
                                                            </StyledButton>
                                                        )}
                                                    </ButtonContainer>
                                                    {isShowTimer && (
                                                        <SendCodeButton>
                                                            Send the code in{' '}
                                                            {isShowTimer && (
                                                                <Timer
                                                                    initSeconds={timerTime}
                                                                    handleFinish={() => {
                                                                        setIsShowTimer(false);
                                                                        setIsShowResendButton(true);
                                                                    }}
                                                                />
                                                            )}
                                                        </SendCodeButton>
                                                    )}
                                                </div>
                                            )}
                                        </ButtonContainer>
                                    </div>
                                )}
                            </VerificationBlockContent>
                        </VerificationBlock>

                        <ButtonContainer>
                            <StyledButton
                                disabled={loading}
                                onClick={() => {
                                    window.location.reload();
                                }}
                                height={'50px'}
                            >
                                Update
                            </StyledButton>
                        </ButtonContainer>
                    </PasswordBlock>
                </Content>
                <div id="verify-phone"></div>
            </Container>
        </Layout>
    );
};

const Content = styled.div`
    padding-top: 50px;
    padding-bottom: 35px;
`;

const PasswordBlock = styled.div`
    padding-left: 5px;
    max-width: 582px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    @media (max-width: 761px) {
        max-width: 360px;
    }
    @media (max-width: 420px) {
        max-width: 280px;
    }
`;

const ButtonContainer = styled.div`
    margin-top: 10px;
    max-width: 250px;
`;

const Tittle = styled.div`
    /* font-family: 'Playfair Display'; */
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 53px;
    text-transform: capitalize;
`;

const ErrorText = styled.div`
    color: #d92e23;
    font-size: 14px;
    padding-left: 10px;
    padding-top: 2px;
`;

const VerificationBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 560px;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    border-radius: 0 0 6px 6px;
    height: auto;
    min-height: 120px;
`;

const VerificationItemTitle = styled.div<{ verified: boolean }>`
    background: #9fabd9;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    border-radius: 6px 6px 0 0;
    box-sizing: border-box;
    padding: 15px;
    color: white;
    height: 75px;
    // padding: 17px 20px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    > div {
        font-size: 22px;
        width: 48px;
    }
`;

const VerificationBlockContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    padding: 15px;

    > div {
        font-size: 18px;
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
`;

const SendCodeButton = styled.div`
    text-align: center;
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #5b73cf;
    text-decoration: none;
    display: flex;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
`;

const VerifiedIcon = styled.div<{ verified: boolean }>``;

export default VerifyAccount;
