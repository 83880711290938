import styled from 'styled-components';
import React from 'react';
import { ReactComponent as MockForvardIMG } from '../../../assets/svgs/price_stonks_icon.svg';
import { ReactComponent as MockBackIMG } from '../../../assets/svgs/price_notStonks_icon.svg';

interface IPaginator {
    max: number;
    page: number;
    setPage: (value: number) => void;
    width?: string;
}

const Index: React.FC<IPaginator> = ({ max, page, width, setPage }) => {
    let pageList: number[] = [];

    for (let i = 1; i <= max; i++) {
        pageList = [...pageList, i];
    }

    return (
        <PageBlock width={width}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <ArrowLeft
                    ishide={page === 1}
                    onClick={() => {
                        if (page - 1 < 1) {
                            return;
                        } else {
                            setPage(page - 1);
                        }
                    }}
                />
                <PageContainer>
                    {pageList.map((item, index) => {
                        const isMany =
                            (index + 1 === page + 2 && index + 1 !== max && index + 1 !== max - 1) ||
                            (index + 1 === page - 2 && index + 1 !== 1 && index + 1 !== 2);
                        return (
                            <Pages
                                key={`page${index}`}
                                onClick={() => {
                                    if (isMany) {
                                        return;
                                    }
                                    setPage(item);
                                }}
                                isMany={isMany}
                                isCurrentPage={index + 1 === page}
                                ishide={
                                    !(index + 1 === page + 1) &&
                                    !(index + 1 === page - 1) &&
                                    !(index + 1 === page) &&
                                    !(index + 1 === page + 2) &&
                                    !(index + 1 === page - 2) &&
                                    !(index + 1 === 1) &&
                                    !(index + 1 === max)
                                }
                            >
                                {index + 1 === page + 2 && index + 1 !== max && index + 1 !== max - 1 ? '...' : null}
                                {index + 1 === page - 2 && index + 1 !== 1 && index + 1 !== 2 ? '...' : null}
                                {(index + 1 !== page + 2 && index + 1 !== page - 2) ||
                                index + 1 === 1 ||
                                index + 1 === max ||
                                index + 1 === 2 ||
                                index + 1 === max - 1
                                    ? item
                                    : null}
                            </Pages>
                        );
                    })}
                </PageContainer>
                <ArrowRight
                    ishide={page === max}
                    onClick={() => {
                        if (page + 1 > max) {
                            return;
                        }
                        setPage(page + 1);
                    }}
                />
            </div>
        </PageBlock>
    );
};

const PageContainer = styled.div`
    display: flex;
    gap: 20px;
`;

interface IPageBlockProps {
    width?: string;
}

const PageBlock = styled.div<IPageBlockProps>`
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #e7e7ed;
    gap: 10px;
    justify-content: center;
    width: ${(props) => (props.width ? props.width : '100%')};
    margin-bottom: 20px;
`;

interface IPageParam {
    isCurrentPage?: boolean;
    ishide?: boolean;
    isMany?: boolean;
}

const Pages = styled.div<IPageParam>`
    cursor: ${(props) => (props.isMany ? 'default' : 'pointer')};
    display: ${(props) => (props.ishide ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: ${(props) => (props.isCurrentPage ? 'white' : 'black')};
    background: ${(props) => (props.isCurrentPage ? '#9FABD9' : 'unset')};
    width: 28px;
    height: 28px;
`;

interface IArrow {
    ishide?: boolean;
}

const ArrowLeft = styled(MockForvardIMG)<IArrow>`
    cursor: pointer;
    transform: rotate(270deg);
    width: 20px;
    height: 20px;
    display: ${(props) => (props.ishide ? 'none' : 'block')};

    * {
        fill: #230b34;
    }
`;
const ArrowRight = styled(MockBackIMG)<IArrow>`
    cursor: pointer;
    transform: rotate(270deg);
    width: 20px;
    height: 20px;
    display: ${(props) => (props.ishide ? 'none' : 'block')};

    * {
        fill: #230b34;
    }
`;
export default Index;
