import React from "react";
import {useEffect} from "react";

export const useClickAway = (ref: React.MutableRefObject<any>, onClickAway: Function, avoidElem?: React.MutableRefObject<any>) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (avoidElem?.current){
                if (event.target === avoidElem?.current || avoidElem?.current?.contains(event.target)) {
                    return
                }
            }

            if (ref.current && !ref.current.contains(event.target)) {
                onClickAway()
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}