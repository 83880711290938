export enum ShopActionsTypes {
    START_SHOP_ACTION = 'START_SHOP_ACTION',
    ERROR_SHOP_ACTION = 'ERROR_SHOP_ACTION',

    GET_SHOP_DATA = 'GET_SHOP_DATA',
    GET_SHOP_PRODUCT = 'GET_SHOP_PRODUCT',
    GET_CATEGORIES_PRODUCTS = 'GET_CATEGORIES_PRODUCTS',
    GET_RECOMMENDED_PRODUCTS = 'GET_RECOMMENDED_PRODUCTS',

    GET_LIVE_PRICE_DATA = 'GET_LIVE_PRICE_DATA',
}