import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextFields from '../../TextFields';
import { FIELD_HEIGHT } from '../../../components/auth/SignUp';
import StyledButton from '../..//StyledButton';
import { checkPassword, FIELD_TYPE, validateField } from '../../../helpers/settingsValidation';
import EyesHandler from '../../EyesComponent';
import { useUserActions } from '../../../hooks/useActions';
import { IModalElement } from '../../components/modals/Modal';
import { useTypedSelector } from '../../../hooks/useSelector';
import { userAPI } from '../../../api/user/userApi';

interface IUserState {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

interface IErrorState {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

interface IShowPassword {
    oldPassword: boolean;
    newPassword: boolean;
    confirmPassword: boolean;
}

const ChangePassword: React.FC<IModalElement> = ({ closeModal }) => {
    const { changeUserPassword } = useUserActions();
    const { error, loading } = useTypedSelector((state) => state.userReducer);
    const [localError, setLocalError] = useState(error);
    useEffect(() => {
        if (error) {
            setLocalError(error);
        }
    }, [error]);

    const [errorState, setErrorState] = useState<IErrorState>({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [userData, setUserData] = useState<IUserState>({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const changePassword = async () => {
        // let obj: IUserState = {
        //     newPassword: validateField(userData.newPassword, FIELD_TYPE.newPassword),
        //     confirmPassword: validateField(userData.confirmPassword, FIELD_TYPE.confirmPassword),
        //     oldPassword: validateField(userData.oldPassword, FIELD_TYPE.oldPassword),
        // };
        // if (Object.values(obj).some((x) => x !== '')) {
        //     setErrorState(obj);
        // } else {
        changeUserPassword(() => {
            if (closeModal) {
                closeModal();
            }
        });
    };

    const [showPassword, setShowPassword] = useState<IShowPassword>({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    return (
        <>
            <ModalTitle>Change Password </ModalTitle>
            {/* <FieldContainer> */}
            {/* <PasswordContainer>
                    <EyePasswordContainer>
                        <EyesHandler value={showPassword.oldPassword} width={"24px"} height={"auto"}
                                     onClick={(e) => setShowPassword(sp =>
                                         ({...sp, oldPassword: !sp.oldPassword}))}
                        />
                    </EyePasswordContainer>
                    <TextFields
                        type={showPassword.oldPassword ? "text" : "password"}
                        value={userData.oldPassword}
                        placeholder={"Old password"}
                        height={FIELD_HEIGHT}
                        onChange={(e) => {
                            setLocalError(undefined)
                            const oldPassword = e.target.value
                            setUserData(u => ({...u, oldPassword}))
                            setErrorState(e => ({
                                ...e,
                                oldPassword: validateField(oldPassword, FIELD_TYPE.oldPassword)
                            }))
                        }}
                        textError={errorState.oldPassword}
                    />
                </PasswordContainer>
                <ErrorWrap style={{
                    display: localError ? "flex" : "none",
                    justifyContent: "center"
                }}>
                    {
                        localError && <div style={{
                            color: "#D92E23",
                            fontSize: 14,
                            paddingBottom: 10,
                            marginTop: -15,
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}>{
                            !localError[0].code ?
                                localError as any
                                :
                                localError[0].code
                        }</div>
                    }
                </ErrorWrap>
                <PasswordContainer>
                    <EyePasswordContainer>
                        <EyesHandler value={showPassword.newPassword} width={"24px"} height={"auto"}
                                     onClick={(e) => setShowPassword(sp =>
                                         ({...sp, newPassword: !sp.newPassword}))}
                        />
                    </EyePasswordContainer>
                    <TextFields
                        type={showPassword.newPassword ? "text" : "password"}
                        value={userData.newPassword}
                        placeholder={"New password"}
                        height={FIELD_HEIGHT}
                        onChange={(e) => {
                            setLocalError(undefined)
                            const newPassword = e.target.value
                            setUserData(u => ({...u, newPassword}))
                            setErrorState(e => ({
                                ...e,
                                newPassword: validateField(newPassword, FIELD_TYPE.newPassword)
                            }))
                        }}
                        textError={errorState.newPassword}
                    />
                </PasswordContainer>
                <PasswordContainer>
                    <EyePasswordContainer>
                        <EyesHandler value={showPassword.confirmPassword} width={"24px"} height={"auto"}
                                     onClick={(e) => setShowPassword(sp =>
                                         ({...sp, confirmPassword: !sp.confirmPassword}))}
                        />
                    </EyePasswordContainer>
                    <TextFields
                        type={showPassword.confirmPassword ? "text" : "password"}
                        value={userData.confirmPassword}
                        placeholder={"Confirm password*"}
                        height={FIELD_HEIGHT}
                        onChange={(e) => {
                            setLocalError(undefined)
                            const confirmPassword = e.target.value
                            setUserData(u => ({...u, confirmPassword}))
                            const errorInPassword = validateField(confirmPassword, FIELD_TYPE.confirmPassword)
                            setErrorState(e => ({
                                ...e,
                                confirmPassword: errorInPassword.length > 0 ? errorInPassword :
                                    checkPassword(userData.newPassword, confirmPassword)
                            }))
                        }}
                        textError={errorState.confirmPassword}
                    />
                </PasswordContainer>
            </FieldContainer> */}
            <ButtonContainer>
                <StyledButton
                    disabled={!((errorState.confirmPassword === '' && errorState.newPassword === '' && errorState.oldPassword === '') || loading)}
                    onClick={changePassword}
                    height={'50px'}
                >
                    Click to Change Password
                </StyledButton>
            </ButtonContainer>
        </>
    );
};

const ErrorWrap = styled.div`
    display: none;
    /*  display: flex;
    justify-content: center;
    flex-direction: column;*/
`;

const ModalTitle = styled.div`
    left: 41.35%;
    right: 41.15%;
    top: 2.9%;
    bottom: 94.29%;

    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    text-align: center;

    color: #0b1126;
`;
const PasswordContainer = styled.div``;

const FieldContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
`;

const ButtonContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 20px;
`;

const EyePasswordContainer = styled.div`
    position: relative;
    margin-bottom: -20px;
    display: flex;
    align-items: center;
    justify-content: right;
    top: 22px;
    margin-right: 14px;
`;

export default ChangePassword;
